import React, { useEffect,useState } from "react";
import DashboardHeader from "../../../customComponent/DashboardHeader";
import AuthService from "../../../api/services/AuthService";
import { Link } from "react-router-dom";

const MylistingPage =  () => {  
    const userRole = sessionStorage.getItem("userrole");   
    const [mylisting, setMyListing] = useState([]);
    useEffect(() => {      
        handleMyListing();
    }, []);

    const handleMyListing = async () => {   
        const userId = sessionStorage.getItem("iduser");  
        await AuthService.getMyListing(userId).then(async result => {
            if (result.apirspo) {
                setMyListing(result.mylisting);
            } else {
           
            }
        });
    }
    return (
        <>
            <div className="container-fluid dashboard">
                <div className="row"> 
                <DashboardHeader />  
                <div id="dashboard-content" className="col-lg-9 h-100 offset-lg-2">
                        <div className="dahsboard-wrap">
                            <div className="dashboard-title">
                                <h4>My Property Listing</h4>                                                                                             
                                <hr />
                            </div>  
                            <div className="table-wrap">
                            <table cellpadding="0" cellspacing="0" className="table table-data">
                        <tr>
                            <th>S.No.</th>
                            <th>Status</th>
                            <th>Property Name</th>
                            <th>Action</th>
                        </tr>
                            {mylisting.map(function(data, idx){   
                                const propertyId = data.id ;     
                                    return (
                                        <>
                                        <tr>
                                        <td>{idx+1}</td>
                                         <td key={idx}>
                                            {
                                                data.status === 0 
                                                ? <span className="badge status-badge pending">Draft</span>
                                                : data.status === 1
                                                ? <span className="badge status-badge approved">Approved</span>
                                                : data.status === 2
                                                ? <span className="badge status-badge pending">Pending</span>
                                                : <span className="badge status-badge pending">Reject</span>
                                            }                                             
                                             </td>
                                         <td><Link to= {/property/+data.id}  className="property-name fw-semi d-inline-block">{data.title}</Link></td>                                      
                                         <td>
                                            {
                                                (userRole === 2 || userRole === 4 && data.status === 1)
                                                ? <>
                                                    <Link to={/listing/+data.id} className="custom-btn text-info edit me-4">Edit</Link>
                                                    <Link to="/scheduler" state={{propertyId}} className="custom-btn text-info edit me-4">Set Availability</Link>
                                                    </>
                                                : (userRole == 2 || userRole == 4)
                                                ?
                                                <>
                                                    <Link to={/listing/+data.id} className="custom-btn text-info edit me-4">Edit</Link>
                                                    </>
                                                : null
                                            }                                          
                                          </td>
                                         </tr>  
                                        </>                                       
                                    )
                                })}
                                </table> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            
        </>
    )
}

export default MylistingPage;