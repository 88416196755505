import React, { useState, useContext } from "react";
import AuthHeader from "../../../customComponent/AuthHeader";
import Footer from "../../../customComponent/Footer";
const UsefullformsPage = () => {
  return (
    <>
      <AuthHeader />

      <h1>Usefull forms Page</h1>
 
      <Footer />
    </>

  );
}

export default UsefullformsPage; 