import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import AuthHeader from "../../../customComponent/AuthHeader";
import Footer from "../../../customComponent/Footer";

const LoginPage = () => {


  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isAuth, setIsAuth] = useState('1');
  const [validationerror, setValidationerror] = useState({});
  // const otpType = localStorage.getItem('2fa');

  // let mobile = sessionStorage.setItem(mobileNumber);

  const handleLoginNew = async (otp, mobileNumber, type) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.login(otp, mobileNumber, type).then(async result => {
      if (result.apirspo) {
        LoaderHelper.loaderStatus(false);
        try {          
          sessionStorage.setItem("username", result.userdata.username);
          sessionStorage.setItem("iduser", result.userdata.iduser);
          sessionStorage.setItem("userrole", result.userdata.userrole);
          sessionStorage.setItem("token", result.token);
          sessionStorage.setItem("planid", result.subscriptiondata[0].subscription_id);
          sessionStorage.setItem("planname", result.subscriptiondata[0].plan_name);
          sessionStorage.setItem("planamount", result.subscriptiondata[0].amount);
          sessionStorage.setItem("planstart", result.subscriptiondata[0].start_date);
          sessionStorage.setItem("planexpire", result.subscriptiondata[0].end_date);
         // alertSuccessMessage('Successful');
          if (result.userdata.userrole == 1) {
               window.location.href = '/';
              }if (result.userdata.userrole == 2) {
                window.location.href = '/seller';
              }if (result.userdata.userrole == 3) {
                window.location.href = '/tenant';
              }if (result.userdata.userrole == 4) {
                window.location.href = '/landlord';
              }else{
                window.location.href = '/';
              }
          
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        setValidationerror(
          result.message
         );
      }
    });
  }

  const handleAuthVerify = async (mobileNumber, type) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.sendCodeLogin(mobileNumber, type).then(async result => {
      if (result.apirspo) {
        try {
          LoaderHelper.loaderStatus(false);
          alertSuccessMessage('Otp Sent Successfully');
          setIsAuth('0');
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        //alertErrorMessage('Please Enter Vailid Mobile Number');
          setValidationerror(
            result.message
        );
      }
    });

  }

  const mySubmitFunction = async (event) => {
    event.preventDefault()
    return false
  }



  return (

    <>
      <AuthHeader />
	  <div class="inner-banner">       
    </div>
      <section className="login_sec mb-5" >
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-4 offset-lg-2 col-md-5 offset-md-1 com login_bg">
              <div>
                <h3 className="secondary-font fw-bold primaryB">Hey!<br />Welcome to<br /> Broker Free Homes</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-5">
              <div className="login-form">
                <form>
                  <h4 className="secondary-font primaryB">Login</h4>

                  {isAuth === '1' ?

                    <div id="login-input" className="mt-3">
                      <p className="mb-2 form-info">Enter your registered mobile number.</p>
                      <div className="input-group input-group-lg">
                        <span className="input-group-text" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                          <path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z" />
                        </svg></span>
                        <input type="text" className="form-control" placeholder="" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                      </div>
                      {validationerror ? <span className="text-danger error">{validationerror.mobile}</span> : ''}
                      {validationerror.customMessages ? <span className="text-danger">{validationerror.customMessages.notreg}</span> : ''}
                      <div className="mt-3 text-center"><button type="button" className="btn btn-primary btn-lg" onClick={() => handleAuthVerify(mobileNumber, 'LOGIN')}> Send OTP </button> </div>
                    </div>
                    : ""
                  }
                  {isAuth === '0' ?
                    <div id="login-verify" className="mt-3">
                      <h6 className="fw-semi mb-2">OTP Verification</h6>
                      <p className="mb-2 form-info">Enter the 4-digit code received on your mobile via text message</p>
                      <div className="input-group input-group-lg">
                        <span className="input-group-text" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                          height="24" viewBox="0 0 24 24">
                          <path
                            d="M17 9.761v-4.761c0-2.761-2.238-5-5-5-2.763 0-5 2.239-5 5v4.761c-1.827 1.466-3 3.714-3 6.239 0 4.418 3.582 8 8 8s8-3.582 8-8c0-2.525-1.173-4.773-3-6.239zm-8-4.761c0-1.654 1.346-3 3-3s3 1.346 3 3v3.587c-.927-.376-1.938-.587-3-.587s-2.073.211-3 .587v-3.587zm4 11.723v2.277h-2v-2.277c-.596-.347-1-.984-1-1.723 0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723z" />
                        </svg></span>
                        <input type="number" className="form-control" placeholder="" value={otp} onChange={(e) => setOtp(e.target.value)} />
                      </div>
                      {validationerror.customMessages ? <span className="text-danger">{validationerror.customMessages.verifyotp}</span> : ''}
                      <div className="mt-3 text-center"><button type="button" className="btn btn-secondary btn-lg" onClick={() => handleLoginNew(otp, mobileNumber, 'LOGIN')}> Login </button>
                      </div>
                    </div>
                    : ''
                  }

                </form>
                <p className="text-center mt-4"><small>New User? <Link to="/signup">Signup</Link></small></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>

  );
}

export default LoginPage; 