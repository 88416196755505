import { ApiConfig } from "../apiConfig/apiConfig"
import { ApiCallPost } from "../apiConfig/apiCall"
import { ApiCallGet } from "../apiConfig/apiCall";
import { ConsoleLogs } from "../../utils/ConsoleLogs";
import moment from "moment";
const TAG = 'AuthService';
const propertyID = localStorage.getItem("propertyID");

const AuthService = {
  login: async (otp, mobile, type) => {
    const { baseUrl, verifyotp } = ApiConfig;
    const url = baseUrl + verifyotp;
    const params = {
      data: {
        otp,
        mobile,
        type,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  register: async (fullName, mobileNumber, emailId, password, city, nationality, profile) => {
    const { baseUrl, register } = ApiConfig;
    const url = baseUrl + register;
    const params = {
      data: {
        name: fullName,
        mobile: mobileNumber,
        email: emailId,
        password: password,
        city: city,
        nationality: nationality,
        userrole: profile,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  getCode: async (type, mobile, signId) => {
    const { baseUrl, verifyotp } = ApiConfig;
    const url = baseUrl + verifyotp;
    const params = {
      type: type,
      mobile: mobile,
      signId: signId
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  getCodeSignup: async (mobileNumber, type) => {
    const { baseUrl, sendOtp } = ApiConfig;
    const url = baseUrl + sendOtp;
    const params = {
      data: {
        mobile: mobileNumber,
        type
      }

    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },


  getRegisterOtp: async (emailOtp, mobileNumber, type) => {
    const { baseUrl, verifyotp } = ApiConfig;
    const url = baseUrl + verifyotp;
    const params = {
      data: {
        otp: emailOtp,
        mobile: mobileNumber,
        type

      }

    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },


  sendCodeLogin: async (mobileNumber, type) => {
    const { baseUrl, sendOtp } = ApiConfig;
    const url = baseUrl + sendOtp;
    const params = {
      data: {
        mobile: mobileNumber,
        type
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  getMyProfile: async (userId) => {
    const { baseUrl, getmyprofile } = ApiConfig;
    const url = baseUrl + getmyprofile;
    const params = {
      data: {
        userId: userId,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },

  getPropertOwner: async (propertyowner, propertyownertype, propertyownername,selfdecl, userId, propertyID) => {
    const { baseUrl, createList } = ApiConfig;
    const url = baseUrl + createList;
    const params = {
      data: {
        propertyowner: propertyowner,
        propertyownertype: propertyownertype,
        propertyownername: propertyownername,
        selfdecl : selfdecl,
        iduser: userId,
        propertyid: propertyID,
        step:1
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  getKeyFeatures: async (listingHeading, purpose, listingPrice, propertyType,builtArea, bedrooms, bathroom, propertyStatus, rentedOut, furnishing, mortgageStatus, coveredParking, builtAreaSq, yardsize,amenitiesvalue, userId, propertyID) => {
    const { baseUrl, createList } = ApiConfig;
    const url = baseUrl + createList;
    const params = {
      data: {
        title: listingHeading,
        purpose: purpose,
        property_price: listingPrice,
        property_type: propertyType,
        area: builtArea,
        bedrooms: bedrooms,
        bathrooms: bathroom,
        property_status: propertyStatus,
        rentedout: rentedOut,
        furnishing: furnishing,
        mortgage_status: mortgageStatus,
        coveredparking: coveredParking,
        description: builtAreaSq,
        yardsize:yardsize,
        iduser: userId,
        propertyid: propertyID,
        step:2
      },
      amenitiesvalue,
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },


  getMarkLoaction: async (apartmentNumber,apartmentName,loaclity,city,latitude,longitude,propertyID) => {
    const { baseUrl, createList } = ApiConfig;
    const url = baseUrl + createList;
    const params = {
      data: {
        property_number: apartmentNumber,
        property_name: apartmentName,
        locality: loaclity,
        city: city,
        latitude: latitude,
        longitude: longitude,
        propertyid: propertyID,
        step:3
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },


  getUploadImages: async (images,propertyID) => {
    const { baseUrl, createList } = ApiConfig;
    const url = baseUrl + createList;    
    const params = {
      data: {       
        propertyid: propertyID,
        step:4,
        formData : images
      }
    };
    
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, params, headers);
  },

  removePropertyImages: async (imageID,propertyID) => {
    const { baseUrl, removePropertyImage } = ApiConfig;
    const url = baseUrl + removePropertyImage;    
    const params = {
      data: {       
        imageid: imageID,
        propertyid:propertyID
      }
    };    
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, params, headers);
  },
  
  removePropertyDocs: async (docsID,propertyID) => {
    const { baseUrl, removePropertyDocs } = ApiConfig;
    const url = baseUrl + removePropertyDocs;    
    const params = {
      data: {       
        docsid: docsID,
        propertyid:propertyID
      }
    };    
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, params, headers);
  },

  getUploadDocs: async (docs, propertyID,status) => {
    const { baseUrl, createList } = ApiConfig;
    const url = baseUrl + createList;
    //const imagename = docs.name.replace(/\s/g, "");    
    const params = {
      data: {  
        propertyid: propertyID,
        status: status,
        step:5,
        formData: docs,
      }
    };
    
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, params, headers);
  },

  getMyListing: async (userId) => {
    const { baseUrl, mylisting } = ApiConfig;
    const url = baseUrl + mylisting;
    const params = {
      data: {
        userId: userId,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  getDefaultModelFunction: async () => {
    const { baseUrl, defaultmodelfunction } = ApiConfig;
    const url = baseUrl + defaultmodelfunction;
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallGet(url, headers);
  },

  getSingleListing: async (propertypurpose) => {
    const { baseUrl, singlelisting } = ApiConfig;
    const url = baseUrl + singlelisting;
    const params = {
      data: {
        propertypurpose: propertypurpose,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  getMyListingByID: async (pid,uid) => {
    const { baseUrl, getmylistingbyid } = ApiConfig;
    const url = baseUrl + getmylistingbyid;
    const params = {
      data: {
        id: pid,
        userId:uid
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },
  
  getListingDetailByID: async (pid,userId) => {
    const { baseUrl, getlistingdetailbyid } = ApiConfig;
    const url = baseUrl + getlistingdetailbyid;
    const params = {
      data: {
        id: pid,
        userid: userId,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  AddFavorite: async (userId,pid) => {
    const { baseUrl, AddFavorite } = ApiConfig;
    const url = baseUrl + AddFavorite;
    const params = {
      data: {
        id: pid,
        userId: userId,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  getMyFavorite: async (userId) => {
    const { baseUrl, getMyFavorite } = ApiConfig;
    const url = baseUrl + getMyFavorite;
    const params = {
      data: {
        userId: userId,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },

  removeMyFavorite: async (userId,pid) => {
    const { baseUrl, removeMyFavorite } = ApiConfig;
    const url = baseUrl + removeMyFavorite;
    const params = {
      data: {
        userId: userId,
        id: pid,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },
  submitListing: async (propertyID,status) => {
    const { baseUrl, submitListing } = ApiConfig;
    const url = baseUrl + submitListing;
    const params = {
      data: {
        propertyid: propertyID,
        status: status,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },
//await AuthService.getSearchResult(filterPropertyPurpose,  searchTerms, filterLocalityInfo, filterPropertyTypeInfo, filterPropertyBedsInfo, filterPropertyBathroomsInfo, filterPropertyAmenitiesInfo, filterMinValInfo, filterMaxValInfo).then(async result => {
  getSearchResult: async (propertypurpose, searchterm, filterlocationinfo, filterpropertyinfo, filterpropertybedsinfo, filterPropertyBathroomsInfo, filterPropertyAmenitiesInfo, filterPropertyFurnishingsInfo, filterPropertyStatusInfo, filterminvalinfo, filtermaxvalinfo) => {
    const { baseUrl, getSearchResult } = ApiConfig;
    const url = baseUrl + getSearchResult;
    const params = {
      data: {
        purpose: propertypurpose,
        searchterm: searchterm,        
      },
      ploc: filterlocationinfo,
      ptype : filterpropertyinfo,
      pbeds : filterpropertybedsinfo,
      pbths: filterPropertyBathroomsInfo, //Bathrooms 
      pamts: filterPropertyAmenitiesInfo, //Amenities
      pfur: filterPropertyFurnishingsInfo,  //Furnishing
      psts: filterPropertyStatusInfo,     //Status
      pmin : filterminvalinfo,
      pmax : filtermaxvalinfo,
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },
  
  getAllLocality: async () => {
    const { baseUrl, getAllLocality } = ApiConfig;
    const url = baseUrl + getAllLocality;
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallGet(url, headers);
  },

  GetMembers: async (userToken) => {
    const { baseUrl, GetMembers } = ApiConfig;
    const url = baseUrl + GetMembers;
    const params = {
      data: {
        step: 'members',
        userToken:userToken,
        //userid: userId
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },

  GetMessages: async (pmid,userToken) => {
    const { baseUrl, GetMessages } = ApiConfig;
    const url = baseUrl + GetMessages;
    const params = {
      data: {
        step: 'get',
        pmid: pmid,
        userToken:userToken,
        //recieverid: recieverId,
        //userid: userId
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },

  SendMessage: async (propertyid,userToken,newmessage) => {
    const { baseUrl, SendMessage } = ApiConfig;
    const url = baseUrl + SendMessage;
    const params = {
      data: {
        step: 'send',
        propertyid: propertyid, 
        userToken:userToken,
        //userid: userId,
        newmessage: newmessage,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },

  getScheduleTime: async (date,userToken, propertyId) => {
    const { baseUrl, ownerschedule } = ApiConfig;
    const url = baseUrl + ownerschedule;
    const params = {
      data: {
        date: moment(date).format('YYYY-MM-DD'),
        userToken:userToken,
        //idpropertyowner:userId,
        idproperty:propertyId,
        step:'get'
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },
  
  setAddTime: async (date, userTime, userToken, propertyId) => {
    const { baseUrl, addTime } = ApiConfig;
    const url = baseUrl + addTime;
    const params = {
      data: {
        date: moment(date).format('YYYY-MM-DD'),
        time: userTime,
        userToken:userToken,
        //idpropertyowner:userId,
        idproperty:propertyId,
        step:'create'
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },

  setRemoveSchedule: async (timeId,userToken) => {
    const { baseUrl, removeTime } = ApiConfig;
    const url = baseUrl + removeTime;
    const params = {
      data: {
        idschedule:timeId,
        userToken:userToken,
        step:'remove'
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },

  getUserScheduleTime: async (date,propertyId,userToken) => {
    const { baseUrl, userschedule } = ApiConfig;
    const url = baseUrl + userschedule;
    const params = {
      data: {
        date: moment(date).format('YYYY-MM-DD'),
        idproperty:propertyId,
        userToken:userToken,
        step:'get'
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },

  setReserveTime: async (scheduleId,ownerId,ownerPropertyId,userToken) => {
    const { baseUrl, reserverTime } = ApiConfig;
    const url = baseUrl + reserverTime;
    const params = {
      data: {
        idschedule:scheduleId,
        idowner:ownerId,
        idproperty:ownerPropertyId,
        userToken:userToken,
        step:'reserve'

      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },

  sechedulerConfirm: async (userschid, userToken, propertyId) => {
    const { baseUrl, ownerschedule } = ApiConfig;
    const url = baseUrl + ownerschedule;
    const params = {
      data: {        
        userschid: userschid,
        userToken:userToken,
        idproperty:propertyId,
        step:'confirm'

      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url,params, headers);
  },

}

export default AuthService;