import { useState } from "react";
import CurrencyInput from 'react-currency-input-field';
import { formatValue } from "react-currency-input-field";
const SavingCalculator = () => {
  const drop = {
    UserType: [
      { UserName: "Buyer", UserId: 1 },
      { UserName: "Seller", UserId: 2 },
      { UserName: "Tenant", UserId: 3 },
      { UserName: "Landlord", UserId: 4 },
    ],
    Commission: [
      { name: "2", CommissionId: 1, UserId: 1 },
      { name: "2", CommissionId: 2, UserId: 2 },
      { name: "5", CommissionId: 3, UserId: 3 },
      { name: "5", CommissionId: 4, UserId: 4 },
    ],
  };

  const [commission, setCommission] = useState([]); 
  const [usertype, setUserType] = useState();  
  const [commissiontype, setCommissionType] = useState();
  const [savedvalue, setSavedValue] = useState(); 
  const [propertyvalue, setPropertyValue] = useState();
  const [usertypeerror, setUserTypeError] = useState(false);  
  const [propertyvalueerror, setPropertyValueError] = useState(false); 
  const changetostate = (e) => {     
     let filtercommission = drop.Commission.filter(
       (item) => item.UserId === parseInt(e.target.value)
     ); 
     setUserTypeError(false);
     setCommission(filtercommission);
     setCommissionType(filtercommission[0].name)
   };
  

  const handleCalculate = (propertyvalue,usertype,commissiontype) => {
    if(usertype == undefined){
      setUserTypeError(true);
    }if(propertyvalue == undefined){
      setPropertyValueError(true)
    }
    let calvalue = Math.round(propertyvalue/100*commissiontype)
    if(calvalue >0){
      setPropertyValueError(false)
      const formattedValue = formatValue({
        value: ''+calvalue+'',
        intlConfig: { locale: 'en-IN', currency: 'AED' },
      });
      setSavedValue(formattedValue)
    }    
  }  
    return (
        <>
         <section id="saving-calculator">
          <form>
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center"><h2>Saving Calculator</h2>
                    <h5>Find out how much you'll save if you transact using Broker Free Homes</h5>
                  </div>    
              <div className="row form-wrap p-4 align-items-end">
                <div className="col-md-2 col-sm-6 scal-col">
                <div className="form-group">
                  <label className="form-label">You are?</label>
                  <select className="form-select form-select-lg" id="autoSizingSelect" value={usertype} onChange={(e) => {changetostate(e);setUserType(e.target.value)}}>
                    <option>Select</option>
                  {drop.UserType.map((item) => {
                      return (
                        <option value={item.UserId} key={item.UserId}>
                          {item.UserName}
                        </option>
                      );
                    })}                    
                  </select>
                  </div>
                  {usertypeerror== true ? <span className="text-danger error">Select user type</span>:null}
                </div>
                <div className="col-md-5 col-sm-6 scal-col">
                <div className="form-group">
                  <label htmlFor="formFile" className="form-label">Property Value/Rental Value</label>
                  <div className="input-group input-group-lg">
                    <span className="input-group-text" id="basic-addon3"><i class="bi bi-cash accentG"></i></span> <span className="input-group-text pe-0">AED</span>                   
                    <CurrencyInput
                        class="form-control border-start-0 ps-1"
                        name="input-name"
                        placeholder=""
                        aria-describedby="basic-addon3"
                        defaultValue={''}
                        decimalsLimit={2}
                        value={propertyvalue}
                        onValueChange={(value) => setPropertyValue(value)}
                      />
                  </div>
                  {propertyvalueerror== true ? <span className="text-danger error">Valid property value is required</span>:null}
                  </div>                  
                </div>
                <div className="col-md-2 col-sm-6 scal-col commission">
                <div className="form-group">
                  <label htmlFor="formFile" className="form-label">Commission</label>
                  <div className="input-group input-group-lg ">                   
                   <select className="form-control" id="autoSizingSelect">
                   {commission.map((item) => {
                      return ( 
                        <>
                        <option value={item.name} key={item.commissionId}>
                          {item.name}
                        </option>
                        </>
                      );
                    })}
                  </select>
                  <span className="input-group-text" id="basic-addon3"><i className="bi bi-percent accentG"></i></span>
                  </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 scal-col compare">
                  <input type="button" className="btn btn-primary btn-lg fw-bold calculate w-100" value="Calculate" onClick={() => handleCalculate(propertyvalue,usertype,commissiontype)}/>
                </div>
                {(savedvalue == undefined) 
                  ? null
                  :<>
                  <div className="text-center h3 mt-3 mb-0 primaryB">You save <strong className="fw-bold">{savedvalue}</strong></div>
                  </>}                
              </div>
            </div>
			 </div>
			  </div>
          </form>
        </section>
        </>
    )
}

export default SavingCalculator;