import React, { useState } from "react";
import AuthHeader from "../../../customComponent/AuthHeader";
import Footer from "../../../customComponent/Footer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";

const SubscriptionPage = () => {
  const [key, setKey] = useState('home');
  const iduser = sessionStorage.getItem("iduser");
  return (
    <>{console.log('iduser',iduser)}
      <AuthHeader />
      <div className="inner-banner">
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-8 offset-md-2 subscriptions-plan">
            <div className="section-title text-center"><h2>Pricing</h2>
              <p className="pricing-text"></p>
            </div>
            <Tabs
              id="controlled-tab-example"
              defaultActiveKey="buyer"
              onSelect={(k) => setKey(k)}
              className="mb-3">
              <Tab eventKey="buyer" title="BUYER">
                <table cellPadding="0" cellSpacing="0" className="plan-features feature-sm">
                  <tr className="freeze-header">
                    <th></th>
                    <th>
                      <h5 className="card-title">Guest</h5>
                      <div className="mb-3"><span className="badge btn-primary limited-feature pe-none">Limited Features</span></div>
                      <div className="subs-price">
                        <span className="fw-medium plan-price">AED 0/year</span>
                      </div>
                    </th>
                    <th>

                      <h5 className="card-title">Member</h5>
                      <div className="mb-3"><span className="badge btn-primary fully-loaded pe-none">Fully Loaded</span></div>
                      <div className="subs-price">
                        <span className="fw-medium text-decoration-line-through plan-price d-block">AED 299/year</span>
                        <span className="fw-semi">Free trial for 6 months</span>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <td>View Property Details</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center fw-semi"><span className="unlimited">Unlimited</span></td>
                  </tr>
                  <tr>
                    <td>Chat with Seller</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center fw-semi"><span className="unlimited">Unlimited</span></td>
                  </tr>
                  <tr>
                    <td>Schedule viewing</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center fw-semi"><span className="unlimited">Unlimited</span></td>
                  </tr>
                  <tr>
                    <td>Savings Calculator </td>
                    <td className="text-center"><span className="yes-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Mortgage Calculator</td>
                    <td className="text-center"><span className="yes-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Useful Forms</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Useful References</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>FAQs</td>
                    <td className="text-center"><span className="yes-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      { (iduser === null) ?
                      <><div className="text-center">
                        <Link className="btn btn-primary" to="/login" as="li">Get Started</Link>                       
                        </div>
                      </>:null}
                      </td>
                  </tr>
                </table>
                <table cellPadding="0" cellSpacing="0" className="plan-features feature-mo">
                  <tr>
                    <th className="sticky-col blank-col"></th>
                    <td>Registration & Login</td>
                    <td>List Property</td>
                    <td>Connect with potential Buyer</td>
                    <td>Savings Calculator </td>
                    <td>Mortgage Calculator</td>
                    <td>Useful Forms</td>
                    <td>Useful References</td>
                    <td>FAQs</td>
                  </tr>

                  <tr>
                    <th className="sticky-col">
                      <h5 className="card-title">Guest</h5>
                      <div className="mb-3"><span className="badge btn-primary limited-feature pe-none">Limited Features</span></div>
                      <div className="subs-price">
                        <span className="fw-medium plan-price">AED 0/year</span>
                      </div>
                    </th>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="yes-black"></span></td>
                  </tr>
                  <tr><td colSpan="10" className="split-col"></td></tr>
                  <tr>
                    <th className="sticky-col">

                      <h5 className="card-title">Member</h5>
                      <div className="mb-3"><span className="badge btn-primary fully-loaded pe-none">Fully Loaded</span></div>
                      <div className="subs-price mb-2">
                        <span className="fw-medium plan-price">AED 0/year</span>
                      </div>
                      { (iduser === null) ?
                      <><Link className="btn btn-primary text-nowrap" to="/login" as="li">Get Started</Link> 
                      </>:null}                     
                    </th>
                    <td><span className="yes-black"></span></td>
                    <td><span className="unlimited">3</span></td>
                    <td><span className="unlimited">Unlimited</span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                  </tr>
                </table>
              </Tab>
              <Tab eventKey="seller" title="SELLER">
                <table cellPadding="0" cellSpacing="0" className="plan-features feature-sm">
                  <tr className="freeze-header">
                    <th></th>
                    <th>

                      <h5 className="card-title text-center">Guest</h5>
                      <div className="mb-3"><span className="badge btn-primary limited-feature pe-none">Limited Features</span></div>
                      <div className="subs-price">
                        <span className="fw-medium plan-price">AED 0</span>
                      </div>
                    </th>
                    <th>

                      <h5 className="card-title text-center">Member</h5>
                      <div className="mb-3"><span className="badge btn-primary fully-loaded pe-none">Fully Loaded</span></div>
                      <div className="subs-price">
                        <span className=" plan-price fw-medium">AED 0</span>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <td>Registration & Login</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>List Property</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="unlimited fw-semi">3</span></td>
                  </tr>
                  <tr>
                    <td>View Property Details</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Connect with potential Buyer</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="unlimited fw-semi">Unlimited</span></td>
                  </tr>
                  <tr>
                    <td>Savings Calculator </td>
                    <td className="text-center"><span className="yes-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Mortgage Calculator</td>
                    <td className="text-center"><span className="yes-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Useful Forms</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Useful References</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>FAQs</td>
                    <td className="text-center"><span className="yes-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                    { (iduser === null) ?
                      <><div className="text-center">
                        <Link className="btn btn-primary" to="/login" as="li">Get Started</Link>                       
                        </div>
                      </>:null}
                      </td>
                  </tr>
                </table>

                <table cellPadding="0" cellSpacing="0" className="plan-features feature-mo">
                  <tr>
                    <th className="sticky-col blank-col"></th>
                    <td>Registration & Login</td>
                    <td>List Property</td>
                    <td>Connect with potential Buyer</td>
                    <td>Savings Calculator </td>
                    <td>Mortgage Calculator</td>
                    <td>Useful Forms</td>
                    <td>Useful References</td>
                    <td>FAQs</td>
                  </tr>

                  <tr>
                    <th className="sticky-col">
                      <h5 className="card-title">Guest</h5>
                      <div className="mb-3"><span className="badge btn-primary limited-feature pe-none">Limited Features</span></div>
                      <div className="subs-price">
                        <span className="fw-medium plan-price">AED 0/year</span>
                      </div>
                    </th>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="yes-black"></span></td>
                  </tr>
                  <tr><td colSpan="10" className="split-col"></td></tr>
                  <tr>
                    <th className="sticky-col">

                      <h5 className="card-title">Member</h5>
                      <div className="mb-3"><span className="badge btn-primary fully-loaded pe-none">Fully Loaded</span></div>
                      <div className="subs-price mb-2">
                        <span className="fw-medium plan-price">AED 0/year</span>
                      </div>
                      { (iduser === null) ?
                      <><Link className="btn btn-primary text-nowrap" to="/login" as="li">Get Started</Link> 
                      </>:null} 
                    </th>
                    <td><span className="yes-black"></span></td>
                    <td><span className="unlimited">3</span></td>
                    <td><span className="unlimited">Unlimited</span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                  </tr>
                </table>
              </Tab>
              <Tab eventKey="tenant" title="TENANT">
                <table cellPadding="0" cellSpacing="0" className="plan-features feature-sm">
                  <tr className="freeze-header">
                    <th></th>
                    <th>
                      <h5 className="card-title">Guest</h5>
                      <div className="mb-3"><span className="badge btn-primary limited-feature pe-none">Limited Features</span></div>
                      <div className="subs-price">
                        <span className="fw-medium plan-price">AED 0/year</span>
                      </div>
                    </th>
                    <th>

                      <h5 className="card-title">Member</h5>
                      <div className="mb-3"><span className="badge btn-primary fully-loaded pe-none">Fully Loaded</span></div>
                      <div className="subs-price">
                        <span className="fw-medium text-decoration-line-through plan-price d-block">AED 49/year</span>
                        <span className="fw-semi">Free trial for 6 months</span>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <td>View Property Details</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="unlimited fw-semi">Unlimited</span></td>
                  </tr>
                  <tr>
                    <td>Chat with Landlord</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="unlimited fw-semi">Unlimited</span></td>
                  </tr>
                  <tr>
                    <td>Schedule viewing</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="unlimited fw-semi">Unlimited</span></td>
                  </tr>
                  <tr>
                    <td>Savings Calculator </td>
                    <td className="text-center"><span className="yes-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Mortgage Calculator</td>
                    <td className="text-center"><span className="yes-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Useful Forms</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Useful References</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>FAQs</td>
                    <td className="text-center"><span className="yes-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                    { (iduser === null) ?
                      <><div className="text-center">
                        <Link className="btn btn-primary" to="/login" as="li">Get Started</Link>                       
                        </div>
                      </>:null}
                      </td>
                  </tr>
                </table>
                <table cellPadding="0" cellSpacing="0" className="plan-features feature-mo">
                  <tr>
                    <th className="sticky-col blank-col"></th>
                    <td>View Property Details</td>
                    <td>Chat with Landlord</td>
                    <td>Schedule viewing</td>
                    <td>Savings Calculator </td>
                    <td>Mortgage Calculator</td>
                    <td>Useful Forms</td>
                    <td>Useful References</td>
                    <td>FAQs</td>
                  </tr>

                  <tr>
                    <th className="sticky-col">
                      <h5 className="card-title">Guest</h5>
                      <div className="mb-3"><span className="badge btn-primary limited-feature pe-none">Limited Features</span></div>
                      <div className="subs-price">
                        <span className="fw-medium plan-price">AED 0/year</span>
                      </div>
                    </th>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="yes-black"></span></td>
                  </tr>
                  <tr><td colSpan="10" className="split-col"></td></tr>
                  <tr>
                    <th className="sticky-col">

                      <h5 className="card-title">Member</h5>
                      <div className="mb-3"><span className="badge btn-primary fully-loaded pe-none">Fully Loaded</span></div>
                      <div className="subs-price mb-2">
                        <span className="fw-medium text-decoration-line-through plan-price d-block">AED 49/year</span>
                        <span className="fw-semi">Free trial for 6 months</span>
                      </div>
                      { (iduser === null) ?
                      <><Link className="btn btn-primary text-nowrap" to="/login" as="li">Get Started</Link> 
                      </>:null}  
                    </th>
                    <td><span className="unlimited">Unlimited</span></td>
                    <td><span className="unlimited">Unlimited</span></td>
                    <td><span className="unlimited">Unlimited</span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                  </tr>
                </table>
              </Tab>
              <Tab eventKey="landlord" title="LANDLORD">
                <table cellPadding="0" cellSpacing="0" className="plan-features feature-sm">
                  <tr className="freeze-header">
                    <th></th>
                    <th>

                      <h5 className="card-title text-center">Guest</h5>
                      <div className="mb-3"><span className="badge btn-primary limited-feature pe-none">Limited Features</span></div>
                      <div className="subs-price">
                        <span className="fw-medium plan-price">AED 0</span>
                      </div>
                    </th>
                    <th>

                      <h5 className="card-title text-center">Member</h5>
                      <div className="mb-3"><span className="badge btn-primary fully-loaded pe-none">Fully Loaded</span></div>
                      <div className="subs-price">
                        <span className=" plan-price fw-medium">AED 0</span>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <td>Registration & Login</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>List Property</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="unlimited fw-semi">3</span></td>
                  </tr>
                  <tr>
                    <td>View Property Details</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Connect with potential Tenant</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="unlimited fw-semi">Unlimited</span></td>
                  </tr>
                  <tr>
                    <td>Savings Calculator </td>
                    <td className="text-center"><span className="yes-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Mortgage Calculator</td>
                    <td className="text-center"><span className="yes-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Useful Forms</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>Useful References</td>
                    <td className="text-center"><span className="no-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td>FAQs</td>
                    <td className="text-center"><span className="yes-black"></span></td>
                    <td className="text-center"><span className="yes-black"></span></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                    { (iduser === null) ?
                      <><div className="text-center">
                        <Link className="btn btn-primary" to="/login" as="li">Get Started</Link>                       
                        </div>
                      </>:null}
                      </td>
                  </tr>
                </table>

                <table cellPadding="0" cellSpacing="0" className="plan-features feature-mo">
                  <tr>
                    <th className="sticky-col blank-col"></th>
                    <td>Registration & Login</td>
                    <td>List Property</td>
                    <td>View Property Details</td>
                    <td>Connect with potential Tenant</td>
                    <td>Savings Calculator </td>
                    <td>Mortgage Calculator</td>
                    <td>Useful Forms</td>
                    <td>Useful References</td>
                    <td>FAQs</td>
                  </tr>

                  <tr>
                    <th className="sticky-col">
                      <h5 className="card-title">Guset</h5>
                      <div className="mb-3"><span className="badge btn-primary limited-feature pe-none">Limited Features</span></div>
                      <div className="subs-price">
                        <span className="fw-medium plan-price">AED 0/year</span>
                      </div>
                    </th>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="no-black"></span></td>
                    <td><span className="yes-black"></span></td>
                  </tr>
                  <tr><td colSpan="10" className="split-col"></td></tr>
                  <tr>
                    <th className="sticky-col">

                      <h5 className="card-title">Member</h5>
                      <div className="mb-3"><span className="badge btn-primary fully-loaded pe-none">Fully Loaded</span></div>
                      <div className="subs-price mb-2">
                        <span className="fw-medium plan-price">AED 0/year</span>
                      </div>
                      { (iduser === null) ?
                      <><Link className="btn btn-primary text-nowrap" to="/login" as="li">Get Started</Link> 
                      </>:null}   
                    </th>
                    <td><span className="yes-black"></span></td>
                    <td><span className="unlimited">3</span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="unlimited">Unlimited</span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                    <td><span className="yes-black"></span></td>
                  </tr>
                </table>
              </Tab>
            </Tabs>
          </div></div></div>
      <Footer />
    </>

  );
}

export default SubscriptionPage;