import React, { useState } from "react";
import DashboardHeader from "../../../customComponent/DashboardHeader";

const DashboardPage = () => {   

    return (
        <>
           <div className="container-fluid dashboard">
                <div className="row"> 
                <DashboardHeader />  
                <div id="dashboard-content" className="col-lg-9 h-100 offset-lg-2">
                <div className="dahsboard-wrap">
                            <div className="dashboard-title">
                                <h4>Dashboard</h4>                                                                                             
                                <hr />
                            </div>  
                        </div>  
                    </div>
                </div>
            </div> 
        </>
    )
}

export default DashboardPage;