import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components';
import AutoComplete from './Autocomplete';
import Marker from './Marker';

const Wrapper = styled.main`
  width: 100%;`;


Array.prototype.hasValue = function(value) {
    var i;
    for (i=0; i<this.length; i++) { if (this[i] === value) return true; }
    return false;
  }

class MyGoogleMap extends Component {
    state = {
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        geoCoder: null,
        places: [],
        center: [],
        zoom: 7,
        address: '',
        draggable: true,
        lat: null,
        lng: null
    };

    componentWillMount() {
        this.setCurrentLocation();
    }

    onMarkerInteraction = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
        
    }
    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ draggable: true });
        this._generateAddress({ lat: this.state.lat, lng: this.state.lng });
    }

    _onChange = ({ center, zoom }) => {
        this.setState({
            center: center,
            zoom: zoom,
        });

    }

    _onClick = (value) => {
        this.setState({
            lat: value.lat,
            lng: value.lng
        });
    }

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        }, ()=>{
            this._generateAddress();
        });
        //console.log("Calling API Loaded");

        //this._generateAddress();
        //this._generateAddress({ lat: this.state.lat, lng: this.state.lng });
    };

    addPlace = (place) => {
        this.setState((state) => {
            return {
                ...state,
                places: [place],
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            }
        }, ()=>{
            this._generateAddress();
        }
        );
        //console.log('add Place - calling generate Address ');
        //this._generateAddress();
        //this._generateAddress({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
    };

    _generateAddress = (location) => {
        const {
            mapApi
        } = this.state;

        const geocoder = new mapApi.Geocoder;
        //const location = this._getLatLng();
        //geocoder.geocode({ 'location': {lat:this.state.lat, lng:this.state.lng} }, (results, status) => {
            geocoder.geocode({ 'location': {lat:this.state.lat, lng:this.state.lng} }, (results, status) => {
                //geocoder.geocode({ 'location': location }, (results, status) => {
                  //  console.log(results[0].address_components);
                   
                  //  console.log(status);
                    if (status === 'OK') {
                        if (results[0]) {
                            var address_components = results[0].address_components;                            
                            
                            var street_number = "";
                            var locality = "";
                            var neighborhood = "";
                            var sublocality = "";
                            //var subsublocality = "";
                            //var subsubsublocality = "";

                            var city = ""; 
                            //var landmark = "";
                            var country = "";
                            var zipcode = "";
                            //var getaddress ="";
                            
                            console.log(address_components);
                            for (var i = 0; i < address_components.length; i++) 
                            {
                                if (address_components[i]['long_name'].length>0){

                                    if(address_components[i].types.hasValue('street_number') || address_components[i].types.hasValue('route')  ) {
                                        street_number = street_number + ((street_number.length>0)?",":"") + address_components[i]['long_name'];
                                    }
                                    else if(address_components[i].types.hasValue('sublocality')  ) {
                                        sublocality =  address_components[i]['long_name'];
                                    }
                                    else if(address_components[i].types.hasValue('neighborhood')) {
                                        neighborhood =  address_components[i]['long_name'];
                                    }
                                    else if(address_components[i].types.hasValue('locality') &&  address_components[i]['long_name']!='Dubai') {
                                        locality =  address_components[i]['long_name'];
                                    }
                                    else if(address_components[i].types.hasValue('political') && address_components[i].types.hasValue('administrative_area_level_1')) {
                                        city =  address_components[i]['long_name'];
                                    }else if(address_components[i].types.hasValue('political') && address_components[i].types.hasValue('country')) {
                                        country =  address_components[i]['long_name'];
                                    }else if (address_components[i].types === "postal_code" && zipcode == "") {
                                        zipcode = address_components[i].long_name;
                                    } 
                                } 
                                /*
                               
                               if (address_components[i].types[0] === "route" ) {
                                    locality = address_components[i].long_name;  
                                }
                                if (address_components[i].types[0] === "landmark" ) {
                                    locality = address_components[i].long_name;  
                                }
                                if (address_components[i].types[2] === "sublocality_level_1"  ) {
                                    subsublocality = address_components[i].long_name;  
                                }
                                if (address_components[i].types[2] === "sublocality_level_2"  ) {
                                    subsubsublocality = address_components[i].long_name;  
                                }
                                if (address_components[i].types[0] === "locality" && address_components[i].types[1] === "political" ) {                                
                                    city = address_components[i].long_name;  
                                }                                
                                if (address_components[i].types[0] === "postal_code" && zipcode == "") {
                                    zipcode = address_components[i].long_name;
                                }                                
                                if (address_components[i].types[0] === "country") {
                                    country = address_components[i].long_name;
                                }*/
                            }
                            if(locality.length<=0){
                                if(sublocality.length>0){
                                    locality = sublocality;
                                }else if(neighborhood.length>0){
                                    locality= neighborhood;
                                }
                            }
                          //  console.log("street_number: " + street_number + " sublocality:" + sublocality + " neighborhood: " + neighborhood + " locality:" + locality +" City: " + city + " Country: " + country + " Zip: " + zipcode);
                            //getaddress =   street_number+' '+sublocality+' '+locality; 
                          //  console.log('landmark',locality+' '+sublocality+' '+subsublocality+' '+subsubsublocality);
                            //getaddress =   landmark+' '+locality+' '+subsublocality+' '+subsubsublocality;   
                            this.zoom = 7;
                            this.setState({ address: results[0].formatted_address });
                            //this.props.getAddressDetails(getaddress, city, this.state.lat, this.state.lng);
                            this.props.getAddressDetails(street_number, sublocality, neighborhood, locality , city,  country, zipcode, this.state.lat, this.state.lng);
                            console.log('PID',this.props.propertyid) 
                         //   console.log("State: "); console.log(results[0].formatted_address);
                        } else {
                            window.alert('No results found');
                        }
                    } else {
                        window.alert('Geocoder failed due to: ' + status);
                    }
        
                });
    }

    // Get Current Location Coordinates
    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: [position.coords.latitude, position.coords.longitude],
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            });
        }
    }

    render() {
        const {
            places, mapApiLoaded, mapInstance, mapApi,
        } = this.state;
        return (
            <Wrapper>
                
                <div className="info-wrapper d-none">
                    <div className="map-details">Latitude: <span>{this.state.lat}</span>, Longitude: <span>{this.state.lng}</span></div>
                    <div className="map-details">Zoom: <span>{this.state.zoom}</span></div>
                    <div className="map-details">Address: <span>{this.state.address}</span></div>
                </div>
                {mapApiLoaded && (
                    <div className='location-input'>
                        <AutoComplete map={mapInstance} mapApi={mapApi} addplace={this.addPlace} />
                    </div>
                )}
<div className='map-warpper'>
                <GoogleMapReact
                    center={this.state.center}
                    zoom={this.state.zoom}
                    draggable={this.state.draggable}
                    onChange={this._onChange}
                    onChildMouseDown={this.onMarkerInteraction}
                    onChildMouseUp={this.onMarkerInteractionMouseUp}
                    onChildMouseMove={this.onMarkerInteraction}
                    onChildClick={() => console.log('child click')}
                    onClick={this._onClick}
                    bootstrapURLKeys={{
                        key: 'AIzaSyD5dy5NNIkHJnscrX9TViUrhpFhAC730rg',
                        language: 'en',
                        libraries: ['places', 'geometry'],
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}>

                    <Marker
                        text={this.state.address}
                        lat={this.state.lat}
                        lng={this.state.lng} />
                </GoogleMapReact></div>              
            </Wrapper >
        );
    }
}

export default MyGoogleMap;