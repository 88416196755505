import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardHeader from "../../../customComponent/DashboardHeader";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import MyGoogleMap from "../../../customComponent/GoogleMap/MyGoogleMap";
import { ConsoleLogs } from "../../../utils/ConsoleLogs";


const ListingPage = () => {

    const navigate = useNavigate();
    const [validationerror, setValidationerror] = useState({});

    const [isAuth, setIsAuth] = useState('4');
    const userId = sessionStorage.getItem("iduser");
    //const propertyID = localStorage.getItem("propertyID");
    const params = useParams();
    const propertyID = params.hasOwnProperty("id") ? params.id : null;
    //console.log("Current Property ID : " + propertyID)    

    const [propertyowner, setPropertyOwner] = useState('');
    const [propertyownertype, setPropertyOwnerType] = useState('');
    const [propertyownername, setPropertyOwnerName] = useState('');
    const [listingHeading, setListingHeading] = useState('');
    const [selfdecl, setSelfDecl] = useState('checked');
    const [purpose, setPurpose] = useState('');
    const [listingPrice, setListingPrice] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [apartmentNumber, setApartmentNumber] = useState('');
    const [apartmentName, setApartmentName] = useState('');
    const [loaclity, setLocality] = useState('');
    const [city, setCity] = useState('');
    const [builtArea, setBuiltArea] = useState('');
    const [bedrooms, setBedrooms] = useState('');
    const [bathroom, setBathroom] = useState('');
    const [propertyStatus, setPropertyStatus] = useState('');
    const [rentedOut, setRentedOut] = useState('');
    const [furnishing, setFurnishing] = useState('');
    const [mortgageStatus, setMortgageStatus] = useState('');
    const [coveredParking, setCoveredParking] = useState('');
    const [builtAreaSq, setBuiltAreaSq] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [localImages, setLocalImages] = useState('');
    const [yardsize, setYardSize] = useState('');
    const [amenitiesvalue, setAmenitiesValue] = useState([]);
    const [amenitiescheckedstate, setAmenitiesCheckedState] = useState({}); //for storing checked state of selected  property amenities
    const [ownerdropd, setOwnerDropD] = useState([]);
    const [ownertypedropd, setOwnerTypeDropD] = useState([]);
    const [purposetypedropd, setPurposeTypeForDropD] = useState([]);
    const [propertytypedropd, setPropertyTypeForDropD] = useState([]);
    const [bedroomtypedropd, setBedroomTypeForDropD] = useState([]);
    const [bathroomtypedropd, setBathroomTypeForDropD] = useState([]);
    const [parkingtypedropd, setParkingTypeForDropD] = useState([]);
    const [furnishtypedropd, setFurnishTypeForDropD] = useState([]);
    const [propertystatustypedropd, setPropertyStatusTypeForDropD] = useState([]);
    const [propertyrentstatustypedropd, setPropertyRentStatusTypeForDropD] = useState([]);
    const [propertymortgagestatustypedropd, setPropertyMortgageStatusTypeForDropD] = useState([]);
    const [propertyameniteslist, setPropertyAmenitesList] = useState([]);     
    const [propertymedia, setPropertyMedia] = useState([]); 
    const [propertydocs, setPropertyDocs] = useState([]); 
    const [btnsubmit, setBtnSubmit] = useState(true);    
  

    const [images, setImages] = useState([]);
    const [docs, setDocs] = useState([]);

    const handleDefaultModelFunction = async () => {
        await AuthService.getDefaultModelFunction().then(async result => {
            if (result.apirspo) {
                setOwnerDropD(result.ownerdropd);
                setOwnerTypeDropD(result.ownertypedropd);
                setPurposeTypeForDropD(result.purposetypesdropd);
                setPropertyTypeForDropD(result.propertytypesdropd);
                setBedroomTypeForDropD(result.bedroomtypesdropd);
                setBathroomTypeForDropD(result.bathroomtypesdropd);
                setParkingTypeForDropD(result.parkingtypesdropd);
                setFurnishTypeForDropD(result.furnishtypesdropd);
                setPropertyStatusTypeForDropD(result.propertystatustypesdropd);
                setPropertyRentStatusTypeForDropD(result.propertyrentstatustypesdropd);
                setPropertyMortgageStatusTypeForDropD(result.propertymortgagestatustypesdropd);
                setPropertyAmenitesList(result.propertyamenitieslist);
                
                //Initially : While retrieving the list of aminities assign selected flag as false for each.
                let amenitiesCheckedFlag= {};   //Assign false state for every element 
                for (var item in result.propertyamenitieslist){
                    amenitiesCheckedFlag[item] = false;
                }
                setAmenitiesCheckedState(amenitiesCheckedFlag); 

            } else {

            }
        });
    }
    

    const handlePropertyInfo = async (propertyID) => {
        await AuthService.getMyListingByID(propertyID, userId).then(async result => {
            if (result.apirspo) {
                setPropertyOwner(result.propertydetail.owner);
                setPropertyOwnerType(result.propertydetail.owner_type);
                setPropertyOwnerName(result.propertydetail.owner_name);
                setListingHeading(result.propertydetail.title);
                setPurpose(result.propertydetail.purpose);
                setListingPrice(result.propertydetail.property_price);
                setPropertyType(result.propertydetail.property_type);
                setApartmentNumber(result.propertydetail.property_number);
                setApartmentName(result.propertydetail.property_name);
                setLocality(result.propertydetail.locality);
                setCity(result.propertydetail.city);
                setLatitude(result.propertydetail.latitude);
                setLongitude(result.propertydetail.longitude);
                setBuiltArea(result.propertydetail.area);
                setBedrooms(result.propertydetail.bedrooms);
                setBathroom(result.propertydetail.bathrooms);
                setPropertyStatus(result.propertydetail.property_status);
                setRentedOut(result.propertydetail.rentedout);
                setFurnishing(result.propertydetail.furnishing);
                setMortgageStatus(result.propertydetail.mortgage_status);
                setCoveredParking(result.propertydetail.coveredparking);
                setYardSize(result.propertydetail.yardsize);
                setBuiltAreaSq(result.propertydetail.description);
                //setAmenitiesValue(result.propertyamenities); //Change it as below now
                //Changes here:
                let updatedpropertyamenities = [];  let amenitiesCheckedFlag = {};
                for(var x in result.propertyamenities){
                    var val = result.propertyamenities[x].idamenities;
                    //console.log(" X : " + x + " : " + val);
                    updatedpropertyamenities.push(val);
                    if(amenitiesCheckedFlag.hasOwnProperty(val)){
                        amenitiesCheckedFlag[val] = true;
                    }else{
                        amenitiesCheckedFlag[val] = true;
                    }
                }
                setAmenitiesValue(updatedpropertyamenities);
                setAmenitiesCheckedState(amenitiesCheckedFlag); 
                setPropertyMedia(result.propertymedia);
                setPropertyDocs(result.propertydocs);   
                result.propertydocs.length >0 ? setBtnSubmit(false) : setBtnSubmit(true);
            } else {

            }
        });

    }

    const handlePropertyOwner = async (propertyowner, propertyownertype, propertyownername, selfdecl) => {
        LoaderHelper.loaderStatus(true);
        //console.log("Inside KeywFeatures : propertyID: " + propertyID);
        await AuthService.getPropertOwner(propertyowner, propertyownertype, propertyownername, selfdecl, userId, propertyID).then(async result => {
            if (result.apirspo) {
                LoaderHelper.loaderStatus(false);
                try {
                    //  alertSuccessMessage(result.message);
                    localStorage.setItem("propertyID", result.propertyID);
                    setIsAuth('1')
                    navigate("/listing/" + result.propertyID);
                } catch (error) {
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                //console.log(result.message.title[0]);
                setValidationerror(
                    result.message
                );
                //alertErrorMessage(result.message);
            }
        });
    }

    const handleKeyFeatures = async (listingHeading, purpose, listingPrice, propertyType, builtArea, bedrooms, bathroom, propertyStatus, rentedOut, furnishing, mortgageStatus, coveredParking, builtAreaSq, yardsize, amenitiesinfo, userId, propertyID) => {
        LoaderHelper.loaderStatus(true);
        console.log("Inside KeywFeatures : propertyID: " + propertyID + "Sending features : " + amenitiesinfo);

        await AuthService.getKeyFeatures(listingHeading, purpose, listingPrice, propertyType, builtArea, bedrooms, bathroom, propertyStatus, rentedOut, furnishing, mortgageStatus, coveredParking, builtAreaSq, yardsize, amenitiesinfo, userId, propertyID).then(async result => {
            if (result.apirspo) {
                LoaderHelper.loaderStatus(false);
                try {
                    //  alertSuccessMessage(result.message);                   
                    setIsAuth('2')
                } catch (error) {
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                //console.log(result.message.title[0]);
                setValidationerror(
                    result.message
                );
                //alertErrorMessage(result.message);
            }
        });
    }

    const handleBackLocation = () => {
        setIsAuth('1')
    }


    const handleMarkLoaction = async (apartmentNumber, apartmentName, loaclity, city, latitude, longitude) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getMarkLoaction(apartmentNumber, apartmentName, loaclity, city, latitude, longitude, propertyID).then(async result => {
            if (result.apirspo) {
                LoaderHelper.loaderStatus(false);
                try {
                    //  alertSuccessMessage(result.message);
                    setIsAuth('3');
                } catch (error) {
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                setValidationerror(
                    result.message
                );
            }
        });
    }


    // const handleMarkLoaction = () => {
    //     setIsAuth('2')
    // }

    const handleBackOwner = () => {
        setIsAuth('0')
    }

    const handleBackProperty = () => {
        setIsAuth('2')
    }


    const handleBackPropertyDetail = () => {
        setIsAuth('3')
    }


    const handleUploadImages = async (image, media) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getUploadImages(image, media, propertyID).then(async result => {
            if (result.apirspo) {
                LoaderHelper.loaderStatus(false);
                try {
                    //    alertSuccessMessage('Successfully Upload Images');
                    setIsAuth('4');
                } catch (error) {
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                setValidationerror(
                    result.message
                );
            }
            return result;
        });
    }

    const handleChangeIdentity = async (event) => {
        event.preventDefault();
        const images = [];
        const targetFiles = event.target.files;
        const targetFilesObject = [...targetFiles]
        targetFilesObject.map((file) => {
            images.push(file)
        })
        setImages([...images]);
        /*
        const fileUploaded = event.target.files[0];
        images.push(fileUploaded);
        setImages([...images]);*/
        //setLocalIdentityData(fileUploaded);
    }

    const handleRemoveImage = (index) => {
        if (index > -1) { // only splice array when item is found
            images.splice(index, 1);
            setImages([...images]);// 2nd parameter means remove one item only
        }
    }
    const handleDeleteImage = async (imageID,propertyID) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.removePropertyImages(imageID,propertyID).then(async result => {
            if (result.apirspo) {   
                handlePropertyInfo(propertyID);                    
                LoaderHelper.loaderStatus(false);        
            }else{

            } 
        });
    }

    const handleDeleteDocs = async (docsID,propertyID) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.removePropertyDocs(docsID,propertyID).then(async result => {
            if (result.apirspo) {
                handlePropertyInfo(propertyID);   
                LoaderHelper.loaderStatus(false);                
            }else{

            } 
        });
    }

    const handleContinue = async () => {
        handleUploadImages(images,propertyID)
    }


    const handleChangeDocsIdentity = async (event) => {
        event.preventDefault();
        const docs = [];
        const targetFiles = event.target.files;
        const targetFilesObject = [...targetFiles]
        targetFilesObject.map((file) => {
            docs.push(file)
        })
        setDocs([...docs]);
        setBtnSubmit(false);
        /*
        const fileUploaded = event.target.files[0];
        images.push(fileUploaded);
        setImages([...images]);*/
        //setLocalIdentityData(fileUploaded);
    }

    const handleRemoveDocs = (index) => {
        if (index > -1) { // only splice array when item is found
            docs.splice(index, 1);
            setDocs([...docs]);// 2nd parameter means remove one item only
        }
        setBtnSubmit(true);
    }

    /*const getAddressDetails = (locality, city, lat, lng) => {
        setLatitude(lat);
        setLongitude(lng);
        setLocality(locality);
        setCity(city);

    }*/
    const getAddressDetails = (street_number, sublocality, neighborhood, locality , city,  country, zipcode, lat, lng) => {
        setApartmentNumber(street_number);
        setLatitude(lat);
        setLongitude(lng);
        setLocality(locality);
        setCity(city);
    }

    const [amenitiesinfo, setAmenitiesInfo] = useState({
        amenities: [],
        response: [],
    });
    
    
    //updated Function for handle checkbox checked or uncheck
    const handleCheckboxChange = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        
        let updatedcheckedstate = amenitiescheckedstate;    //storing checked aminities into the  updatedcheckedstate
        if (checked) { // Case 1 : The user checks the box
            if(!updatedcheckedstate.hasOwnProperty(value)){
                updatedcheckedstate[value] = true;
            }
        } else { // Case 2  : The user unchecks the box
            if(updatedcheckedstate.hasOwnProperty(value)){
                delete updatedcheckedstate[value];
             }
        }
        setAmenitiesCheckedState(updatedcheckedstate);

        //Prepare amenities array values.
        let updatedamenities = [];
        for(var x in updatedcheckedstate){
            updatedamenities.push(x);
        }

        setAmenitiesValue(setAmenitiesValue);
        setAmenitiesInfo({
            amenities:updatedamenities,
            response:updatedamenities
        });

    }

    const handleContinueLast = (status) => {   
        const draft="Your listing has been saved as a Draft. <br> You may edit it from your dashboard, complete the pending parts and then submit it for approval.";
        const submit="You have successfully submitted your property for listing at Broker Free Homes. <br> Our team will review it for meeting our guidelines and standards. You will be notified the status via email, in next 2 working days.";          
        if (docs.length > 0 && status == 0) {  
                const message = draft;       
                handleUploadDcos(docs, propertyID,status,message)            
        } else if (docs.length > 0 && status == 2) { 
                 const message = submit;            
                handleUploadDcos(docs, propertyID,status,message)            
        } else if(status == 0) {  
            const message = draft;   
            handleSubmitListing(propertyID,status,message)   
        }else if(status == 2) {     
            const message = submit;    
            handleSubmitListing(propertyID,status,message)   
        }

    }
    const handleSubmitListing = async (propertyID,status,message) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.submitListing(propertyID,status).then(async result => {
            if (result.apirspo) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(message);
                try {
                    navigate("/mylisting/");
                } catch{

                }
            }
        });

    }
    const handleUploadDcos = async (docs, propertyID,status,message) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getUploadDocs(docs, propertyID,status).then(async result => {
            if (result.apirspo) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(message);
                    navigate("/mylisting/");
                } catch (error) {
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                setValidationerror(
                    result.message
                );
            }
            return result;
        });
    }

    useEffect(() => {       
        handleDefaultModelFunction();        
        if (propertyID > 0) {
            localStorage.setItem("propertyID", propertyID);
            handlePropertyInfo(propertyID);
        } else {
            localStorage.setItem("propertyID", null);
        }

    }, []);
    return (
        <>            
            <div className="container-fluid dashboard">
                <div className="row">
                <DashboardHeader />
                    <div id="dashboard-content" className="col-lg-9 h-100 offset-lg-2">
                        <div className="dahsboard-wrap">
                            <div className="dashboard-title">
                                <h4>List your Property</h4>
                                <hr />
                            </div>
                            <div>
                                <ul id="progressbar" className="p-0 d-none">
                                    <li className=""></li>
                                    <li className=""></li>
                                    <li className=""></li>
                                    <li className=""></li>
                                    <li className=""></li>
                                </ul>
                            </div>
                            {isAuth === '0' ?
                                <>
                                    <div className="card ownership">
                                        <div className="card-header fw-semi primaryB">
                                            Ownership
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                            <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <select className="form-select" name="propertyownertype" id="floatingSelect" aria-label="Floating label select example" value={propertyownertype} onChange={(e) => setPropertyOwnerType(e.target.value)}>
                                                            <option value="0">Select</option>
                                                            {Object.keys(ownertypedropd).map(key => {
                                                                return <>
                                                                    <option value={key}>{ownertypedropd[key]}</option>
                                                                </>
                                                            })}
                                                        </select>
                                                        <label htmlFor="floatingSelect">What describes the ownership of the property? <span className="text-danger">*</span></label>
                                                        {validationerror ? <span className="text-danger">{validationerror.propertyownertype}</span> : ''}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <select className="form-select" name="propertyowner" id="floatingSelect" aria-label="Floating label select example" value={propertyowner} onChange={(e) => setPropertyOwner(e.target.value)}>
                                                            <option value="0">Select</option>
                                                            {Object.keys(ownerdropd).map(key => {
                                                                return <>
                                                                    <option value={key}>{ownerdropd[key]}</option>
                                                                </>
                                                            })}
                                                        </select>
                                                        <label htmlFor="floatingSelect">Are you the owner/one of the owners <span className="text-danger">*</span></label>
                                                        {validationerror ? <span className="text-danger">{validationerror.propertyowner}</span> : ''}
                                                    </div>
                                                </div>                                               

                                                <div className="col-sm-12 mb-3">
                                                    <div className="form-floating">
                                                        <input type="text" name="propertyownername" className="form-control" id="floatingInput" value={propertyownername} onChange={(e) => setPropertyOwnerName(e.target.value)} />
                                                        <label htmlFor="floatingInput">Full Names of owner(s) as per Title Deed <span className="text-danger">*</span></label>
                                                        {validationerror ? <span className="text-danger">{validationerror.propertyownername}</span> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 mb-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" name="selfdecl" type="checkbox" id="gridCheck" checked value={selfdecl} onChange={(e) => setSelfDecl(e.target.value)} hidden />
                                                        <label className="form-check-label" htmlFor="gridCheck">
                                                        <strong>Please Note: </strong>In case you are not the sole owner of the property, you will need to upload a Power of Attorney (POA) by the owner in your favour, in order to make this listing LIVE. You can do this in the ‘Upload Documents’ Section of this form or later through your dashboard.
                                                        </label>
                                                        {validationerror ? <span className="text-danger">{validationerror.selfdecl}</span> : ''}
                                                    </div>
                                                </div>
                                                <div className="text-end card-action">
                                                    <button className="btn btn-primary" id="propety-detail-show" onClick={() => handlePropertyOwner(propertyowner, propertyownertype, propertyownername, selfdecl)}>Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : isAuth === '1' ?
                                    <>
                                        <div className="card key-feature">
                                            <div className="card-header fw-semi primaryB">
                                                 Property Details
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-4">
                                                            <select className="form-select" name="purpose" id="floatingSelect" aria-label="Floating label select example" value={purpose} onChange={(e) => setPurpose(e.target.value)}>
                                                                <option value="">Select</option>
                                                                {Object.keys(purposetypedropd).map(key => {
                                                                    return <>
                                                                        <option value={key}>{purposetypedropd[key]}</option>
                                                                    </>
                                                                })}
                                                            </select>
                                                            <label htmlFor="floatingSelect">Purpose <span className="text-danger">*</span></label>
                                                            {validationerror ? <span className="text-danger">{validationerror.purpose}</span> : ''}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-3">
                                                            <select className="form-select" name="propertytype" id="floatingSelect" aria-label="Floating label select example" value={propertyType} onChange={(e) => setPropertyType(e.target.value)}>
                                                                <option value="0">Select</option>
                                                                {Object.keys(propertytypedropd).map(key => {
                                                                    return <>
                                                                        <option value={key}>{propertytypedropd[key]}</option>
                                                                    </>
                                                                })}
                                                            </select>
                                                            <label htmlFor="floatingSelect">Property Type <span className="text-danger">*</span></label>
                                                            {validationerror ? <span className="text-danger">{validationerror.property_type}</span> : ''}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-4">
                                                            <input type="text" name="property_price" className="form-control" id="floatingInput" value={listingPrice} onChange={(e) => setListingPrice(e.target.value)} />
                                                            <label htmlFor="floatingInput">Listing Price (AED) <span className="text-danger">*</span></label>
                                                            {validationerror ? <span className="text-danger">{validationerror.property_price}</span> : ''}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-4">
                                                            <input type="text" name="title" className="form-control" id="floatingInput" value={listingHeading} onChange={(e) => setListingHeading(e.target.value)} />
                                                            <label htmlFor="floatingInput">Listing Heading (upto 60 Characters) <span className="text-danger">*</span></label>
                                                            {validationerror ? <span className="text-danger">{validationerror.title}</span> : ''}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-4">
                                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" value={bedrooms} onChange={(e) => setBedrooms(e.target.value)}>
                                                                <option value="Select">Select</option>
                                                                {Object.keys(bedroomtypedropd).map(key => {
                                                                    return <>
                                                                        <option value={key}>{bedroomtypedropd[key]}</option>
                                                                    </>
                                                                })}
                                                            </select>
                                                            <label htmlFor="floatingSelect">Bedrooms <span className="text-danger">*</span></label>
                                                            <span className="text-danger">{validationerror.bedrooms}</span>
                                                        </div>

                                                    </div>

                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-4">                                                           
                                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" value={bathroom} onChange={(e) => setBathroom(e.target.value)}>
                                                                <option value="Select">Select</option>
                                                                {Object.keys(bathroomtypedropd).map(key => {
                                                                    return <>
                                                                        <option value={key}>{bathroomtypedropd[key]}</option>
                                                                    </>
                                                                })}
                                                            </select>
                                                            <label htmlFor="floatingInput">Bathroom <span className="text-danger">*</span></label>
                                                            <span className="text-danger">{validationerror.bathrooms}</span>
                                                        </div>
                                                    </div>



                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-4">
                                                            <input type="text" name="area" className="form-control" id="floatingInput" value={builtArea} onChange={(e) => setBuiltArea(e.target.value)} />
                                                            <label htmlFor="floatingInput">Built-up Area (Sq.Ft) <span className="text-danger">*</span></label>
                                                            <span className="text-danger">{validationerror.area}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-4">

                                                            <input type="text" className="form-control" id="floatingInput" value={yardsize} onChange={(e) => setYardSize(e.target.value)} />

                                                            <label htmlFor="floatingSelect">Plot Size (Sq.Ft)</label>
                                                            <span className="text-danger">{validationerror.yardsize}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-4">
                                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" value={propertyStatus} onChange={(e) => setPropertyStatus(e.target.value)} >
                                                                <option>Select</option>
                                                                {Object.keys(propertystatustypedropd).map(key => {
                                                                    return <>
                                                                        <option value={key}>{propertystatustypedropd[key]}</option>
                                                                    </>
                                                                })}
                                                            </select>
                                                            <label htmlFor="floatingSelect">Property Status <span className="text-danger">*</span></label>
                                                            <span className="text-danger">{validationerror.property_status}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-4">
                                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" value={rentedOut} onChange={(e) => setRentedOut(e.target.value)}>
                                                                <option>Select</option>
                                                                {Object.keys(propertyrentstatustypedropd).map(key => {
                                                                    return <>
                                                                        <option value={key}>{propertyrentstatustypedropd[key]}</option>
                                                                    </>
                                                                })}
                                                            </select>
                                                            <label htmlFor="floatingSelect">Rented Out</label>
                                                            <span className="text-danger">{validationerror.rentedout}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-4">
                                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" value={furnishing} onChange={(e) => setFurnishing(e.target.value)}>
                                                                <option>Select</option>
                                                                {Object.keys(furnishtypedropd).map(key => {
                                                                    return <>
                                                                        <option value={key}>{furnishtypedropd[key]}</option>
                                                                    </>
                                                                })}
                                                            </select>
                                                            <label htmlFor="floatingSelect">Furnishing <span className="text-danger">*</span></label>
                                                            <span className="text-danger">{validationerror.furnishing}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-4">
                                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" value={mortgageStatus} onChange={(e) => setMortgageStatus(e.target.value)}>
                                                                <option>Select</option>
                                                                {Object.keys(propertymortgagestatustypedropd).map(key => {
                                                                    return <>
                                                                        <option value={key}>{propertymortgagestatustypedropd[key]}</option>
                                                                    </>
                                                                })}
                                                            </select>
                                                            <label htmlFor="floatingSelect">Mortgage Status</label>
                                                            <span className="text-danger">{validationerror.mortgage_status}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="form-floating mb-4">
                                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" value={coveredParking} onChange={(e) => setCoveredParking(e.target.value)}>
                                                                <option>Select</option>
                                                                {Object.keys(parkingtypedropd).map(key => {
                                                                    return <>
                                                                        <option value={key}>{parkingtypedropd[key]}</option>
                                                                    </>
                                                                })}
                                                            </select>
                                                            <label htmlFor="floatingSelect">Covered Parking</label>
                                                            <span className="text-danger">{validationerror.coveredparking}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="form-floating mb-4">
                                                            <textarea type="text" rows="3" name="description" className="form-control" id="floatingInput" value={builtAreaSq} onChange={(e) => setBuiltAreaSq(e.target.value)}></textarea>
                                                            <label htmlFor="floatingInput">Short Description <span className="text-danger">*</span></label>
                                                            <span className="text-danger">{validationerror.description}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 mt-3">
                                                        <h6>Amenities <span className="text-danger">*</span></h6>
                                                        <ul className="list-unstyled d-flex flex-wrap align-items-center">
                                                        {Object.keys(propertyameniteslist).map(key => {
                                                            return <>                                                                        
                                                                <li className="amenities_list">
                                                                    <input type="checkbox" name="amenities" value={key} id={`amenities-checkbox-${key}`} onChange={handleCheckboxChange} defaultChecked={amenitiescheckedstate.hasOwnProperty(key)?true:false} className=""/>
                                                                    <label htmlFor={`amenities-checkbox-${key}`}>{propertyameniteslist[key]}</label>
                                                                </li>
                                                            </>
                                                        })}                                               
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <button className="btn btn-secondary" onClick={handleBackOwner}>Back</button>
                                                    <button className="btn btn-primary" onClick={() => handleKeyFeatures(listingHeading, purpose, listingPrice, propertyType, builtArea, bedrooms, bathroom, propertyStatus, rentedOut, furnishing, mortgageStatus, coveredParking, builtAreaSq, yardsize, amenitiesinfo.amenities, userId, propertyID)}>Continue</button>
                                                </div>
                                            </div>
                                        </div></>
                                    : isAuth === '2' ?
                                        <>
                                            <div className="card property-location">
                                                <div className="card-header fw-semi primaryB">
                                                    Mark Location on Map <span className="text-danger">*</span>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-sm-12 mb-3 mb-4">
                                                            <MyGoogleMap getAddressDetails={getAddressDetails} propertyid={propertyID}/>
                                                        </div>
                                                        <div className="col-xl-4 col-sm-6 mb-3">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInput" value={city} onChange={(e) => setCity(e.target.value)} />
                                                                <label htmlFor="floatingInput">City <span className="text-danger">*</span></label>
                                                                <span className="text-danger">{validationerror.city}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-sm-6 mb-3">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInput" value={loaclity} onChange={(e) => setLocality(e.target.value)} />
                                                                <label htmlFor="floatingInput">Locality/Area <span className="text-danger">*</span></label>
                                                                <span className="text-danger">{validationerror.locality}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-sm-6 mb-3">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInput" value={apartmentNumber} onChange={(e) => setApartmentNumber(e.target.value)} />
                                                                <label htmlFor="floatingInput">Apartment/Villa Number <span className="text-danger">*</span></label>
                                                                {validationerror ? <span className="text-danger">{validationerror.property_number}</span> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-sm-6 mb-3">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInput" value={apartmentName} onChange={(e) => setApartmentName(e.target.value)} />
                                                                <label htmlFor="floatingInput">Apartment/Villa Name <span className="text-danger">*</span></label>
                                                                <span className="text-danger">{validationerror.property_name}</span>
                                                            </div>

                                                        </div>


                                                        <div className="d-none">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInput" value={latitude} onChange={(e) => setLatitude(e.target.value)} />
                                                                <label htmlFor="floatingInput">Latitude *</label>
                                                            </div>
                                                        </div>

                                                        <div className="d-none">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInput" value={longitude} onChange={(e) => setLongitude(e.target.value)} />
                                                                <label htmlFor="floatingInput">Lognitude*</label>
                                                            </div>
                                                        </div>



                                                    </div>

                                                    <div className="d-flex justify-content-between">
                                                        <button className="btn btn-secondary" onClick={handleBackLocation}>Back</button>
                                                        <button className="btn btn-primary" onClick={() => handleMarkLoaction(apartmentNumber, apartmentName, loaclity, city, latitude, longitude)}>Continue</button>
                                                    </div>
                                                </div>

                                                <div>
                                                </div>
                                            </div>
                                        </>
                                        : isAuth === '3' ?
                                            <div className="card property-images">
                                                <div className="card-header fw-semi primaryB">
                                                    Property Images
                                                </div>
                                                <div className="card-body">
                                                    <p><small>Upload upto 10 images of your property. Better images attract more enquiries so be sure to upload the most depictive and attractive ones. Photos must be JPG or PNG format. Image file size should be less than 10MB. We suggest that WIDE composition of images be uploaded (instead of tall)<br/> Recommended Image Dimensions: 800px wide X 600px tall (Ratio: 4:3)</small></p>

                                                    <div className="file-upload position-relative mb-2">
                                                        <div className="upload-text"><span><i className="bi bi-cloud-arrow-up"></i> Click here to add files for
                                                            upload</span></div>
                                                        <input type="file" multiple name="image[]" className="upload" data-multiple-caption="{count} files selected" onChange={handleChangeIdentity} />

                                                    </div>
                                                    <span className="text-danger">{validationerror.image}</span>
                                                    <div className="my-3">Inserted Media
                                                        <div className="d-flex  flex-wrap mt-2">
                                                            {images.length > 0 ?
                                                                images.map((item, index) => {
                                                                    const imgata = URL.createObjectURL(item);

                                                                    return (
                                                                        <div className="me-4 mb-2"><img src={imgata} style={{ height: '100px', width: '100px' }} />
                                                                            <span onClick={() => handleRemoveImage(index)}><img src="/images/close.png" style={{ height: '10px', width: '10px', position: 'absolute' }} />
                                                                            </span></div>
                                                                    )
                                                                }) : null}
                                                        </div>
                                                        <div className="d-flex flex-wrap mt-2">
                                                            {propertymedia.map(function(data, idx){  
                                                               // console.log('mee',Object.keys(propertymedia))       
                                                                    return (
                                                                        <>                                                                         
                                                                        <div className="me-4 mb-2" key={idx}>
                                                                        <img src={global.Image+propertyID+'/'+data.property_media} style={{ height: '100px', width: '100px' }} /> 
                                                                        <span onClick={() => handleDeleteImage(data.id,propertyID)}><img src="/images/close.png" style={{ height: '10px', width: '10px', position: 'absolute' }} />
                                                                            </span>
                                                                       </div>
                                                                        </>                                       
                                                                    )
                                                             })}     
                                                      </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <button className="btn btn-secondary" onClick={handleBackProperty}>Back</button>
                                                        <button className="btn btn-primary" onClick={handleContinue}>Continue</button>
                                                    </div>
                                                </div>
                                            </div>
                                            : isAuth === '4' ?
                                                <div className="card property-images">
                                                    <div className="card-header fw-semi primaryB">
                                                        Uploads Documents
                                                    </div>
                                                    <div className="card-body">
                                                        <p><small>Please confirm that you are authorised to list this property by submitting required documents. This is an essential step to complete your listing. You can do this now or later from 'My Listings' link in your account. Format should be PDF.</small></p>

                                                        <div className="file-upload position-relative mb-2">
                                                            <div className="upload-text"><span><i className="bi bi-cloud-arrow-up"></i> Click here to add files for
                                                                upload</span></div>
                                                            <input type="file" multiple name="docs[]" className="upload" data-multiple-caption="{count} files selected" onChange={handleChangeDocsIdentity} />
                                                        </div>
                                                        {validationerror ? <span className="text-danger">{validationerror.docs}</span> : ''}
                                                        <div className="mb-4">Inserted Media
                                                            <div className="d-flex flex-wrap mt-2">
                                                                {docs.length > 0 ?
                                                                    docs.map((item, index) => {
                                                                        const imgata = URL.createObjectURL(item);

                                                                        return (
                                                                            <div className="me-4 mb-2"><img src={imgata} style={{ height: '100px', width: '100px', alt: 'property media' }} />
                                                                                <span onClick={() => handleRemoveDocs(index)}><img src="/images/close.png" style={{ height: '10px', width: '10px', position: 'absolute' }} />
                                                                                </span></div>
                                                                        )
                                                                    }) : null}
                                                            </div>
                                                            <div className="d-flex flex-wrap mt-2">
                                                            {propertydocs.map(function(data, idx){                                                                 
                                                               // console.log('mee',Object.keys(propertymedia))       
                                                                    return (
                                                                        <>                                                                                                                                          
                                                                        <div className="me-4 mb-2" key={idx}>
                                                                        <img src={global.Image+propertyID+'/'+data.docs} style={{ height: '100px', width: '100px' }} /> 
                                                                        <span onClick={() => handleDeleteDocs(data.id,propertyID)}><img src="/images/close.png" style={{ height: '10px', width: '10px', position: 'absolute' }} />
                                                                            </span>
                                                                        </div>
                                                                        </>                                       
                                                                    )
                                                             })}     
                                                      </div>
                                                        </div>
                                                        <div className="mb-4">
                                                            <div className="form-check ps-0">
                                                                <input classclassName="form-check-input" type="checkbox" id="gridCheck" checked/>
                                                                <label className="form-check-label ms-2" for="gridCheck">
                                                                    I confirm that the property details, images and videos uploaded by me are authentic and correct.
                                                                </label><br></br>
                                                                <input classclassName="form-check-input" type="checkbox" id="gridCheck" checked/>
                                                                <label className="form-check-label ms-2" for="gridCheck">
                                                                    I confirm that I am not a Real Estate Agent.
                                                                </label>
                                                            </div></div>
                                                        <div className="row">
                                                            <div className="col-sm-4 mb-sm-0 mb-3"><button className="btn btn-secondary" onClick={handleBackPropertyDetail}>Back</button></div>
                                                            <div className="col-sm-8 text-sm-end">
                                                                 <button className="btn btn-outline-dark" value="0" onClick={(e) =>handleContinueLast(e.target.value)}>Save as Draft</button> &nbsp;
                                                                <button className="btn btn-primary"  value="2" onClick={(e) =>handleContinueLast(e.target.value)} disabled={btnsubmit}>Submit Now</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                : null
                            }
                        </div>
                    </div>
                </div>

            </div>

            {/* <Footer /> */}
        </>
    )
}

export default ListingPage;