import React, { useState, useEffect } from "react";
import AuthService from "../../api/services/AuthService";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";
import { formatValue } from "react-currency-input-field";
import Swal from 'sweetalert2'

const PropertyListing = (props) => {
    const userId = sessionStorage.getItem("iduser");
    const { propertyList } = props;
    const { propertytype } = props;
    const [list, setList] = useState(propertyList);
    const [isfavorite, setIsFavorite] = useState('');
    const [isAdded, setIsAdded] = useState('');
    const [isRemove, setIsRemove] = useState('');

    useEffect(() => {
        if (propertyList.length > 0) {
            setList([...propertyList]);
        }
    }, [propertyList]);
    const owlOptions = {
        margin: 30,
        loop: false,
        responsiveClass: true,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 1,
            },
            767: {
                items: 2,
            },
            1200: {
                items: 3,
            }
        },
    };
    useEffect(() => {
        handleAddFavorite();
    }, [isAdded, isRemove])

    const handleAddFavorite = async (property_id) => {
        await AuthService.AddFavorite(userId, property_id).then(async result => {
            if (result.apirspo) {

                setIsRemove(result.removed);
                setIsAdded(result.added);

                // if (result.added) {
                //     setIsFavorite('favorite-button is-favorite');
                // } else if (result.removed) {
                //     setIsFavorite('favorite-button notis-favorite');
                // }

                // console.log(result.favoritelist);
            } else {

            }
        });
    }

    const handleRestricted = () => {
        Swal.fire({
            title: 'This link is exclusively for registered users.',
            text: "Please Login/Register to continue.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Login'
          }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = '/login';
            }
          })
      };
    return (
        <>
            <section id="plisting-home" className="spacer-md">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="section-title"><h2 className="mb-0">Browse Properties</h2>
                            </div>
                            <OwlCarousel className="owl-theme" {...owlOptions}>
                                {list.length > 0 ?
                                    list.map((data, idx) =>
                                        <>
                                            <div className="item plist-col" key={idx}>
                                                <div className="plist-wrap">
                                                    <div className="pthumb">
                                                        <div className="pthumb-holder position-relative">    
                                                            {
                                                                userId >0 
                                                                    ?
                                                                    <Link to={/property/ + data.property_id}><img src={global.Image + data.property_id + '/' + data.mediaphoto} className="img-fluid" alt="" /></Link>
                                                                    : 
                                                                    <>
                                                                     <Link to="" onClick={handleRestricted}><img src={global.Image + data.property_id + '/' + data.mediaphoto} className="img-fluid" alt="" /></Link>
                                                                    </>
                                                            }         
                                                            <div className="tags position-absolute">
                                                                <span className="badge">
                                                                    {Object.keys(propertytype).map(key => {
                                                                        return <>
                                                                            {key == data.property_type ? propertytype[key] : ''}
                                                                        </>
                                                                    })}
                                                                </span>
                                                            </div>
                                                            <div className="position-absolute img-action">
                                                                
                                                                <div className="favourite d-none">                                                                    
                                                                    <button type="button" onClick={() => handleAddFavorite(data.property_id)}>
                                                                        {
                                                                            isAdded || data.favoriteproperty == data.property_id
                                                                                ?
                                                                                <i class="bi  bi-heart-fill primaryG"></i>
                                                                                : isRemove
                                                                                ?
                                                                                <i class="bi bi-heart primaryG"></i>
                                                                                : <i class="bi bi-heart primaryG"></i>
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pinfo">
                                                        <div className="pname"><h4>
                                                            {
                                                                userId >0
                                                                ?
                                                                <Link to={/property/ + data.property_id}>{data.title}</Link>
                                                                :
                                                                <Link to="" onClick={handleRestricted}>{data.title}</Link>
                                                            }                                                            
                                                            </h4>
                                                            <address className="paddress"><i class="bi bi-geo-alt me-1"></i> {data.locality}</address></div>
                                                        <div className="pprice"> 
                                                                {
                                                                    formatValue({
                                                                        value: ''+data.property_price+'',
                                                                        intlConfig: { locale: 'en-IN', currency: 'AED' },
                                                                        })
                                                                }
                                                        </div>
                                                        <div className="pspecs d-flex">
                                                            <div className="pspecs-col"><div className="pspecs-img"><img src="images/bedroom-2.svg" className="img-fluid" width="16px" height="16px" /> </div><div className="pspecs-data">{data.bedrooms}</div></div>
                                                            <div className="pspecs-col"><div className="pspecs-img"><img src="images/bathroom-2.svg" className="img-fluid" width="16px" height="16px" /></div> <div className="pspecs-data">{data.bathrooms}</div></div>
                                                            <div className="pspecs-col"><div className="pspecs-img"><img src="images/set-square.svg" className="img-fluid" width="16px" height="16px" /></div> <div className="pspecs-data">{data.area} sqft</div></div>
                                                        </div>
                                                    </div>
                                                    <div className="paction">
                                                        {
                                                            userId >0 
                                                            ?
                                                            <>
                                                            <a href="#" className="btn btn-sm border-0">Schedule Viewing <span></span></a>
                                                            <a href="#" className="btn btn-sm btn-primary">Contact Owner <span></span></a>
                                                            </>
                                                            :
                                                            <>
                                                            <a href="javascript:void(0)" onClick={handleRestricted} className="btn btn-sm border-0">Schedule Viewing <span></span></a>
                                                             <a href="javascript:void(0)" onClick={handleRestricted} className="btn btn-sm btn-primary">Contact Owner <span></span></a>
                                                            </>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    : null}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PropertyListing;