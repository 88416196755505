import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 finfo">
              <div className="footer-widget">
                <div className="footer-widget-logo"><img src="/images/logo.png" className="img-fluid mb-md-3" alt="Broker Free Home" /></div>
                <div className="ftxt">
                  <p>Broker Free Homes is your connect to the world of agent-free and commission-free
                    property deals. Happy Savings!!</p>
                </div>
                <ul className="list-unstyled social">
                  <li className="d-inline-block"><a href="#" target="_blank"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" className="social-svg">
                    <path
                      d="M0 0v24h24v-24h-24zm16 7h-1.923c-.616 0-1.077.252-1.077.889v1.111h3l-.239 3h-2.761v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"/>
                  </svg></a></li>
                  <li className="d-inline-block mx-2"><a href="#" target="_blank"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" className="social-svg">
                    <path
                      d="M0 0v24h24v-24h-24zm18.862 9.237c.208 4.617-3.235 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.079-4.03 3.198-4.03.944 0 1.797.398 2.396 1.037.748-.147 1.451-.42 2.085-.796-.245.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.44.656-.997 1.234-1.638 1.697z"/>
                  </svg></a></li>
                  <li className="d-inline-block"><a href="#" target="_blank"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" className="social-svg">
                    <path
                      d="M14.667 12c0 1.473-1.194 2.667-2.667 2.667-1.473 0-2.667-1.193-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667zm3.846-3.232c.038.843.046 1.096.046 3.232s-.008 2.389-.046 3.233c-.1 2.15-1.109 3.181-3.279 3.279-.844.038-1.097.047-3.234.047-2.136 0-2.39-.008-3.232-.046-2.174-.099-3.181-1.132-3.279-3.279-.039-.845-.048-1.098-.048-3.234s.009-2.389.047-3.232c.099-2.152 1.109-3.181 3.279-3.279.844-.039 1.097-.047 3.233-.047s2.39.008 3.233.046c2.168.099 3.18 1.128 3.28 3.28zm-2.405 3.232c0-2.269-1.84-4.108-4.108-4.108-2.269 0-4.108 1.839-4.108 4.108 0 2.269 1.84 4.108 4.108 4.108 2.269 0 4.108-1.839 4.108-4.108zm1.122-4.27c0-.53-.43-.96-.96-.96s-.96.43-.96.96.43.96.96.96c.531 0 .96-.43.96-.96zm6.77-7.73v24h-24v-24h24zm-4 12c0-2.172-.009-2.445-.048-3.298-.131-2.902-1.745-4.52-4.653-4.653-.854-.04-1.126-.049-3.299-.049s-2.444.009-3.298.048c-2.906.133-4.52 1.745-4.654 4.653-.039.854-.048 1.127-.048 3.299 0 2.173.009 2.445.048 3.298.134 2.906 1.746 4.521 4.654 4.654.854.039 1.125.048 3.298.048s2.445-.009 3.299-.048c2.902-.133 4.522-1.745 4.653-4.654.039-.853.048-1.125.048-3.298z"/>
                  </svg></a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-5 fnav">
              <div className="footer-widget">
                <h4 className="footer-widget-title">Helpful Links</h4>
                <ul className="list-unstyled mb-0">
                  <li><a href="/about-us">About us</a></li>
                  <li><Link to="/subscription"><span>Pricing</span></Link></li>
                  <li><a href="/coming-soon">Blog</a></li>
                  <li><a href="/coming-soon">Contact</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 col-sm-7 fcontact">
              <div className="footer-widget">
                <h4 className="footer-widget-title">Reach Us</h4>
                <ul className="list-unstyled footer-contacts mb-0">
                  <li className="fmail"><i className="bi bi-envelope-fill me-2"></i> <a href="#">info@brokerfreehomes.com</a></li>
                  <li className="flocation"><i className="bi bi-geo-alt-fill me-2"></i> Gllit Technologies - FZCO<br/>
Post Box No. 341496, Dubai Silicon Oasis, Dubai - UAE</li>
                  <li className="fphone"><i className="bi bi-telephone-fill me-2"></i> <a href="tel:+971123456789">+971 123456789</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-5 text-md-end order-md-2 footerbottom-nav">
              <ul className="list-unstyled mb-0">
                <li className="d-inline-block"><a href="coming-soon/">Terms &amp; Conditions</a></li>
                <li className="d-inline-block"><a href="coming-soon/">Privacy Policy</a></li>
              </ul>
            </div>
            <div className="col-md-7 order-md-1 copyright">
              Copyright @2023 Gllit Technologies FZCO. All Rights Reserved.
            </div>

          </div>
        </div>
      </div>
    </footer>

  );
}

export default Footer;