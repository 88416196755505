import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../customComponent/DashboardHeader";
import AuthService from "../../../api/services/AuthService";
import { Link,useLocation } from 'react-router-dom'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";

const userId = sessionStorage.getItem("iduser");
const userToken = sessionStorage.getItem("token");
const SchedulerPage = (props) => {

  const location = useLocation()
  const { propertyId } = location?.state

  const [myfavorite, setMyFavorite] = useState([]);

  const [userdate, setDate] = useState(new Date());
  const [userTime, setUserTime] = useState('');
  const [ownerSchedules, setOwnerSchedule] = useState([]);
  const [propertytitle, setPropertyTitle] = useState();
  

  useEffect(() => {
    handleGetTime();
  },[])

  const handleSchedulerCnfrm = async (userschid) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.sechedulerConfirm(userschid,userToken, propertyId).then(async result => {
      if (result.apirspo) {
        try {
          LoaderHelper.loaderStatus(false);
          handleGetTime(userdate);
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage();
      }
    });
  }

  const handleAddTime = async (userdate, userTime) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.setAddTime(userdate, userTime, userToken, propertyId).then(async result => {
      if (result.apirspo) {
        try {
          LoaderHelper.loaderStatus(false);
          handleGetTime(userdate);
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage();
      }
    });
  }

   const handleGetTime = async (newDate) => {    
    LoaderHelper.loaderStatus(true);
    await AuthService.getScheduleTime(newDate,userToken, propertyId).then(async result => {
      if (result.apirspo) {
        try {
          LoaderHelper.loaderStatus(false);          
          setOwnerSchedule(result?.ownerschedule)
          setPropertyTitle(result?.propertytitle)
          setDate(newDate);
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage();
      }
    });
  }

  const handleRemoveSchedule = async (timeId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.setRemoveSchedule(timeId,userToken).then(async result => {
      if (result.apirspo) {
        try {
          LoaderHelper.loaderStatus(false);
          handleGetTime(userdate);
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage();
      }
    });

  }

  var time = new Date()
    .toLocaleTimeString("en-US", {
      hour12: true
    });

  return (
    <>
      <div class="container-fluid dashboard">
        <div class="row">
          <DashboardHeader />
          <div id="dashboard-content" class="col-lg-9 h-100 offset-lg-2">
            <div class="dahsboard-wrap">
              <div class="dashboard-title">
                <h4>Schedule for: {propertytitle}</h4>
                <hr />
              </div>
              <div class="row bg-white">
                <div class="col-md-6">
                  <div class="d-flex justify-content-between align-items-center">
                      <div className="table-wrap">
                        <div className='app'>
                          <div className='calendar-container' >
                            <h2>
                              <p className='text-center'>
                                <span className='bold'></span>{' '}
                                { /*date.toDateString()*/}
                              </p>
                            </h2>
                          {/*  <Calendar onChange={setDate} value={date} /> */}
                            <Calendar onChange={handleGetTime}/>
                          </div>

                          {/* <p className='text-center'>
                            <span className='bold'>Selected Time:</span>{' '}
                            {userTime}
                          </p> */}
                        </div>
                      </div>
                      {/* <div><button class="btn arrow-prev"></button></div>*/}
                      {/* <div class="active-date">January 2023</div> */}
                      {/*  <div><button class="btn arrow-next"></button></div> */}
                    </div>
                </div>
                <div class="col-md-6">
                  <div class="p-3">                    
                      <h6>Set Time</h6>
                      <div class="d-flex">
                        <div class="col-auto">
                          <input type="time" class="form-control form-control-sm" onChange={(e) => setUserTime(e.target.value)} />
                        </div>
                        <div class="col-auto ms-3"><button class="btn btn-sm primaryB-bg text-white" type="button" onClick={() => handleAddTime(userdate, userTime)}>Add Time</button></div>
                      </div>
                    <ul class="list-unstyled schedule-list mt-5">
                     
                        {ownerSchedules.length > 0 ?
                          ownerSchedules.map((data, idx) =>                          
                            <>
                          <li>
                            <div class="schedule-time"><div className="chat-sender fw-semi">{data?.requestedby}</div> <span className="text-dark">{moment( data?.scheduletime,"HHmm").format("LT")}</span></div>
                              { 
                                data?.isrequested === 1 && data?.isconfirm === 0 ?
                                <>
                                <div class="schedule-time text-dark"> <span class="badge status-badge pending">Booking Received</span>
                                </div>
                                <div> <button class="btn btn-sm btn-success" type="button" onClick={() => handleSchedulerCnfrm(data.userschid)}><i class="bi bi-check-lg"></i>&nbsp; Confirm</button></div>
                                </>
                                : data?.isrequested === 1 && data?.isconfirm === 1 ?
                                <>
                                <div class="schedule-time text-dark align-self-end"><span class="badge status-badge approved">Confirmed</span></div>
                                <div> <Link className="btn btn-sm btn-info" to={"/message"} state={data.ownerpropertyid}><i class="bi bi-chat-left-text"></i>&nbsp; Chat Now</Link> &nbsp; 
                                <button class="btn btn-sm btn-danger"  onClick={() => handleRemoveSchedule(data?.idschedule)}>Cancel</button></div>
                               </>
                                :                                
                                <div><button class="btn btn-sm btn-danger" type="button" onClick={() => handleRemoveSchedule(data?.idschedule)}>Remove</button></div>
                              }  
                             </li>                                                                                              
                            </>
                          ) : null}
                                       
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default SchedulerPage;