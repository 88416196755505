import React, { useEffect,useState } from "react";
import DashboardHeader from "../../../customComponent/DashboardHeader";
import AuthService from "../../../api/services/AuthService";
import { Link } from "react-router-dom";
const userId = sessionStorage.getItem("iduser");  
const MyfavoritePage =  () => {     
    const [myfavorite, setMyFavorite] = useState([]);
    useEffect(() => {      
        handleMyFavorite();
    }, []);

    const handleMyFavorite = async () => {  
        await AuthService.getMyFavorite(userId).then(async result => {
            if (result.apirspo) {
                setMyFavorite(result.myfavorite);
            } else {
           
            }
        });
    }
    const handleRemovefavorite = async (pid) => {  
        await AuthService.removeMyFavorite(userId,pid).then(async result => {
            if (result.apirspo) {
                 handleMyFavorite();
            } else {
           
            }
        });
    }
    
    return (
        <>
            <div className="container-fluid dashboard">
                <div className="row"> 
                <DashboardHeader />  
                <div id="dashboard-content" className="col-lg-9 h-100 offset-lg-2">
                        <div className="dahsboard-wrap">
                            <div className="dashboard-title">
                                <h4>My Favorite Listing</h4>                                                                                             
                                <hr />
                            </div>  
                            <div className="table-wrap">
                            <table cellpadding="0" cellspacing="0" className="table table-data">
                        <tr>
                            <th>S.No.</th>
                            <th>Property Name</th> 
                            <th>Action</th> 
                        </tr>
                            {myfavorite.map(function(data, idx){         
                                    return (
                                        <>
                                        <tr>
                                        <td>{idx+1}</td>                                         
                                         <td>
                                         <Link  to= {/property/+data.property_id}><img src={global.Image+data.property_id+'/'+ data.mediaphoto}  className="img-fluid" alt="" width={100}/></Link> &nbsp;
                                            <Link to= {/property/+data.id}  className="property-name fw-semi d-inline-block">{data.title}</Link>
                                            </td> 
                                            <td> <a className="custom-btn trash text-danger cursor-pointer" onClick={() => handleRemovefavorite(data.property_id)}>Delete</a></td>                                                                                                                       
                                         </tr>  
                                        </>                                       
                                    )
                                })}
                                </table> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            
        </>
    )
}

export default MyfavoritePage;