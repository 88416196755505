import AuthHeader from "../../../customComponent/AuthHeader";
import Footer from "../../../customComponent/Footer";

const RegisterthanksPage = () => {
  const userRole = sessionStorage.getItem("userrole");
  const handleContinute = () => {
    if (userRole == 1) {
       window.location.href = '/';
      }else if (userRole == 2) {
        window.location.href = '/seller';
      }else if (userRole == 3) {
        window.location.href = '/tenant';
      }else if (userRole == 4) {
        window.location.href = '/landlord';
      }else{
        window.location.href = '/';
      }
}

  return (
    <>
      <AuthHeader />
      <div className="inner-banner"></div>     
        <section className="mb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
              <div className="login-form"><h4 className="secondary-font primaryB">Thank You for signing up.</h4>
                <p>As a limited-time welcome offer, your registration has been upgraded to a <strong>free trial</strong> of the <strong>Premium membership</strong> for six months.</p>
                <p className="fs-16 fw-semi primaryB">Happy Saving!</p>
                <p><button className="btn btn btn-primary"  onClick={() => (handleContinute())}>Click here to continue</button></p></div>
              </div>
            </div>
          </div>
        </section>
      <Footer />
    </>
  );
}

export default RegisterthanksPage;