import React, { useState, useContext } from "react";
import AuthHeader from "../../../customComponent/AuthHeader";
import Footer from "../../../customComponent/Footer";
import { Link } from "react-router-dom";

const ComingSoonPage = () => {
      const [key, setKey] = useState('home');
  return (
    <>
      <AuthHeader />

     <div className="inner-banner">
      </div>
      <div className="container mb-5">
        <div className="row">
      <div className="col-md-8 offset-md-2 subscriptions-plan my-5">
      <div class="section-title text-center"><h2>This page will be coming soon.</h2></div>
      </div>
      </div>
    </div>
 
      <Footer />
    </>

  );
}

export default ComingSoonPage; 