import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../customComponent/DashboardHeader";
import AuthService from "../../../api/services/AuthService";

const UserProperty = () => {
    const userId = sessionStorage.getItem("iduser");
    const [myprofile, setMyProfile] = useState([]);

    useEffect(() => {
        handleMyProfile();
    }, []);

    const handleMyProfile = async () => {
        await AuthService.getMyProfile(userId).then(async result => {
            if (result.apirspo) {
                setMyProfile(result.myprofile);
            } else {

            }
        });
    }
    return (
        <>
            <div className="container-fluid dashboard">
                <div className="row">
                    <DashboardHeader />
                    <div id="dashboard-content" className="col-lg-9 h-100 offset-lg-2">
                        <div className="dahsboard-wrap">
                            <div className="dashboard-title">
                                <h4>My Profile</h4>
                                <hr />
                            </div>
                            <div className="row">
                                <div className="col-md-6">                                
                                <h5 className="primaryB fw-semi">Info</h5>
                           
                            <ul className="list-unstyled user-profile">
                                <li className="list-unstyled"><div>Role</div> <div>{myprofile.role_name}</div></li>
                                <li className="list-unstyled"><div>Name</div><div>{myprofile.name}</div></li>
                                <li className="list-unstyled"><div>Email</div><div>{myprofile.email} <span className="badge status-badge pending">{myprofile.is_email_verified ==1 ?'Verified':'Verification pending'} </span></div></li>
                                <li className="list-unstyled"><div>Contact</div><div>{myprofile.mobile} </div></li>
                                <li className="list-unstyled"><div>City</div><div>{myprofile.city}</div></li>
                                <li className="list-unstyled"><div>Nationality</div><div>{myprofile.nationality}</div></li>
                                <li className="list-unstyled"><div>Account created</div><div>{myprofile.created_at}</div></li>
                                <li className="list-unstyled"><div>Status</div><div>{(myprofile.status == 1 ? 'Active' : 'Inactive')}</div></li>
                            </ul>
                            </div>
                            <div className="col-md-6">
                                <h5 className="primaryB fw-semi">My Plan</h5>
                            <ul className="list-unstyled user-profile">
                                <li className="list-unstyled"><div>Plan</div> <div>{sessionStorage.getItem("planname")}</div></li>
                                <li className="list-unstyled"><div>Amount</div><div>{sessionStorage.getItem("planamount")}</div></li>
                                <li className="list-unstyled"><div>Subscription Date</div><div>{sessionStorage.getItem("planstart")}</div></li>
                                <li className="list-unstyled"><div>Expiry Date</div><div>{sessionStorage.getItem("planexpire")}</div></li>
                                <li className="list-unstyled"><div>Status</div><div>{(myprofile.status == 1 ? 'Active' : 'Inactive')}</div></li>
                            </ul>                              
                            </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserProperty;