import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../customComponent/DashboardHeader";
import AuthService from "../../../api/services/AuthService";
import { Link,useLocation } from 'react-router-dom'
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";

const userId = sessionStorage.getItem("iduser");
const userToken = sessionStorage.getItem("token");
const UserSchedulePage = () => {

  const location = useLocation(); 
  const { propertyid } = location?.state
  const [myfavorite, setMyFavorite] = useState([]);
  const [userdate, setDate] = useState(new Date());
  const [userTime, setUserTime] = useState('');
  const [ownerSchedules, setOwnerSchedule] = useState([]);
  const [propertytitle, setPropertyTitle] = useState();


  useEffect(() => {
    handleGetTime();
  },[])

  const handleUserSchedulerCnfrm = async (userdate, userTime) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.sechedulerConfirm(userdate, userTime, userId, propertyid).then(async result => {
      if (result.apirspo) {
        try {
          LoaderHelper.loaderStatus(false);
          alertSuccessMessage('Successfully');
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage();
      }
    });
  }

  const handleGetTime = async (newDate) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getUserScheduleTime(newDate,propertyid,userToken).then(async result => {      
      if (result.apirspo) {
        try {
          LoaderHelper.loaderStatus(false);
          setOwnerSchedule(result?.userschedule)
          setPropertyTitle(result?.propertytitle)
          setDate(newDate);
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage();
      }
    });
  }


  const handleTimeReserve = async (scheduleId,ownerId,ownerPropertyId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.setReserveTime(scheduleId,ownerId,ownerPropertyId,userToken).then(async result => {
      if (result.apirspo) {
        try {
          LoaderHelper.loaderStatus(false);
          handleGetTime();
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage();
      }
    });

  }

  var time = new Date()
    .toLocaleTimeString("en-US", {
      hour12: true
    });

  return (
    <>
      <div class="container-fluid dashboard">
        <div class="row">
          <DashboardHeader />
          <div id="dashboard-content" class="col-lg-9 h-100 offset-lg-2">
            <div class="dahsboard-wrap">
              <div class="dashboard-title">
                <h4>Schedule for: {propertytitle}</h4>
                <hr />
              </div>
              <div class="row bg-white">
                <div class="col-md-6">
                  <div class="d-flex justify-content-between align-items-center">
                      <div className="table-wrap">
                        <div className='app'>
                          <div className='calendar-container' >
                            <h2>
                              <p className='text-center'>
                                <span className='bold'></span>{' '}
                                {/*date.toDateString()*/}
                              </p>
                            </h2>
                            {/*<Calendar onChange={setDate} value={date} />*/}
                            <Calendar onChange={handleGetTime}/>
                          </div>

                        </div>
                      </div>
                     
                    </div>
                </div>
                <div class="col-md-6">
                  <div class="p-3">
                    
                    
                    <ul class="list-unstyled schedule-list">                     
                        {ownerSchedules.length > 0 ?
                          ownerSchedules.map((data, idx) =>
                            <>
                            <li>
                              <div class="schedule-time text-dark">{moment( data?.scheduletime,"HHmm").format("LT")}</div>
                              <div>
                                { 
                                  data?.isrequested === 1 && data?.isconfirm === 0 ? 
                                      <>
                                      <div class="schedule-time text-dark"><span class="badge status-badge pending">Booking Requested</span>
                                      </div>
                                      </>
                                  : data?.isrequested === 1 && data?.isconfirm === 1 ?
                                        <>
                                        <div> <span class="badge status-badge approved" >Confirmed</span> <Link className="btn btn-sm btn-info" to={"/message"} state={data.ownerpropertyid}><i class="bi bi-chat-left-text"></i> &nbsp; Chat Now</Link></div>
                                        { /*<div><a href="/contact" class="btn btn-sm btn-info">Contact</a> 
                                        <button class="btn btn-sm btn-danger">Cancel</button></div> */}
                                        </>
                                  :
                                  <button class="btn btn-sm bg-warning" type="button" onClick={() => handleTimeReserve(data?.idschedule,data?.ownerid,data?.ownerpropertyid)}>Reserve</button>
                                }                                
                                {/* <button class="btn btn-sm bg-warning" type="button" onClick={() => handleTimeReserve(data?.idschedule,data?.ownerpropertyid)}>Reserve</button> */}
                                </div>
                                </li>
                            </>
                          ) : null}                      
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserSchedulePage;