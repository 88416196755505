import React, { useState, useContext } from "react";
import AuthHeader from "../../../customComponent/AuthHeader";
import Footer from "../../../customComponent/Footer";
import { Link } from "react-router-dom";
const UsefulllinksPage = () => {
  return (
    <>
      <AuthHeader />

     <div className="inner-banner">
      </div>
      <div className="container mb-5">
        <div className="row">
      <div className="col-md-6 offset-md-3">
      <div class="section-title text-center"><h2>Useful Links</h2></div>
      <p className="fs-16">There’s a plethora of information available on the internet and then there’s the information that you actually need. </p>
<p className="fs-16">We’ve done the research for you so that you don’t have to. Here are some curated links to pages that are of relevance to you.</p>
      <div className="accordion faqs" id="useful-links">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        FOR SELLER
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#useful-links">
      <div className="accordion-body">
        <p>
       <Link to="//dubailand.gov.ae/en/eservices/request-for-sale-registration/#/" target="_blank"><strong>Request for Sale Registration</strong></Link></p>
      
        <p>
       <Link to="//dubailand.gov.ae/en/eservices/request-to-register-the-sale-of-a-mortgaged-property/#/" target="_blank"><strong>Request to Register the Sale of a Mortgaged Property</strong>  </Link></p>

      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        FOR LANDLORD
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#useful-links">
      <div className="accordion-body">
        <p>
       <Link to="//dubailand.gov.ae/en/eservices/request-to-register-and-renew-ejari-contract/#/" target="_blank"><strong>Request to Register or Renew Ejari Contract</strong></Link></p>
      
        <p>
       <Link to="//dubailand.gov.ae/en/eservices/request-for-cancellation-of-ejari-contract/#/" target="_blank"><strong>Request to Cancel Ejari Contract</strong></Link></p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        FOR TENANT
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#useful-links">
      <div className="accordion-body">
        <p>
       <Link to="//dubailand.gov.ae/en/eservices/request-to-register-and-renew-ejari-contract/#/" target="_blank"><strong>Request to Register or Renew Ejari Contract</strong></Link></p>
      
        <p>
       <Link to="//dubailand.gov.ae/en/eservices/request-for-cancellation-of-ejari-contract/#/" target="_blank"><strong>Request to Cancel Ejari Contract</strong></Link></p>
      </div>
    </div>
  </div>
</div>
      </div>
      </div>
      </div>
 
      <Footer />
    </>

  );
}

export default UsefulllinksPage; 