import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import AuthHeader from "../../../customComponent/AuthHeader";
import Footer from "../../../customComponent/Footer";
import Filters from "../../../customComponent/Filters";
import { Link,useLocation } from "react-router-dom";
import { formatValue } from "react-currency-input-field";
import { ConsoleLogs } from "../../../utils/ConsoleLogs";
import Swal from 'sweetalert2'
/*
    From the Search Bar page : We have post parameters : 
    navigate('/search', { 
        state: {
            propertypurpose:propertypurpose,    //RENT - 2 /SELL - 1 / 
            propertycity:propertycity,          //City of the property : Dubai which is single option in previous form
            propertytype:propertytypes,         // Type of Property : Apartment / Villa / etc.
            searchterm:searchtermdata           //Search Term : Array of search Terms : which further used as Location 
        }
    });
    Hence on Search Page we have above params with useLocation option

    //selected options :
                        
    //                  filterPropertyPurpose   //RENT - 2 /SELL - 1 / Choosen on Search Bar Initially
                        filterPropertyTypeInfo  // Type of Property : Apartment / Villa / etc. // choosen on Search Bar
                        searchTerms             //Selected Terms

    //Avaialble options :    
                    
*/

const SearchPropertyPage = () => {
    //Initially retrieve the params using useLocation from the Request received from Search Bar. 
    // Initialize the respective values in the filter params
    const location = useLocation();

    const filterPropertyPurpose = location.state.propertypurpose;
    // Initial Search Term | would be array always can be set as LocalityInfo later
    const [searchTerms, setSearchTerms] = useState(location.state.searchterm);// Initial Search Term | would be array always can be set as LocalityInfo later    
    const [searchtermerror, setSearchTermError] = useState(false);          //These are duplicate form searchBar
    const maxAllowedSearchTerms = 2;    //Maximum(3) Search Terms to get searched.  /These are duplicate form searchBar
    
    const onEnterSearchTerm = (place, eventsrc) => {
        if(!(place == undefined || place.address_components == undefined)){ // if valid search Term identified 
            let  latestTerm = place.address_components[0].long_name;
            setSearchTerms( prevSearchTerms => {
                if(prevSearchTerms.length>maxAllowedSearchTerms){
                    setSearchTermError(true);
                    return prevSearchTerms;
                } else { 
                    return [...prevSearchTerms, latestTerm];};
                });
            
        }
    }

    let selectedPropertyType = { };
    if(!selectedPropertyType.hasOwnProperty(location.state.propertytype)) {
        selectedPropertyType[location.state.propertytype] = {"id":location.state.propertytype, "propertytypeinfo":location.state.propertytype}  ;
    }
    //Filter purpose variables : 
    //Initalize these filter variables from previous Search bar parms data 
    const [filterLocalityInfo, setLocalityInfo] = useState({});
    const [filterPropertyTypeInfo, setPropertyTypeInfo] = useState(selectedPropertyType);
    const [filterPropertyBedsInfo, setPropertyBedsInfo] = useState({});
    const [filterPropertyBathroomsInfo, setPropertyBathroomsInfo] = useState({});
    const [filterPropertyAmenitiesInfo, setPropertyAmenitiesInfo] = useState({});

    const [filterPropertyFurnishingsInfo, setPropertyFurnishingsInfo] = useState({});
    const [filterPropertyStatusInfo, setPropertyStatusInfo] = useState({});

    const [filterMinValInfo, setMinValInfo] = useState();
    const [filterMaxValInfo, setMaxValInfo] = useState();
 
    
    const userId = sessionStorage.getItem("iduser");              //Getting Logged in user Id infomration from the localStorage
    
    const [singlelisting, setSingleListing] = useState([]);     
    const [propertyTypes, setPropertyTypes] = useState({});     //Contain all available property Types
    const [bedrooms, setBedrooms] = useState({});               //Contain all bedroom options
    const [bathrooms, setBathrooms] = useState({});               //Contain all bathrooms options 
    const [amenities, setAmenities] = useState({});               //Contain all Amenities options 

    const [funishings, setFurnishings] = useState({});               //Contain all Furnishings options 
    const [pstatus, setpStatus] = useState({});               //Contain all Property Status options 

    const [minVal, setMinVal] = useState(1);                   //Contain Minimum Value
    const [maxVal, setMaxVal] = useState(1);                   //Contain Maximum Value
    const [avg, setAvg] = useState(1);
    const [isresultfound, setResultFound] = useState(); //added by deepak
    

    const [totlalisting, setTotlaListing] = useState([]);
    //const [propertytype, setPropertyType] = useState([]);
    
    const [isfavorite, setIsFavorite] = useState([]);
    const [resultmessage, setResultMessage] = useState([]);
    

    useEffect(() => {
        handlePropertySearch(filterLocalityInfo,filterPropertyTypeInfo,filterPropertyBedsInfo, filterPropertyBathroomsInfo, filterPropertyAmenitiesInfo, filterPropertyFurnishingsInfo, filterPropertyStatusInfo, filterMinValInfo,filterMaxValInfo);
    },[filterLocalityInfo, filterPropertyTypeInfo, filterPropertyBedsInfo, filterPropertyBathroomsInfo, filterPropertyAmenitiesInfo, filterPropertyFurnishingsInfo, filterPropertyStatusInfo, filterMinValInfo,filterMaxValInfo]);
    
    //function used to retrieve values from Filter Component Selection basis
    const getFilterDetails = (searchTerms, propertyTypeInfo, propertyBedsInfo, propertyBathroomsInfo, propertyAmenitiesInfo, propertyFurnishingInfo, propertyStatusInfo, propertyMinValInfo, propertyMaxValInfo) => {    
        //console.log("getFilter Function called  Details : " + JSON.stringify(propertyInfo) )
        //setLocalityInfo(localityInfo);
        setPropertyTypeInfo(propertyTypeInfo);
        setPropertyBedsInfo(propertyBedsInfo);
        setPropertyBathroomsInfo(propertyBathroomsInfo);
        setPropertyAmenitiesInfo(propertyAmenitiesInfo);
        setPropertyFurnishingsInfo(propertyFurnishingInfo);
        setPropertyStatusInfo(propertyStatusInfo);
        
        setMinValInfo(propertyMinValInfo);
        setMaxValInfo(propertyMaxValInfo); 
    }
    const handlePropertySearch = async () => {
        await AuthService.getSearchResult(filterPropertyPurpose,  searchTerms, filterLocalityInfo, filterPropertyTypeInfo, filterPropertyBedsInfo, filterPropertyBathroomsInfo, filterPropertyAmenitiesInfo, filterPropertyFurnishingsInfo, filterPropertyStatusInfo, filterMinValInfo, filterMaxValInfo).then(async result => {
            setResultFound(result.resultfound); // added by deepak
            setSingleListing(result.singlelisting);

            setPropertyTypes(result.pttypes);
            setBedrooms(result.bdtypes);
            setBathrooms(result.bthtypes);
            setAmenities(result.amtypes);
            setFurnishings(result.frntypes);
            setpStatus(result.sttypes);

            setMinVal(result.ptmin);
            setMaxVal(result.ptmax);
            setAvg(Number( (result.ptmin+result.ptmax)/2));

            setTotlaListing(result.singlelisting.length);

            
          //} else {
          //  setResultMessage('There are no results that match your search, You can view other relevent property');
          //  setSingleListing(result.singlelisting);
          //  setPropertyTypes(result.pttypes)
          //  setBedrooms(result.bedroomtypesdropd)
          //  setTotlaListing(result.singlelisting.length);
    
          //}
        });
      }

    const handleGetPropertyType = (pTypeId) =>{
      //  console.log("Property Type"+ pTypeId);
      //  console.log(propertyTypes);
        return propertyTypes[pTypeId];
    }

    const handleAddFavorite = async (property_id) => {
        await AuthService.AddFavorite(userId,property_id).then(async result => {
            if (result.apirspo) { 
                if (result.added) {            
                    setIsFavorite('favorite-button is-favorite'); 
                 }else if(result.removed){
                    setIsFavorite('favorite-button notis-favorite');
                    }else{
                        setIsFavorite('favorite-button');
                }
               // console.log(result.favoritelist);
            } else {

            }
        });
    }  
    const handleRestricted = () => {
        Swal.fire({
            title: 'This link is exclusively for registered users.',
            text: "Please Login/Register to continue.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Login'
          }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = '/login';
            }
          })
      };
    return (
        <>
            <AuthHeader />
            <div className="inner-banner">
           
            </div>
            <section className="search mb-5">
                <div className="container">
                    <div className="row">                  

                    {/*singlelisting?.length > 0 ?
                        <Filters searchTerms={searchTerms} filterPropertyPurpose={filterPropertyPurpose} propertytype={propertyTypes} bedrooms={bedrooms} propertyTypeInfo={filterPropertyTypeInfo}  getFilterDetails={getFilterDetails} />
                        : 
                        null
                    */}    
                    <Filters 
                        searchTerms={searchTerms}                           //Coming initially form previous screen
                        filterPropertyPurpose={filterPropertyPurpose}       //Coming initially from previous screen
                        filterPropertyTypeInfo={filterPropertyTypeInfo}     //Coming initially from previous screen


                        propertyTypes={propertyTypes}       // All Property Types options // Initially can be blank
                        bedrooms={bedrooms}                // All  bedroom options
                        bathrooms={bathrooms}              // All  bedroom options
                        amenities={amenities}              //All Available amenities
                        funishings={funishings}             //All Available furnishings
                        propertystatus={pstatus}                   //All Property Status

                        propertyMinVal={minVal}     //Minimum Price
                        propertyMaxVal={maxVal}     // Maximum Price
                        propertyAvgVal = {avg}         //Average Val

                        getFilterDetails={getFilterDetails} 
                        />

                        <div className="col-lg-9 search-list">
                            <div className="row">
                           
                                <div className="col-sm-12 mb-2">
                                    {
                                      totlalisting > 0 && isresultfound == true
                                      ?
                                      <>
                                       {totlalisting} Properties for {filterPropertyPurpose== 2 ? 'Rent' : 'Sale'} in your selected location(s)
                                      </>
                                      :
                                      <>
                                        There are no results that match your search criteria. <br></br>View other properties on {filterPropertyPurpose== 2 ? 'Rent' : 'Sale'}.
                                      </>
                                    } 
                                    
                                    </div>
                                {singlelisting.map(function (data, idx) {
                                    return (
                                        <>
                                            <div className="col-lg-4 col-sm-6 plist-col" key={idx}>
                                                <div className="plist-wrap">
                                                    <div className="pthumb">
                                                        <div className="pthumb-holder position-relative">    
                                                            <Link to={/property/ + data.property_id}><img src={global.Image + data.property_id + '/' + data.mediaphoto} className="img-fluid" alt="" /></Link> 
                                                            <div className="tags position-absolute"><span className="badge">{handleGetPropertyType(data.property_type)}</span></div>
                                                            <div className="position-absolute img-action">
                                                                <div className="favourite d-none">
                                                                <button type="button" className={(data.favoriteproperty === data.property_id) ? 'favorite-button is-favorite' : 'favorite-button notis-favorite'} onClick={() => handleAddFavorite(data.property_id)}>
                                                                    <i className="favorite__icon favorite--enable"></i>
                                                                    <i className="favorite__icon favorite--not"></i>
                                                                </button>  
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pinfo">
                                                        <div className="pname"><h4>                                                            
                                                            <Link to={/property/ + data.property_id}>{data.title}</Link>  
                                                            </h4>
                                                            <address className="paddress"><img src="images/location.svg" /> {data.locality},{data.City}</address></div>
                                                            <div className="pprice">
                                                                {
                                                                    formatValue({
                                                                        value: ''+data.property_price+'',
                                                                        intlConfig: { locale: 'en-IN', currency: 'AED' },
                                                                        })
                                                                }
                                                            </div>
                                                        <div className="pspecs d-flex">
                                                            <div className="pspecs-col"><div className="pspecs-img"><img src="images/bedroom-2.svg" className="img-fluid" width="16px" height="16px" /> </div><div className="pspecs-data">{data.bedrooms}</div></div>
                                                            <div className="pspecs-col"><div className="pspecs-img"><img src="images/bathroom-2.svg" className="img-fluid" width="16px" height="16px" /></div> <div className="pspecs-data">{data.bathrooms}</div></div>
                                                            <div className="pspecs-col"><div className="pspecs-img"><img src="images/set-square.svg" className="img-fluid" width="16px" height="16px" /></div> <div className="pspecs-data">{data.yardsize} sqft</div></div>
                                                        </div>

                                                    </div>
                                                    <div className="paction">
                                                    {
                                                            userId >0 
                                                            ?
                                                            <>
                                                            <a href="#" className="btn btn-sm border-0">Schedule Viewing <span></span></a>
                                                            <a href="#" className="btn btn-sm btn-primary">Contact Owner <span></span></a>
                                                            </>
                                                            :
                                                            <>
                                                            <a href="javascript:void(0)" onClick={handleRestricted} className="btn btn-sm border-0">Schedule Viewing <span></span></a>
                                                             <a href="javascript:void(0)" onClick={handleRestricted} className="btn btn-sm btn-primary">Contact Owner <span></span></a>
                                                            </>
                                                    }                                                        
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                               {/*} <div className="col-sm-12 text-center"><a href="/#" className="btn btn-primary">Load More</a></div>*/}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </>
    )
}

export default SearchPropertyPage;