import React from "react";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const BlogPage = () => {
    const owlOptions = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: true,
        autoplay: false,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            767: {
                items: 2,
                
            },
            1200: {
                items: 3,
                mouseDrag:false,
            }
        },
    };
    return (

        <section id="blog">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="section-title text-center">
                            <h5 className="secondaryG">Catch up with the Latest</h5>
                            <h2>Recent News, Guides and Articles</h2>
                        </div>
                        <OwlCarousel className="owl-theme" {...owlOptions}>
                            <div className="item">
                                <div className="post-thumb"><img src="images/blog-1.jpg" className="img-fluid" alt="" /></div>
                                <div className="post-txt">
                                    <h2 className="post-title">Dubai emerges as a popular second-home destination for expats</h2>
                                    <p className="post-excert">The emirate’s stable economy, which is set to expand to 4.5 per cent in 2022, is one of the major reasons that has made many expats select Dubai as their second home</p>
                                    <div className="text-end"><a href="/coming-soon" className="btn btn-sm btn-outline-grey">Read More</a></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="post-thumb"><img src="images/blog-2.jpg" className="img-fluid" alt="" /></div>
                                <div className="post-txt">
                                    <h2 className="post-title">Tenants in Dubai become homeowners as rents soar and mortgage rates hold steady</h2>
                                    <p className="post-excert">Experts say they are noticing a trend of residents becoming first-time buyers</p>
                                    <div className="text-end"><a href="/coming-soon" className="btn btn-sm btn-outline-grey">Read More</a></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="post-thumb"><img src="images/blog-3.jpg" className="img-fluid" alt="" /></div>
                                <div className="post-txt">
                                    <h2 className="post-title">Can the Dubai real estate market defy a global downturn in 2023?</h2>
                                    <p className="post-excert">The city is less exposed to interest rate increases because of the high prevalence of cash buyers</p>
                                    <div className="text-end"><a href="/coming-soon" className="btn btn-sm btn-outline-grey">Read More</a></div>
                                </div>
                            </div>
                        </OwlCarousel>

                        {/* <div className="owl-carousel blog-owl">
                          
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogPage;