import React, { useContext } from "react";
import { ProfileContext } from "../context/ProfileProvider";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import LandingPage from "../ui/pages/LandingPage";
import AuthHeader from "../customComponent/AuthHeader";
import Footer from "../customComponent/Footer";
import LoginPage from "../ui/pages/LoginPage";
import UserProperty from "../ui/pages/UserProperty";
import DashboardPage from "../ui/pages/DashboardPage";
import MylistingPage from "../ui/pages/MylistingPage";
import MyfavoritePage from "../ui/pages/MyfavoritePage";
import ListingPage from "../ui/pages/ListingPage";
import SignupPage from "../ui/pages/SignupPage";
import BuyerPage from "../ui/pages/BuyerPage";
import LandrodPage from "../ui/pages/LandrodPage";
import SellerPage from "../ui/pages/SellerPage";
import TenanPage from "../ui/pages/TenanPage";
import SearchPropertyPage from "../ui/pages/SearchPropertyPage";
import DetailPropertyPage from "../ui/pages/DetailPropertyPage";
import SubscriptionPage from "../ui/pages/SubscriptionPage";
import MessagePage from "../ui/pages/MessagePage";
import SchedulerPage from "../ui/pages/SchedulerPage";
import UserSchedulePage from "../ui/pages/UserSchedulePage";
import RegisterthanksPage from "../ui/pages/RegisterthanksPage"
import AboutusPage from "../ui/pages/AboutusPage";
import FaqPage from "../ui/pages/FaqPage";
import DisclaimerPage from "../ui/pages/DisclaimerPage";
import ContactPage from "../ui/pages/ContactPage";
import UsefulllinksPage from "../ui/pages/UsefulllinksPage";
import UsefullformsPage from "../ui/pages/UsefullformsPage";
import ComingSoonPage from "../ui/pages/ComingSoonPage";

global.Image = "https://funnel.techpapaya.in/public/uploads/";
//global.Image = "http://localhost/bfh/backend/public/uploads/";
const Routing = () => {
  const [profileState] = useContext(ProfileContext);
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("iduser");
  const userRole = sessionStorage.getItem("userrole");
  return (
    <Router basename="/">
   
      <Routes>
        <Route exact path="/" element={<LandingPage />}></Route>
        <Route exact path="/login" element={<LoginPage />}></Route>
        <Route exact path="/signup" element={<SignupPage />}></Route>
        <Route exact path="/buyer" element={<BuyerPage />}></Route>
        <Route exact path="/landlord" element={<LandrodPage />}></Route>
        <Route exact path="/seller" element={<SellerPage />}></Route>
        <Route exact path="/tenant" element={<TenanPage />}></Route>
        <Route exact path="/subscription" element={<SubscriptionPage />}></Route>     
        <Route exact path="/about-us" element={<AboutusPage />}></Route>
        <Route exact path="/faqs" element={<FaqPage />}></Route>
        <Route exact path="/disclaimer" element={<DisclaimerPage />}></Route> 
        <Route exact path="/contact" element={<ContactPage />}></Route> 
        <Route exact path="/useful-links" element={<UsefulllinksPage />}></Route> 
        <Route exact path="/useful-forms" element={<UsefullformsPage />}></Route> 
        <Route exact path="/coming-soon" element={<ComingSoonPage />}></Route>
        <Route exact path="/register-thanks" element={<RegisterthanksPage />}></Route>               
        <Route exact path="/search" element={<SearchPropertyPage />}></Route>
        <Route exact path="/message" element={<MessagePage />}></Route> 
        <Route exact path="/scheduler" element={<SchedulerPage />}></Route>
        <Route exact path="/userschedule" element={<UserSchedulePage />}></Route>
        {
          (userId >0)
              ?
              <>
              <Route exact path="/profile" element={<UserProperty />}></Route>
              <Route exact path="/dashboard" element={<DashboardPage />}></Route>
              <Route exact path="/mylisting" element={<MylistingPage />}></Route>
              <Route exact path="/myfavorite" element={<MyfavoritePage />}></Route> 
              <Route exact path="/property/:id" element={<DetailPropertyPage />}></Route> 
              </>
              :
              <>
              <Route path='/profile' element={<Navigate replace to='/login' />} />
              <Route path='/dashboard' element={<Navigate replace to='/login' />} />
              <Route path='/mylisting' element={<Navigate replace to='/login' />} />
              <Route path='/myfavorite' element={<Navigate replace to='/login' />} />
              <Route path='/property/:id' element={<Navigate replace to='/login' />} />
              </>
        }       
               
        {
          (userRole == 2 || userRole == 4) 
                ? 
                <><Route exact path="/listing" element={<ListingPage />}></Route>
                <Route exact path="/listing/:id" element={<ListingPage />}></Route> </>
                :null
        }
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default Routing;