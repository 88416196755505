import React from "react";
import { Link } from "react-router-dom";

import PropertyListing from "../../../customComponent/PropertyListing";
import SavingCalculator from "../../../customComponent/SavingCalculator";
import BlogPage from "../../../customComponent/BlogPage";
import Mortgagecalculator from "../../../customComponent/Mortgagecalculator";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AuthHeader from "../../../customComponent/AuthHeader";
import Footer from "../../../customComponent/Footer";
<><script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>  </>

const SellerPage = () => {
  const owlOptions = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    loop:true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        767: {
            items: 2,
        },
        1200: {
            items: 3,
        }
    },
};
  return (
    <>
      <AuthHeader />
      <div className="page-banner seller-banner">
        <div id="banner-caption" className="container">
          <div className="d-flex banner-caption-wrap">
            <div className="banner-txt">
              <h1>100% Happiness <br></br>
                0% Commission</h1>
              <h4 className="mb-0">Sell directly to a buyer</h4>
			    <div className="mt-5">
          {sessionStorage.getItem('token') !==null ? <>
          <Link className="btn btn-primary btn-lg" to="/listing"><span>List your Property</span></Link>
          </> : <>
          <Link className="btn btn-primary btn-lg" to="/login"><span>List your Property</span></Link>         
          </>}            
          </div>
            </div>
          </div>
        </div>
      </div>
     
      <section id="single-tips">
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="col-xl-10 offset-xl-1 position-relative single-tips-wrap withform">
                <div className="section-title text-center"><h2>SAVINGS all the Way</h2></div>

                <div className='container-fluid' >
                  <OwlCarousel className="owl-theme" {...owlOptions}>
                    <div className="item">
                      <div className="saving-card text-center">
                        <div className=""><img src="images/zero-commission-deal.svg" className="img-fluid m-auto" alt="Zero Commission Deals" /></div>
                        <h4>Zero Commission Deals</h4>
                        <hr></hr>
                        <p>A little DIY means substantial money saved. And it's so easy!</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="saving-card text-center">
                        <div className=""><img src="images/single-point-research.svg" className="img-fluid m-auto" alt="Single Point Research" /></div>
                        <h4>Single Point Research</h4>
                        <hr></hr>
                        <p>We've done the ground work, so that you don't have to.</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="saving-card text-center">
                        <div className=""><img src="images/low-plateform-fee.svg" className="img-fluid m-auto" alt="Low Platform Charge" /></div>
                        <h4>Low Platform Charge</h4>
                        <hr></hr>
                        <p>Nominal subscription fee, NO transaction charges!</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="saving-card text-center">
                        <div className=""><img src="images/any-time-deal.svg" className="img-fluid m-auto" alt="Anytime Deal" /></div>
                        <h4>Anytime Deal</h4>
                        <hr></hr>
                        <p>Co-ordinate the viewing of your new home at your own convenience. Skip the scheduling nightmare with multiple Agents.</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="saving-card text-center">
                        <div className=""><img src="images/true-empowerment.svg" className="img-fluid m-auto" alt="True Empowerment" /></div>
                        <h4>Full Control</h4>
                        <hr></hr>
                        <p>Stay in control at every step of the purchase/ rental journey.</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="saving-card text-center">
                        <div className=""><img src="images/easy-direct-co-ordination.svg" className="img-fluid m-auto" alt="Co-ordination" /></div>
                        <h4>Easy tools for direct <span className="text-nowrap">Co-ordination</span></h4>
                        <hr></hr>
                        <p>Use the Message Board and Viewing Planner to directly connect with the 
owner.</p>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section id="how-it-works">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="section-title"><h2>How it Works</h2>
              <h5>Broker-Free Transactions, <span className="text-nowrap">Zero-Commission</span> Deals</h5>
              </div>
              <div className="row">
                <div className="col-xl-4 offset-xl-1 col-sm-6 infographic-col">
                  <div className="d-sm-flex h-100">
                    <div className="infographic">
                      <div className="infographic-wrap text-center">
                        <div className="infographic-card">
                          <img src="images/property-list.svg" alt="" />
                          <h5>List the Property</h5>
                          <p className="mb-0">Upload details and images of your property in simple easy steps</p>
                        </div>
                      </div>
                    </div>
                    <div className="arrow-right position-relative">
                      <picture>
                        <source srcSet="images/polygon-mobile.svg" media="(max-width: 575px)" />
                        <source srcSet="images/polygon.svg" />
                        <img src="images/polygon.svg" alt="" />
                      </picture>
                      <div className="info-num">01</div></div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 infographic-col">
                  <div className="d-sm-flex h-100">
                    <div className="infographic">
                      <div className="infographic-wrap text-center">
                        <div className="infographic-card">
                          <img src="images/connect-directly.svg" alt="" />
                          <h5>Connect Directly</h5>
                          <p className="mb-0">Interact directly with interested buyers using the Message Board chat</p>
                        </div>
                      </div>
                    </div>
                    <div className="arrow-right position-relative">
                      <picture>
                        <source srcSet="images/polygon-mobile.svg" media="(max-width: 575px)" />
                        <source srcSet="images/polygon.svg" />
                        <img src="images/polygon.svg" alt="" />
                      </picture>
                      <div className="info-num">02</div></div>
                  </div>
                </div>
                <div className="col-xl-4 offset-xl-1 col-sm-6 infographic-col">
                  <div className="d-sm-flex h-100">
                    <div className="infographic">
                      <div className="infographic-wrap text-center">
                        <div className="infographic-card">
                          <img src="images/home-visit.svg" alt="" />
                          <h5>Home Viewing</h5>
                          <p className="mb-0">Schedule a property viewing using the convenient Meeting Planner tool</p>
                        </div>
                      </div>
                    </div>
                    <div className="arrow-right position-relative">
                      <picture>
                        <source srcSet="images/polygon-mobile.svg" media="(max-width: 575px)" />
                        <source srcSet="images/polygon.svg" />
                        <img src="images/polygon.svg" alt="" />
                      </picture> <div className="info-num">03</div></div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 infographic-col">
                  <div className="d-sm-flex h-100">
                    <div className="infographic">
                      <div className="infographic-wrap text-center">
                        <div className="infographic-card">
                          <img src="images/seal-the-deal.svg" alt="" />
                          <h5>Seal the Deal</h5>
                          <p className="mb-0">Found a buyer? Close the deal DIY using our user-friendly step-by-step guides, checklists and templates</p>
                        </div>
                      </div>
                    </div>
                    <div className="arrow-right position-relative">
                      <picture>
                        <source srcSet="images/polygon-mobile.svg" media="(max-width: 575px)" />
                        <source srcSet="images/polygon.svg" />
                        <img src="images/polygon.svg" alt="" />
                      </picture> <div className="info-num">04</div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SavingCalculator />
      <section id="help-desk">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-title text-center">
                <h2>Help Desk</h2>
              </div>
              <div className="row">
                <div className="col-sm-4 image-card">
                  <a href="/coming-soon"></a>
                  <div className="icon-txt text-center">
                  <div className="card-thumb"><img src="images/useful-forms-icon.png" className="img-fluid" alt="Useful Forms" /></div>
                    <h5>Useful Forms</h5>
                  </div>
                </div>
                <div className="col-sm-4 image-card">
                  <a href="/useful-links"></a>
                  <div className="icon-txt text-center">
                  <div className="card-thumb"><img src="images/useful-links-icons.png" className="img-fluid" alt="Useful Links" /></div>
                    <h5>Useful Links</h5>
                  </div>
                </div>
                <div className="col-sm-4 image-card">
                  <a href="/faqs"></a>
                  <div className="icon-txt text-center">
                  <div className="card-thumb"><img src="images/faqs-icon.png" className="img-fluid" alt="FAQs" /></div>
                    <h5>FAQs</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlogPage />

      <Footer />
    </>

  );
}

export default SellerPage;