import React from "react";
import { Link,useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

const AuthHeader = () => {
  const navigate = useNavigate();
  const logout = () => {
    sessionStorage.clear();    
    navigate("/login");
  }
  return (
    <>
      <header>
      {['lg'].map((expand) => (
        <Navbar key={expand} bg="default navbar-dark"  expand={expand}>
          <Container fluid>          
            <Link className="navbar-brand" to="/"><img src="/images/logo.png" alt="Broker Free Homes" /></Link>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end" className="primaryB-bg">
              <Offcanvas.Header closeButton className="btn-close-white ms-auto">
                
              </Offcanvas.Header>
              <Offcanvas.Body className="primaryLinks">
              {sessionStorage.getItem('token') !==null ? <>
              <Nav className="header-right order-lg-3 order-3 d-lg-flex" as="ul">              
                <Nav as="li" className="nav-item"><Nav.Link href="#action1"><span>عربى</span></Nav.Link></Nav> 
                <Nav as="li" className="nav-item reg-user"> 
                    <NavDropdown title={sessionStorage.getItem('username') } id={`offcanvasNavbarDropdown-expand-${expand}`}>
                      <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                      <NavDropdown.Item href="/listing">Dashboard</NavDropdown.Item>
                      <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                    </NavDropdown>
                    </Nav> 
                 </Nav>
                  </> : <>
                  <Nav  className="nav-item order-3"><Link to="/login" className="btn register-btn fw-bold"
                      role="button">LOGIN</Link></Nav>       
                  </>}   
                  <Nav className="navbar-nav m-auto mb-0 mb-lg-0 order-1">
                    <Link className="nav-link" to="/"><span>Buyer</span></Link>
                    <Link className="nav-link" to="/seller"><span>Seller</span></Link>
                    <Link className="nav-link" to="/tenant"><span>Tenant</span></Link>
                    <Link className="nav-link" to="/landlord"><span>Landlord</span></Link>
                    <Link className="nav-link d-lg-none" to="/coming-soon"><span>About us</span></Link>
                    <Link className="nav-link d-lg-none" to="/subscription"><span>Pricing</span></Link>
                    <Link className="nav-link d-lg-none" to="/coming-soon"><span>Blog</span></Link>
                    <Link className="nav-link d-lg-none" to="/coming-soon"><span>Contact</span></Link>
                    {/*
                    <Nav as="li" className="nav-item"><Nav.Link href="/"><span>Buyer</span></Nav.Link></Nav>
                    <Nav as="li" className="nav-item"><Nav.Link href="/seller"><span>Seller</span></Nav.Link></Nav>
                    <Nav as="li" className="nav-item"><Nav.Link href="/tenant"><span>Tenant</span></Nav.Link></Nav>
                    <Nav as="li" className="nav-item"><Nav.Link href="/landlord"><span>Landlord</span></Nav.Link></Nav> 
                 */}
                </Nav>     
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      </header>
    </>
  );
}

export default AuthHeader;
