import React from "react";
import { Link,useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import AuthHeader from "../AuthHeader";

const DashboardHeader = () => {
  const navigate = useNavigate();
  const userRole = sessionStorage.getItem("userrole");
  const logout = () => {
    sessionStorage.clear(); 
    navigate("/login");
  }
  return (
    <>
      <header>
      <AuthHeader />
      </header>
      <div id="dashboard-sidebar" className="col-lg-2">
                <div className="sidebar-wrap">
                 
        {['lg'].map((expand) => (
        <Navbar key={expand}   expand={expand}>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start" className="">
              <Offcanvas.Header closeButton className="btn-close-dark ms-auto">            
              </Offcanvas.Header>
              <Offcanvas.Body className="primaryLinks"> 
                  <Nav className="navbar-nav dashboard-sidebar w-100" as="ul">                  
                  <Nav as="li" className="nav-item"><Link className="nav-link" to="/dashboard" as="li"><i className="bi bi-bar-chart-line"></i> Dashboard</Link></Nav>
                {/*}  <Nav as="li" className="nav-item"><Link className="nav-link" to="/" as="li"><i className="bi bi-chat-right-text"></i> Messages</Link></Nav>*/}
                  <Nav as="li" className="nav-item nav-head mt-5"><h6 className="primaryB fw-bold">Listing</h6></Nav>
                  <Nav as="li" className="nav-item"><Link className="nav-link" to="/mylisting" as="li"><i className="bi bi-card-list"></i> My Listing(s)</Link></Nav>
                  <Nav as="li" className="nav-item"><Link className="nav-link" to="/myfavorite" as="li"><i className="bi bi-card-list"></i> My Favorite(s)</Link></Nav>
                  {/*<Nav as="li" className="nav-item"><Link className="nav-link" to="/" as="li"><i className="bi bi-calendar2-check"></i> View Scheduler</Link></Nav>*/}
                  {
                    userRole == 2 || userRole == 4
                          ? 
                          <>
                          <Nav as="li" className="nav-item"><Link className="nav-link" to="/listing" as="li"><i className="bi bi-file-earmark-plus"></i> Add new</Link></Nav>
                          </>
                          : null
                  }                 
                
                </Nav>     
              </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Navbar>
      ))}
                </div>
            </div>
    </>
  );
}

export default DashboardHeader;
