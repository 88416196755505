const appUrl = 'https://funnel.techpapaya.in';
//const appUrl = 'http://localhost/bfh/backend';
export const ApiConfig = {
  // =========EndPoints==========
 
  login: 'auth/login',  
  register: "api/register",
  verifyotp:'api/verifyotp',
  sendOtp:'api/sendotp',
  getmyprofile:'api/userprofile',
  createList:'api/createlisting',
  mylisting:'api/mylisting',
  getmylistingbyid:'api/mylistinginfo',
  removePropertyImage:'api/removepropertyimage',
  removePropertyDocs:'api/removepropertydocs',
  submitListing:'api/submitlisting',
  singlelisting:'api/singlelisting',
  getlistingdetailbyid:'api/detaillisting',
  AddFavorite:'api/addfavorite',
  getMyFavorite:'api/myfavorite',
  removeMyFavorite:'api/removefavorite',
  defaultmodelfunction:'api/defaultmodelfunction',
  getSearchResult:'api/search',
  getAllLocality:'api/locality',
  GetMembers :'api/messanger',
  GetMessages :'api/messanger',
 SendMessage :'api/messanger',
 ownerschedule:'api/ownerschedule',
 addTime:'api/ownerschedule',
 removeTime:'api/ownerschedule',
 userschedule:'api/userschedule',
 reserverTime:'api/userschedule',
  // ============URLs================
  baseUrl: `${appUrl}/`, 
};