import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import { Link,useParams } from "react-router-dom";
import AuthHeader from "../../../customComponent/AuthHeader";
import Footer from "../../../customComponent/Footer";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { formatValue } from "react-currency-input-field";
import Swal from 'sweetalert2'

const DetailPropertyPage = () => {
    const userId = sessionStorage.getItem("iduser");
    const userRole = sessionStorage.getItem("userrole");
    const [propertydetail, setPropertyDetail] = useState([]);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [propertytype, setPropertyType] = useState([]);
    const [bedroomTypes, setBedroomTypes] = useState([]);
    const [bedroomtype, setBedroomType] = useState([]);
    const [parkingTypes, setParkingTypes] = useState([]);
    const [parkingtype, setParkingType] = useState([]);
    const [furnishTypes, setFurnishTypes] = useState([]);
    const [furnishtype, setFurnishType] = useState([]);
    const [propertystatusTypes, setPropertyStatusTypes] = useState([]);
    const [propertystatustype, setPropertyStatusType] = useState([]);
    const [propertyRentStatusTypes, setPropertyRentStatusTypes] = useState([]);
    const [propertyrentstatustype, setPropertyRentStatusType] = useState([]);
    const [propertyMortgageStatusTypes, setPropertyMortgageStatusTypes] = useState([]);
    const [propertymortgagestatustype, setPropertyMortgageStatusType] = useState([]);
    const [propertyamenities, setPropertyAmenities] = useState([]);
    const [propertymedia, setPropertyMedia] = useState([]);
    const [isfavorite, setIsFavorite] = useState([]);

    const params = useParams();
    const propertyid= params.id;

    useEffect(() => {
        handleListingDetail();
        handleGetPropertyType(propertytype);
        handleGetBedroomType(bedroomtype);
        handleGetParkingType(parkingtype);
        handleGetFurnishType(furnishtype);
        handlePropertyStatusType(propertystatustype);
        handlePropertyRentStatusType(propertyrentstatustype);
        handlePropertyMortgageStatusType(propertymortgagestatustype);
    }, []);

    const handleListingDetail = async () => {
        await AuthService.getListingDetailByID(propertyid,userId).then(async result => {
            if (result.apirspo) {
                setPropertyDetail(result.propertydetail);
                setPropertyTypes(result.pttypes);
                setBedroomTypes(result.bedtypes);
                setParkingTypes(result.parktypes);
                setFurnishTypes(result.furnishtypes);
                setPropertyStatusTypes(result.propertystatus);
                setPropertyRentStatusTypes(result.propertyrentstatus);
                setPropertyMortgageStatusTypes(result.propertymortgagestatus);
                setPropertyAmenities(result.propertyamenities);
                setPropertyMedia(result.propertymedia);
                if(result.favorite == null){
                    setIsFavorite('favorite-button notis-favorite');
                }else{
                    setIsFavorite('favorite-button is-favorite');
                }
            } else {

            }
        });
    }

    const handleGetPropertyType = (pTypeId) => {
        return propertyTypes[pTypeId];
    }
    const handleGetBedroomType = (bedTypeId) => {
        return bedroomTypes[bedTypeId];
    }
    const handleGetParkingType = (parkTypeId) => {
        return parkingTypes[parkTypeId];
    }
    const handleGetFurnishType = (furnishTypeId) => {
        return furnishTypes[furnishTypeId];
    }
    const handlePropertyStatusType = (pstatusId) => {
        return propertystatusTypes[pstatusId];
    }
    const handlePropertyRentStatusType = (prentstatusId) => {
        return propertyRentStatusTypes[prentstatusId];
    }
    const handlePropertyMortgageStatusType = (pmortgagestatusId) => {
        return propertyMortgageStatusTypes[pmortgagestatusId];
    }

    const handleAddFavorite = async (property_id) => {
        await AuthService.AddFavorite(userId,property_id).then(async result => {
            if (result.apirspo) { 
                if (result.added) {            
                    setIsFavorite('favorite-button is-favorite');
                 }else if(result.removed){
                    setIsFavorite('favorite-button notis-favorite');
                    }else{
                        setIsFavorite('favorite-button');
                }
               // console.log(result.favoritelist);
            } else {

            }
        });
    }
    const handleOwnerRestricted = () => {
        Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Property owner cannot contact for their own property.',
            showConfirmButton: false,
            timer: 5000
          })
      };
    return (
        <>
            <AuthHeader />
            <div className="inner-banner"></div>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 mb-lg-5 order-lg-2">

                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators d-none">
                                    <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>

                                <div className="carousel-inner">
                                    {propertymedia.map(function (data, idx) {
                                        return <>
                                            <div className={"carousel-item" + (idx === 0 ? ' active' : '')} key={idx}>
                                                <img src={global.Image + propertydetail.property_id + '/' + data.property_media} className="d-block w-100" alt="..." />
                                            </div>
                                        </>
                                    })}

                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-5 list-single-wrapper order-lg-1">
                            <div className="list-single-header">
                                <div className="property-details-desktop d-none d-lg-block">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            {handleGetPropertyType(propertydetail.property_type)}
                                            <h1 className="secondary-font fw-bold secondaryG mt-2 mb-1">{propertydetail.title}</h1>
                                            <div className=""><address className="list-single-location mb-4 d-flex align-items-center"><i class="bi bi-geo-alt primaryB me-1"></i> {propertydetail.locality},{propertydetail.city}</address></div>
                                        </div>
                                        <div className="list-single-stats text-center"> 
                                            <div className="favourite mb-3 d-none">
                                            <button type="button" class={isfavorite} onClick={() => handleAddFavorite(propertydetail.property_id)} data-bs-toggle="tooltip" data-bs-placement="left" title="Tooltip on left">
                                                <i class="favorite__icon favorite--enable"></i>
                                                <i class="favorite__icon favorite--not"></i>
                                                </button>                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-single-price fw-bold h5 primaryB mb-4">
                                            {
                                                formatValue({
                                                    value: ''+propertydetail.property_price+'',
                                                    intlConfig: { locale: 'en-IN', currency: 'AED' },
                                                    })
                                            }
                                         {}
                                    </div>
                                </div>
                                <div className="list-single-specs my-5">
                                    <ul className="list-unstyled d-flex spacs flex-wrap mb-0">
                                        <li><div className="d-flex">
                                            <div className="spac-icon"><img src="/images/bed.png" /></div>
                                            <div className="spac-txt text-dark"><span className="spac-txt-head">Bedrooms</span>
                                                <span className="spac-txt-data fw-bold">{handleGetBedroomType(propertydetail.bedrooms)} Beds</span>
                                            </div>
                                        </div></li>
                                        <li><div className="d-flex">
                                            <div className="spac-icon"><img src="/images/bath.png" /></div>
                                            <div className="spac-txt text-dark"><span className="spac-txt-head">Bathrooms</span>
                                                <span className="spac-txt-data fw-bold">{propertydetail.bathrooms} Baths</span>
                                            </div>
                                        </div></li>
                                        <li><div className="d-flex align-items-end">
                                            <div className="spac-icon"><img src="/images/area.png" /></div>
                                            <div className="spac-txt text-dark"><span className="spac-txt-head">Area (sq ft)</span>
                                                <span className="spac-txt-data fw-bold">{propertydetail.area}</span>
                                            </div>
                                        </div></li>
                                        <li><div className="d-flex align-items-end">
                                            <div className="spac-icon"><img src="/images/parking.png" /></div>
                                            <div className="spac-txt text-dark"><span className="spac-txt-head">Covered Parking</span>
                                                <span className="spac-txt-data fw-bold">{handleGetParkingType(propertydetail.coveredparking)}</span>
                                            </div>
                                        </div></li>
                                        <li><div className="d-flex align-items-end">
                                            <div className="spac-icon"><img src="/images/furnishing.png" /></div>
                                            <div className="spac-txt text-dark"><span className="spac-txt-head">Furnishing</span>
                                                <span className="spac-txt-data fw-bold">{handleGetFurnishType(propertydetail.furnishing)}</span>
                                            </div>
                                        </div></li>
                                        <li><div className="d-flex align-items-end">
                                            <div className="spac-icon"><img src="/images/property-status.png" /></div>
                                            <div className="spac-txt text-dark"><span className="spac-txt-head">Status</span>
                                                <span className="spac-txt-data fw-bold">{handlePropertyStatusType(propertydetail.property_status)}</span>
                                            </div>
                                        </div></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div id="property-info" className="row">
                        <div className="col-md-8">
                            <nav className="navi" >
                                <ul className="list-unstyled list-single-nav d-flex menu mb-0">
                                    <li><a href="#overview"><span>Details</span></a></li>
                                    <li><a href="#location-map"><span>View on Map</span></a></li>
                                    <li><a href="#aminities"><span>Amenities</span></a></li>
                                </ul>
                            </nav>
                            <div id="overview" className="overview-content">
                                <p>{propertydetail.description}</p>
                                <div className="collapse" id="overview">
                                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.</p>
                                </div>
                                <div id="details">
                                    <h5 className="primaryB fw-semi">Details</h5>
                                    <ul className="list-unstyled aminities-list mb-0">
                                        <li>Built-up Area (Sq.Ft): <span className="text-dark fw-semi">{propertydetail.area}</span></li>
                                        <li>Yard Size: <span className="text-dark fw-semi">{propertydetail.yardsize}</span></li>
                                        <li>Bathrooms: <span className="text-dark fw-semi">{propertydetail.bathrooms}</span></li>
                                        <li>Bedrooms: <span className="text-dark fw-semi">{handleGetBedroomType(propertydetail.bedrooms)}</span></li>
                                        <li>Status: <span className="text-dark fw-semi">{handlePropertyStatusType(propertydetail.property_status)}</span></li>
                                        <li>Rented Out: <span className="text-dark fw-semi">{handlePropertyRentStatusType(propertydetail.rentedout)}</span></li>
                                        <li>Furnishing: <span className="text-dark fw-semi">{handleGetFurnishType(propertydetail.furnishing)}</span></li>
                                        <li>Mortgage Status: <span className="text-dark fw-semi">{handlePropertyMortgageStatusType(propertydetail.mortgage_status)}</span></li>
                                        <li>Covered Parking: <span className="text-dark fw-semi">{handleGetParkingType(propertydetail.coveredparking)}</span></li>
                                    </ul>
                                </div>
                            </div>

                            <div id="location-map" className="map">
                                <h5 className="primaryB fw-semi">View on Map</h5>
                                <iframe src={"https://maps.google.com/maps?q=" + propertydetail.latitude + "," + propertydetail.longitude + "&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                                    height="300"
                                    width="100%"
                                    style={{ border: "0" }}
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div id="aminities">
                                <h5 className="primaryB fw-semi">Amenities</h5>
                                <ul className="list-unstyled aminities-list">
                                    {propertyamenities.map(function (data, idx) {
                                        return (
                                            <>
                                                <li key={idx}>{data.amenities_name}</li>
                                            </>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 offset-lg-1 col-md-4">
                            <div className="p-3 save-bfh">
                                <p>Here's what you save when you buy this property through BrokerFreeHomes</p>
                                <div className="text-center"><div className="saved-amount">&nbsp;  
                                    {
                                        propertydetail.purpose === 1 || propertydetail.purpose === 2
                                        ?
                                        formatValue({
                                            value: ''+Math.round(propertydetail.property_price/100*2)+'',
                                            intlConfig: { locale: 'en-IN', currency: 'AED' },
                                            })                                                                                 
                                        :
                                        formatValue({
                                            value: ''+Math.round(propertydetail.property_price/100*5)+'',
                                            intlConfig: { locale: 'en-IN', currency: 'AED' },
                                            })                                                                            
                                    }                                   
                                </div></div>
                            </div>
                            <div className="like-action like-property p-3 mt-4 ">
                                <h5 className="mb-3">Liked this property?</h5>
                                <div className="d-flex action">
                                    {
                                        propertydetail.iduser == userId
                                        ?
                                        <>
                                         <div className="action-box">  
                                            <Link to="" onClick={handleOwnerRestricted}><img src="/images/schedule-visit.png" className="mb-2" /><span className="d-block">Schedule Visit</span></Link>                                      
                                            </div>
                                            <div className="action-box">                                
                                            <Link className="nav-link" to="" onClick={handleOwnerRestricted}><img src="/images/contact-owner.png" className="mb-2" /><span className="d-block">Contact Owner</span></Link>     
                                        </div>

                                        </>
                                        :
                                        <>
                                        <div className="action-box">  
                                            <Link to={"/userschedule"} state={{propertyid}}><img src="/images/schedule-visit.png" className="mb-2" /><span className="d-block">Schedule Visit</span></Link>                                      
                                            </div>
                                            <div className="action-box">                                
                                            <Link className="nav-link" to={"/message"} state={{propertyid}}><img src="/images/contact-owner.png" className="mb-2" /><span className="d-block">Contact Owner</span></Link>     
                                        </div>
                                        </>
                                    }                                   
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section id="plisting-home">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="section-title"><h2 className="mb-0 d-none">Related Properties</h2>
                            </div>
                            <div className="owl-carousel property-carousel">
                                <div className="item plist-col">
                                    <div className="plist-wrap">
                                        <div className="pthumb">
                                            <div className="pthumb-holder position-relative"><img src="images/demo-property.jpg" className="img-fluid" alt="" />
                                                <div className="tags position-absolute"><span className="badge">Apartment</span></div>
                                                <div className="position-absolute img-action">
                                                    <div className="favourite mb-3"><a href="#"><svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m7.234 3.004c-2.652 0-5.234 1.829-5.234 5.177 0 3.725 4.345 7.727 9.303 12.54.194.189.446.283.697.283s.503-.094.697-.283c4.977-4.831 9.303-8.814 9.303-12.54 0-3.353-2.58-5.168-5.229-5.168-1.836 0-3.646.866-4.771 2.554-1.13-1.696-2.935-2.563-4.766-2.563zm0 1.5c1.99.001 3.202 1.353 4.155 2.7.14.198.368.316.611.317.243 0 .471-.117.612-.314.955-1.339 2.19-2.694 4.159-2.694 1.796 0 3.729 1.148 3.729 3.668 0 2.671-2.881 5.673-8.5 11.127-5.454-5.285-8.5-8.389-8.5-11.127 0-1.125.389-2.069 1.124-2.727.673-.604 1.625-.95 2.61-.95z" fillRule="nonzero" /></svg></a></div>
                                                    <div className="view"><svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z" fillRule="nonzero" /></svg> 105</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pinfo">
                                            <div className="pname"><h4 className="mb-0">Luxury Villa with Garden</h4>
                                                <address className="paddress"><svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" /></svg> Dubai Marina, Dubai</address></div>
                                            <div className="pprice">AED 1000</div>
                                            <p className="pdes">A cras semper auctor neque vitae tempus quam pellentesque. Fermentum leo vel orci porta non pulvinar neque laoreet. Lectus proin nibh nisl condimentum id. Morbi blandit cursus risus at. </p>
                                            <div className="pspecs d-flex fw-semi">
                                                <div><div><img src="images/bedroom-2.svg" className="img-fluid" width="16px" height="16px" /> </div><div>3</div></div>
                                                <div className="ms-3"><div><img src="images/bathroom-2.svg" className="img-fluid" width="16px" height="16px" /></div> <div>2</div></div>
                                                <div className="ms-3"><div><img src="images/set-square.svg" className="img-fluid" width="16px" height="16px" /></div> <div>764 sqft</div></div>
                                            </div>

                                        </div>
                                        <div className="paction">
                                            <a href="#" className="btn btn-sm btn-primary">Schedule Viewing</a> <a href="#" className="btn btn-sm btn-primary">Contact Owner</a>
                                        </div>

                                    </div>
                                </div>

                                <div className="item plist-col">
                                    <div className="plist-wrap">
                                        <div className="pthumb">
                                            <div className="pthumb-holder position-relative"><img src="images/demo-property.jpg" className="img-fluid" alt="" />
                                                <div className="tags position-absolute"><span className="badge">Villa</span></div>
                                                <div className="position-absolute img-action">
                                                    <div className="favourite mb-3"><a href="#"><svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m7.234 3.004c-2.652 0-5.234 1.829-5.234 5.177 0 3.725 4.345 7.727 9.303 12.54.194.189.446.283.697.283s.503-.094.697-.283c4.977-4.831 9.303-8.814 9.303-12.54 0-3.353-2.58-5.168-5.229-5.168-1.836 0-3.646.866-4.771 2.554-1.13-1.696-2.935-2.563-4.766-2.563zm0 1.5c1.99.001 3.202 1.353 4.155 2.7.14.198.368.316.611.317.243 0 .471-.117.612-.314.955-1.339 2.19-2.694 4.159-2.694 1.796 0 3.729 1.148 3.729 3.668 0 2.671-2.881 5.673-8.5 11.127-5.454-5.285-8.5-8.389-8.5-11.127 0-1.125.389-2.069 1.124-2.727.673-.604 1.625-.95 2.61-.95z" fillRule="nonzero" /></svg></a></div>

                                                    <div className="view"><svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z" fillRule="nonzero" /></svg> 105</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pinfo">
                                            <div className="pname"><h4 className="mb-0">Luxury Villa with Garden</h4>
                                                <address className="paddress"><svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" /></svg> Dubai Marina, Dubai</address></div>
                                            <div className="pprice">AED 1000</div>
                                            <p className="pdes">Eget mauris pharetra et ultrices neque ornare. Tristique senectus et netus et. Sit amet risus nullam eget. Erat nam at lectus urna duis convallis convallis tellus id.</p>
                                            <div className="pspecs d-flex fw-semi">
                                                <div><div><img src="images/bedroom-2.svg" className="img-fluid" width="16px" height="16px" /> </div><div>3</div></div>
                                                <div className="ms-3"><div><img src="images/bathroom-2.svg" className="img-fluid" width="16px" height="16px" /></div> <div>2</div></div>
                                                <div className="ms-3"><div><img src="images/set-square.svg" className="img-fluid" width="16px" height="16px" /></div> <div>764 sqft</div></div>
                                            </div>

                                        </div>
                                        <div className="paction">
                                            <a href="#" className="btn btn-sm btn-primary">Schedule Viewing</a> <a href="#" className="btn btn-sm btn-primary">Contact Owner</a>
                                        </div>

                                    </div>
                                </div>
                                <div className="item plist-col">
                                    <div className="plist-wrap">
                                        <div className="pthumb">
                                            <div className="pthumb-holder position-relative"><img src="images/demo-property.jpg" className="img-fluid" alt="" />
                                                <div className="tags position-absolute"><span className="badge">Pent-house</span></div>
                                                <div className="position-absolute img-action">
                                                    <div className="favourite mb-3"><a href="#"><svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m7.234 3.004c-2.652 0-5.234 1.829-5.234 5.177 0 3.725 4.345 7.727 9.303 12.54.194.189.446.283.697.283s.503-.094.697-.283c4.977-4.831 9.303-8.814 9.303-12.54 0-3.353-2.58-5.168-5.229-5.168-1.836 0-3.646.866-4.771 2.554-1.13-1.696-2.935-2.563-4.766-2.563zm0 1.5c1.99.001 3.202 1.353 4.155 2.7.14.198.368.316.611.317.243 0 .471-.117.612-.314.955-1.339 2.19-2.694 4.159-2.694 1.796 0 3.729 1.148 3.729 3.668 0 2.671-2.881 5.673-8.5 11.127-5.454-5.285-8.5-8.389-8.5-11.127 0-1.125.389-2.069 1.124-2.727.673-.604 1.625-.95 2.61-.95z" fillRule="nonzero" /></svg></a></div>

                                                    <div className="view"><svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z" fillRule="nonzero" /></svg> 105</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pinfo">
                                            <div className="pname"><h4 className="mb-0">Luxury Villa with Garden & Pent-House</h4>
                                                <address className="paddress"><svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" /></svg> Dubai Marina, Dubai</address></div>
                                            <div className="pprice">AED 1000</div>
                                            <p className="pdes">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Molestie at elementum eu facilisis sed. </p>
                                            <div className="pspecs d-flex fw-semi">
                                                <div><div><img src="images/bedroom-2.svg" className="img-fluid" width="16px" height="16px" /> </div><div>3</div></div>
                                                <div className="ms-3"><div><img src="images/bathroom-2.svg" className="img-fluid" width="16px" height="16px" /></div> <div>2</div></div>
                                                <div className="ms-3"><div><img src="images/set-square.svg" className="img-fluid" width="16px" height="16px" /></div> <div>764 sqft</div></div>
                                            </div>

                                        </div>
                                        <div className="paction">
                                            <a href="#" className="btn btn-sm btn-primary">Schedule Viewing</a> <a href="#" className="btn btn-sm btn-primary">Contact Owner</a>
                                        </div>

                                    </div>
                                </div>
                                <div className="item plist-col">
                                    <div className="plist-wrap">
                                        <div className="pthumb">
                                            <div className="pthumb-holder position-relative"><img src="images/demo-property.jpg" className="img-fluid" alt="" />
                                                <div className="tags position-absolute"><span className="badge">Pent-house</span></div>
                                                <div className="position-absolute img-action">
                                                    <div className="favourite mb-3"><a href="#"><svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m7.234 3.004c-2.652 0-5.234 1.829-5.234 5.177 0 3.725 4.345 7.727 9.303 12.54.194.189.446.283.697.283s.503-.094.697-.283c4.977-4.831 9.303-8.814 9.303-12.54 0-3.353-2.58-5.168-5.229-5.168-1.836 0-3.646.866-4.771 2.554-1.13-1.696-2.935-2.563-4.766-2.563zm0 1.5c1.99.001 3.202 1.353 4.155 2.7.14.198.368.316.611.317.243 0 .471-.117.612-.314.955-1.339 2.19-2.694 4.159-2.694 1.796 0 3.729 1.148 3.729 3.668 0 2.671-2.881 5.673-8.5 11.127-5.454-5.285-8.5-8.389-8.5-11.127 0-1.125.389-2.069 1.124-2.727.673-.604 1.625-.95 2.61-.95z" fillRule="nonzero" /></svg></a></div>

                                                    <div className="view"><svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z" fillRule="nonzero" /></svg> 105</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pinfo">
                                            <div className="pname"><h4 className="mb-0">Luxury Villa with Garden & Pent-House</h4>
                                                <address className="paddress"><svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" /></svg> Dubai Marina, Dubai</address></div>
                                            <div className="pprice">AED 1000</div>
                                            <p className="pdes">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Molestie at elementum eu facilisis sed. </p>
                                            <div className="pspecs d-flex fw-semi">
                                                <div><div><img src="images/bedroom-2.svg" className="img-fluid" width="16px" height="16px" /> </div><div>3</div></div>
                                                <div className="ms-3"><div><img src="images/bathroom-2.svg" className="img-fluid" width="16px" height="16px" /></div> <div>2</div></div>
                                                <div className="ms-3"><div><img src="images/set-square.svg" className="img-fluid" width="16px" height="16px" /></div> <div>764 sqft</div></div>
                                            </div>

                                        </div>
                                        <div className="paction">
                                            <a href="#" className="btn btn-sm btn-primary">Schedule Viewing</a> <a href="#" className="btn btn-sm btn-primary">Contact Owner</a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-5"><a href="#" className="btn btn-primary d-none">See All Properties</a></div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default DetailPropertyPage;