import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import AuthHeader from "../../../customComponent/AuthHeader";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import Footer from "../../../customComponent/Footer";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const SignupPage = () => {
  const [validationerror, setValidationerror] = useState({});
  const navigate = useNavigate();
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [city, setCity] = useState("");
  const [nationality, setNationality] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [fullName, setFullName] = useState("");
  const [profile, setProfile] = useState("");
  const [isAuth, setIsAuth] = useState('1');
  const [signupmobileerror, setSignupMobileError] = useState();
  const [signupnameerror, setSignupNameError] = useState();
  const [signupemailerror, setSignupEmailError] = useState();

  const handleRegister = async (fullName,mobileNumber,emailId,password, city, nationality, profile) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.register(fullName,mobileNumber,emailId,password, city, nationality, profile).then(async result => {
      if (result.apirspo) {
        LoaderHelper.loaderStatus(false);
        try {
          sessionStorage.setItem("username", result.userdata.username);
          sessionStorage.setItem("iduser", result.userdata.iduser);
          sessionStorage.setItem("userrole", result.userdata.userrole);
          sessionStorage.setItem("token", result.token);
          sessionStorage.setItem("planid", result.subscriptiondata[0].subscription_id);
          sessionStorage.setItem("planname", result.subscriptiondata[0].plan_name);
          sessionStorage.setItem("planamount", result.subscriptiondata[0].amount);
          sessionStorage.setItem("planstart", result.subscriptiondata[0].start_date);
          sessionStorage.setItem("planexpire", result.subscriptiondata[0].end_date);
         // alertSuccessMessage('Successful');token
         if (result.token) {
              navigate("/register-thanks");
            }else{
              navigate("/");
          }
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
          setValidationerror(
            result.message
        );
      }
    });
  }


  const getCodeSignup = async (mobileNumber, type,fullName,emailId) => {
      if(mobileNumber <10 || mobileNumber ==''){
        setSignupMobileError('Valid mobile number is required.')
      } else if(fullName ==''){
        setSignupNameError('Name is required.')
      }else if(emailId ==''){
        setSignupEmailError('Valid email is required.')
      }else{
      LoaderHelper.loaderStatus(true);
      await AuthService.getCodeSignup(mobileNumber, type).then(async result => {
        if (result.apirspo) {
          LoaderHelper.loaderStatus(false);
          try {
        //    alertSuccessMessage(result.message);
            setIsAuth('2');
          } catch (error) {
            alertErrorMessage(error);
            console.log('error', `${error}`);
          }
        } else {
          LoaderHelper.loaderStatus(false);
          setValidationerror(
            result.message
        );
        }
      });
    }
   
  }


  const handleRegisterOtp = async (emailOtp, type) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getRegisterOtp(emailOtp, mobileNumber, type).then(async result => {
      if (result.apirspo) {
        LoaderHelper.loaderStatus(false);
        try {
       //   alertSuccessMessage(result.message);
          setIsAuth('0');
        } catch (error) {
          alertErrorMessage(error);
          console.log('error', `${error}`);
        }
      } else {
        LoaderHelper.loaderStatus(false);
     //   alertErrorMessage(result.message);
          setValidationerror(
            result.message
        );
      }
    });
  }

  const mySubmitFunction = async (event) => {
    event.preventDefault()
    return false
  }

  return (
    <>
      <AuthHeader />
      <div className="inner-banner"></div>
      {isAuth === '1' ?
        <section style={{ paddingBottom: '50px' }} >
          <div className="container">
            <div className="row g-0">
              <div className="col-lg-4 offset-lg-2 col-md-5 offset-md-1 com login_bg" >
                <div className="">
                  <div className="signup-txt secondary-font mb-3">
                    <span className="fw-bold primaryB h4">Register</span> and get started on <span
                      className="fw-bold primaryB h4">saving money</span>. Get access to <span
                        className="fw-bold primaryB h4">broker-free property</span> deals and save on commisions!
                  </div>
      <div className="register-benefits"> 
      <strong className="mb-2 d-block">Register to get access to features like</strong>
      <ul className="bullet-list fw-semi p-0">
      <li>Unlimited browsing of property listings</li>
      <li>List your property</li>
      <li>Connect with owners via chat</li>
      <li>Schedule property viewing</li>
      <li>& a wide range of tools and resources</li>
      </ul>
      </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5">
                <div className="login-form">
                  <form>
                    <h4 className="secondary-font primaryB">Signup</h4>
                    <div className="mt-3">
                      <label><small>Full Name</small></label>
                      <div className="input-group input-group-lg">
                        <span className="input-group-text" id="basic-addon1"
                        // style={{background:var(--otherG)}}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg"
                            width="14" height="14" viewBox="0 0 24 24">
                            <path
                              d="M19 7.001c0 3.865-3.134 7-7 7s-7-3.135-7-7c0-3.867 3.134-7.001 7-7.001s7 3.134 7 7.001zm-1.598 7.18c-1.506 1.137-3.374 1.82-5.402 1.82-2.03 0-3.899-.685-5.407-1.822-4.072 1.793-6.593 7.376-6.593 9.821h24c0-2.423-2.6-8.006-6.598-9.819z" />
                          </svg></span>
                        <input type="text" name ="name" className="form-control" placeholder="" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                      </div>
                      {signupnameerror ? <span className="text-danger error">{signupnameerror}</span> : ''}
                    </div>
                    <div className="mt-3">
                      <label><small>Email</small></label>
                      <div className="input-group input-group-lg">
                        <span className="input-group-text" id="basic-addon1" >
                          <svg xmlns="http://www.w3.org/2000/svg"  width="14" height="14" viewBox="0 0 24 24">
                            <path d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z" />
                          </svg></span>
                        <input type="text" name="email" className="form-control" placeholder="" value={emailId} onChange={(e) => setEmailId(e.target.value)} /> 
                      </div> 
                      {signupemailerror ? <span className="text-danger error">{signupemailerror}</span> : ''}                    
                    </div>
                    <div className="mt-3"><label><small>Mobile</small></label>
                      <div className="input-group input-group-lg">
                        <span className="input-group-text" id="basic-addon1" ><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
                          <path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z" />
                        </svg></span>
                        <input type="text" name="mobile" className="form-control" placeholder="" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                      </div>
                      {validationerror ? <span className="text-danger error">{validationerror.mobile}</span> : ''}
                      {validationerror.customMessages ? <span className="text-danger">{validationerror.customMessages.alreadyreg}</span> : ''}
                      {signupmobileerror ? <span className="text-danger error">{signupmobileerror}</span> : ''}
                    
                    </div>
                    <div className="mt-4 text-center">
                      <button type="button" className="btn btn-primary btn-lg" onClick={() => getCodeSignup(mobileNumber, 'REGISTER',fullName,emailId)} disabled={!mobileNumber}>Send Verification Code</button>
                    </div>
                  </form>
                  <p className="text-center mt-4 mb-0"><small>Already have an account? <Link to="/login">Login</Link></small></p>
                </div>
              </div>
            </div>
          </div>
        </section>
        : ""
      }

      {isAuth === '0' ?
        <section className="mb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="login-form">
                  <form>
                    <h4 className="secondary-font primaryB">Continue Signup</h4>

                    <div className="mt-3"><label><small>Name</small></label>
                      <input type="text" className="form-control" placeholder="" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                    </div>
                    {validationerror ? <span className="text-danger">{validationerror.name}</span> : ''}
                    <div className="mt-3 d-none"><label><small>Mobile Number</small></label>
                      <input type="text" className="form-control" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                    </div>
                  
                    <div className="mt-3"><label><small> Email Id</small></label>
                      <input type="text" className="form-control" placeholder="" value={emailId} onChange={(e) => setEmailId(e.target.value)} />
                    </div>
                    {validationerror ? <span className="text-danger">{validationerror.email}</span> : ''}
                    <div className="mt-3 d-none"><label><small>Password</small></label>
                      <input type="text" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="mt-3"><label><small>City of Residence</small></label>
                    <select className="form-select" name="city" value={city} onChange={(e) => setCity(e.target.value)}>
                      <option value='0'>Select</option>
                        <option value='Abu Dhabi'>Abu Dhabi</option>
                        <option value='Dubai'>Dubai</option>
                        <option value='Sharjah'>Sharjah</option>
                        <option value='Ajman'>Ajman</option>
                        <option value='Umm Al Quwain'>Umm Al Quwain</option>
                        <option value='Ras Al Khaimah'>Ras Al Khaimah</option>
                        <option value='Fujairah'>Fujairah</option>
                      </select>
                    </div>
                    {validationerror ? <span className="text-danger">{validationerror.city}</span> : ''}
                    <div className="mt-3"><label><small>Nationality</small></label>
                    <select className="form-select" name="nationality" value={nationality} onChange={(e) => setNationality(e.target.value)}>
                      <option value='0'>Select</option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="The Bahamas">The Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                      <option value="Botswana">Botswana</option>
                      <option value="Brazil">Brazil</option>
                      <option value="Brunei">Brunei</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cabo Verde">Cabo Verde</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Central African Republic">Central African Republic</option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo, Democratic Republic of the">Congo, Democratic Republic of the</option>
                      <option value="Congo, Republic of the">Congo, Republic of the</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Côte d’Ivoire">Côte d’Ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">Dominican Republic</option>
                      <option value="East Timor (Timor-Leste)">East Timor (Timor-Leste)</option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">Equatorial Guinea</option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Eswatini">Eswatini</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="Gabon">Gabon</option>
                      <option value="The Gambia">The Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Greece">Greece</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-Bissau">Guinea-Bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran">Iran</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea, North">Korea, North</option>
                      <option value="Korea, South">Korea, South</option>
                      <option value="Kosovo">Kosovo</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Laos">Laos</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libya">Libya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                      <option value="Moldova">Moldova</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar (Burma)">Myanmar (Burma)</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="North Macedonia">North Macedonia</option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Romania">Romania</option>
                      <option value="Russia">Russia</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Sudan, South">Sudan, South</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syria">Syria</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Togo">Togo</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">United Arab Emirates</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Vatican City">Vatican City</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Vietnam">Vietnam</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                      </select>                     
                    </div>
                    {validationerror ? <span className="text-danger">{validationerror.nationality}</span> : ''}
                    <div className="mt-3"><label><small>Choose your profile</small></label>
                      <select className="form-select" name="userrole" value={profile} onChange={(e) => setProfile(e.target.value)}>
                      <option value='0'>Select</option>
                        <option value='1'>Buyer</option>
                        <option value='2'>Seller</option>
                        <option value='3'>Tenant</option>
                        <option value='4'>Landlord</option>
                      </select></div>
                      {validationerror ? <span className="text-danger">{validationerror.userrole}</span> : ''}
                    <div className="mt-3 text-center">
                      <button type="button" className="btn btn-primary" onClick={() => handleRegister(fullName,mobileNumber,emailId,password, city, nationality, profile)}> Signup</button>
                    </div>
                  </form>
                </div>
                <p className="text-center mb-0"><small>Already have an account? <Link to="/login">Login</Link></small>
                </p>
              </div>
            </div>
          </div>
        </section>
        : ''
      }




      {isAuth === '2' ?
        <section className="mb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="login-form"
                // style={{margin:0 50px}}
                >
                  <form>
                    <h4 className="secondary-font primaryB">Verification Code/OTP</h4>
                    <div className="mt-3"><label><small>Enter the OTP received on your phone via text message.</small></label>
                      <input type="text" className="form-control" value={emailOtp} onChange={(e) => setEmailOtp(e.target.value)} /></div>
                      {validationerror.customMessages ? <span className="text-danger">{validationerror.customMessages.verifyotp}</span> : ''}
                    <div className="mt-3 text-center">
                      <button type="button" className="btn btn-primary" onClick={() => (handleRegisterOtp(emailOtp, 'REGISTER'))}> Verify OTP </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        : ''
      }

      <Footer />
    </>
  );
}

export default SignupPage;