import { useState } from "react";
import CurrencyInput from 'react-currency-input-field';
import { formatValue } from "react-currency-input-field";
const Mortgagecalculator = () => {

    const [emivalue, setEmiValue] = useState();
    const [principalamount, setPrincipalAmount] = useState();
    const [propertyvalue, setPropertyValue] = useState();   
    const [propertyvalueerror, setPropertyValueError] = useState(false); 
    const MAXDOWNPAY = 40;
    const [downpayvalue, setDownPayValue] = useState(20);
    const getDownPayment = () => {
        return { backgroundSize: `${(downpayvalue * 100) / MAXDOWNPAY}% 100%` };
      };

    const MAXINTERESTRATE = 8;
    const [interestvalue, setInterestValue] = useState(1);
    const getInterestRate = () => {
        return { backgroundSize: `${(interestvalue * 100) / MAXINTERESTRATE}% 100%` };
      };
    
    const MAXTENURE = 25;
    const [tenurevalue, setTenureValue] = useState(3);
    const getTenuretRate = () => {
        return { backgroundSize: `${(tenurevalue * 100) / MAXTENURE}% 100%` };
      };
    
    const handleCalculate = (propertyvalue,downpayvalue,interestvalue,tenurevalue) => {
        if(propertyvalue == undefined){
            setPropertyValueError(true)
          }
        let  monthlyInterest =  (interestvalue/12/100);
        let  yearToMonth =  (tenurevalue*12);
        let downPayemntAmount = (propertyvalue/100*downpayvalue)
        let principalAmount = propertyvalue-downPayemntAmount  
      /*  
       let calc = Math.pow(1+monthlyInterest,yearToMonth)      
       let calc1 = principalAmount*(calc*monthlyInterest)
       let calc2 = Math.pow(1+monthlyInterest,yearToMonth)
       */
     //  let Emi = calc1/calc2       
       let Emi =  principalAmount*monthlyInterest*Math.pow(1+monthlyInterest,yearToMonth)/(Math.pow(1+monthlyInterest,yearToMonth)-1)

       // let  Emi = principalAmount*monthlyInterest*(1+monthlyInterest)*yearToMonth/((1+monthlyInterest)*yearToMonth-1)
        if(Emi >0 && principalAmount>0 ){
            setPropertyValueError(false)
           
            const formattedValuePrincipal = formatValue({
            value: ''+principalAmount+'',
            intlConfig: { locale: 'en-IN', currency: 'AED' },
            });  
            setEmiValue(Emi);
            setPrincipalAmount(formattedValuePrincipal)
        }        
    }  
    return (
        <>
            <div className="col-lg-6 p-0 mortgage-calulator">
                <div className="calulator-wrap">
                    <h3 className="primaryB mb-4">Calculate Your Mortgage</h3>
                      <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="formFile" className="form-label">Property Price</label>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><small>AED</small></span>
                                <CurrencyInput
                                        class="form-control"
                                        name="input-name"
                                        placeholder=""
                                        aria-describedby="basic-addon3"
                                        defaultValue={''}
                                        decimalsLimit={2}
                                        value={propertyvalue}
                                        onValueChange={(value) => setPropertyValue(value)}
                                    />
                            </div>
                            {propertyvalueerror== true ? <span className="text-danger error">Valid property value is required</span>:null}
                        </div>
                        <div className="col-sm-6 mt-sm-0 mt-4">
                            <label htmlFor="formFile" className="form-label">Down Payment</label>
                            <div className="range">
                                <div className="sliderValue">
                                    <span>100</span>
                                </div>
                                <div className="field">
                                    <div className="value left">
                                        20%</div>
                                        <input
                                                type="range"
                                                min="20"
                                                max={MAXDOWNPAY}
                                                step="1"
                                                onChange={(e) => setDownPayValue(e.target.value)}
                                                style={getDownPayment()}
                                                value={downpayvalue}
                                            />
                                    <div className="value right">
                                        40%</div>
                                        <div className="value center fw-bold primaryB">{downpayvalue}%</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-sm-5 mt-4">
                            <label htmlFor="formFile" className="form-label">Annual Interest Rate (Fixed)</label>
                            <div className="range">
                                <div className="sliderValue">
                                    <span>100</span>
                                </div>
                                <div className="field">
                                    <div className="value left">
                                        1.00%</div>
                                        <input
                                                type="range"
                                                min="1"
                                                max={MAXINTERESTRATE}
                                                step="0.1"
                                                onChange={(e) => setInterestValue(e.target.value)}
                                                style={getInterestRate()}
                                                value={interestvalue}
                                            />
                                    <div className="value right">
                                        8.00%
                                    </div>
                                    <div className="value center fw-bold primaryB">{interestvalue}%</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-sm-5 mt-4">
                            <label htmlFor="formFile" className="form-label">Loan Term (in years)</label>
                            <div className="range">
                                <div className="sliderValue">
                                    <span>100</span>
                                </div>
                                <div className="field">
                                    <div className="value left">
                                        3 Years</div>
                                        <input
                                                type="range"
                                                min="3"
                                                max={MAXTENURE}
                                                step="1"
                                                onChange={(e) => setTenureValue(e.target.value)}
                                                style={getTenuretRate()}
                                                value={tenurevalue}
                                            />
                                    <div className="value right">
                                        25 Years
                                    </div>
                                    <div className="value center fw-bold primaryB">{tenurevalue} Years</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-sm-5 mt-4"><input type="submit" className="btn btn-secondary btn-lg" value="Calculate" onClick={() => handleCalculate(propertyvalue,downpayvalue,interestvalue,tenurevalue)} /></div>
                        <div className="col-md-8  mt-sm-5 mt-4 primaryB">
                        {(principalamount == undefined) ? <></>
                        : <><div className="loan-amount">Loan Amount: <strong>{principalamount}</strong></div></>}  
                         {(emivalue == undefined) ? <></>
                                 : <><div className="loan-emi">EMI: <strong>                                   
                                     {
                                        formatValue({
                                            value: ''+Math.round(emivalue * 100)/100+'',
                                            intlConfig: { locale: 'en-IN', currency: 'AED' },
                                            })
                                    }                                    
                                    </strong></div></>}    
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Mortgagecalculator;