import React, { useState, useContext } from "react";
import AuthHeader from "../../../customComponent/AuthHeader";
import Footer from "../../../customComponent/Footer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

const FaqPage = () => {
      const [key, setKey] = useState('home');
  return (
    <>
      <AuthHeader />

     <div className="inner-banner">
      </div>
      <div className="container mb-5">
        <div className="row">
      <div className="col-md-8 offset-md-2 subscriptions-plan">
      <div class="section-title text-center"><h2>FAQs</h2></div>
        <Tabs
              id="controlled-tab-example"
              defaultActiveKey="buyer"
              onSelect={(k) => setKey(k)}
              className="mb-3">
                   <Tab eventKey="buyer" title="BUYER">                     
                       <div className="accordion faqs" id="buyer-faqs">
  <div className="accordion-item">
    <h2 className="accordion-header" id="buyerheadingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#buyercollapseOne" aria-expanded="true" aria-controls="buyercollapseOne">
        What charges are applicable on use of the website?
      </button>
    </h2>
    <div id="buyercollapseOne" className="accordion-collapse collapse show" aria-labelledby="buyerheadingOne" data-bs-parent="#buyer-faqs">
      <div className="accordion-body">
       <p>As a limited period welcome offer, the subscription fee of the Premium membership to BrokerFreehomes.com has been waived off for the first six months. </p>
        <p className="mb-0">Additionally, no fee, no charge is applicable by BrokerFreeHomes on closure of deal through the platform.</p> 
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="buyerheadingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#buyercollapseTwo" aria-expanded="false" aria-controls="buyercollapseTwo">
        Do I need to register to use the site?
      </button>
    </h2>
    <div id="buyercollapseTwo" className="accordion-collapse collapse" aria-labelledby="buyerheadingTwo" data-bs-parent="#buyer-faqs">
      <div className="accordion-body">
        <p>While registration is not mandatory, we recommend you to do so as many useful features can be used only on registration.</p>
        <p className="mb-0">The full potential and the maximum benefit can be derived on registering and creating an account.</p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="buyerheadingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#buyercollapseThree" aria-expanded="false" aria-controls="buyercollapseThree">
        Are the properties listed on the site, verified by Broker-Free-Homes Team?
      </button>
    </h2>
    <div id="buyercollapseThree" className="accordion-collapse collapse" aria-labelledby="buyerheadingThree" data-bs-parent="#buyer-faqs">
      <div className="accordion-body">
        <p>The listings on the platform are made live only on submission of the Property Title Deed and the requisite Power of Attorneys. These documents are screened by our team to verify them. </p>
        <p className="mb-0">Due diligence beyond this is the responsibility of the buyer.</p>
      </div>
    </div>
  </div>
        <div className="accordion-item">
    <h2 className="accordion-header" id="buyerheadingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#buyercollapseFour" aria-expanded="false" aria-controls="buyercollapseFour">
        Is there any limitation on the number of property owners I can connect to?
      </button>
    </h2>
    <div id="buyercollapseFour" className="accordion-collapse collapse" aria-labelledby="buyerheadingFour" data-bs-parent="#buyer-faqs">
      <div className="accordion-body">
        <p className="mb-0">No, there isn’t. Once you have registered and created an account; you can connect to unlimited number of sellers. </p>
      </div>
    </div>
  </div>
        <div className="accordion-item">
    <h2 className="accordion-header" id="buyerheadingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#buyercollapseFive" aria-expanded="false" aria-controls="buyercollapseFive">
        How do I get the contact number of the property owner?
      </button>
    </h2>
    <div id="buyercollapseFive" className="accordion-collapse collapse" aria-labelledby="buyerheadingFive" data-bs-parent="#buyer-faqs">
      <div className="accordion-body">
        <p className="mb-0">In order to protect the privacy of the property owner, we do not share his/her contact number or email address. You can, however, easily connect with the property owner using the Message Board Chat through your account.</p>
      </div>
    </div>
  </div>
        
        <div className="accordion-item">
    <h2 className="accordion-header" id="buyerheadingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#buyercollapseSix" aria-expanded="false" aria-controls="buyercollapseSix">
        How do I save the shortlisted properties for future reference?
      </button>
    </h2>
    <div id="buyercollapseSix" className="accordion-collapse collapse" aria-labelledby="buyerheadingSix" data-bs-parent="#buyer-faqs">
      <div className="accordion-body">
            <p>If you like any property and wish to revisit it later, click on the Heart Sign button to Save it to Favourites.</p>
        <p className="mb-0">You can view and manage your Favourites from your account. </p>
      </div>
    </div>
  </div>
        
        <div className="accordion-item">
    <h2 className="accordion-header" id="buyerheadingSeven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#buyercollapseSeven" aria-expanded="false" aria-controls="buyercollapseSeven">
        Will my contact information be upfront and visible to everyone?
      </button>
    </h2>
    <div id="buyercollapseSeven" className="accordion-collapse collapse" aria-labelledby="buyerheadingSeven" data-bs-parent="#buyer-faqs">
      <div className="accordion-body">
        <p className="mb-0">In order to protect your privacy, we do not share contact numbers or email address on the platform. You can contacted only through the Message Board Chat, which you can access through your account.</p>
      </div>
    </div>
  </div>
        
</div>
                       </Tab> {/* buyer tab */}
        
<Tab eventKey="seller" title="Seller">
    <div className="accordion faqs" id="seller-faqs">
  <div className="accordion-item">
    <h2 className="accordion-header" id="sellerheadingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sellercollapseOne" aria-expanded="true" aria-controls="sellercollapseOne">
        What charges are applicable on use of the website?
      </button>
    </h2>
    <div id="sellercollapseOne" className="accordion-collapse collapse show" aria-labelledby="sellerheadingOne" data-bs-parent="#seller-faqs">
      <div className="accordion-body">
       <p>As a limited period welcome offer, the subscription fee of the Premium membership to BrokerFreehomes.com has been waived off for the first six months.</p>
        <p className="mb-0">Additionally, no fee, no charge is applicable by BrokerFreeHomes on closure of deal through the platform.</p> 
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="sellerheadingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sellercollapseTwo" aria-expanded="false" aria-controls="sellercollapseTwo">
        Do I need to register to use the site?
      </button>
    </h2>
    <div id="sellercollapseTwo" className="accordion-collapse collapse" aria-labelledby="sellerheadingTwo" data-bs-parent="#seller-faqs">
      <div className="accordion-body">
        <p>While registration is not mandatory, we recommend you to do so as many useful features can be used only on registration. </p>
        <p className="mb-0">The full potential and the maximum benefit can be derived on registering and creating an account.</p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="sellerheadingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sellercollapseThree" aria-expanded="false" aria-controls="sellercollapseThree">
       I don’t have the property papers handy. Can I list the property?
      </button>
    </h2>
    <div id="sellercollapseThree" className="accordion-collapse collapse" aria-labelledby="sellerheadingThree" data-bs-parent="#seller-faqs">
      <div className="accordion-body">
        <p>You can initiate the process of listing the property by adding all the information related to the property and saving it as a Draft. </p>
        <p className="mb-0">The Draft listing can be accessed from your account, from where you can edit it and upload the pending Property Title Deed in PDF format. The completed listing can then be submitted for approval. </p>
      </div>
    </div>
  </div>
        <div className="accordion-item">
    <h2 className="accordion-header" id="sellerheadingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sellercollapseFour" aria-expanded="false" aria-controls="sellercollapseFour">
        I am joint owner in a property. Can I list the property on the site?
      </button>
    </h2>
    <div id="sellercollapseFour" className="accordion-collapse collapse" aria-labelledby="sellerheadingFour" data-bs-parent="#seller-faqs">
      <div className="accordion-body">
        <p className="mb-0">Yes, you can. You will need to upload a Power of Attorney (POA) from each of the remaining owners of the property as authorisation. </p>
      </div>
    </div>
  </div>
        <div className="accordion-item">
    <h2 className="accordion-header" id="sellerheadingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sellercollapseFive" aria-expanded="false" aria-controls="sellercollapseFive">
        I submitted a listing of my property. But it isn’t visible anywhere.
      </button>
    </h2>
    <div id="sellercollapseFive" className="accordion-collapse collapse" aria-labelledby="sellerheadingFive" data-bs-parent="#seller-faqs">
      <div className="accordion-body">
            <p>After submission, a Listing is reviewed by our team to verify that it is complete in all respects, and meets our guidelines & standards. This process can take upto two working days.</p>
        <p className="mb-0">You shall be notified via email once the listing is approved and goes live.</p>
      </div>
    </div>
  </div>
        
        <div className="accordion-item">
    <h2 className="accordion-header" id="sellerheadingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sellercollapseSix" aria-expanded="false" aria-controls="sellercollapseSix">
        Can I update my property images & details, once it has been listed?
      </button>
    </h2>
    <div id="sellercollapseSix" className="accordion-collapse collapse" aria-labelledby="sellerheadingSix" data-bs-parent="#seller-faqs">
      <div className="accordion-body">
            <p>You can easily update your listing from the ‘My Listings’ section in your account.</p>
        <p className="mb-0">However, please be aware that on editing, your listing will be resubmitted for approval. </p>
      </div>
    </div>
  </div>
        
        <div className="accordion-item">
    <h2 className="accordion-header" id="sellerheadingSeven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sellercollapseSeven" aria-expanded="false" aria-controls="sellercollapseSeven">
       Are there any charges to list the property? Is the fees recurring till the property is listed?
      </button>
    </h2>
    <div id="sellercollapseSeven" className="accordion-collapse collapse" aria-labelledby="sellerheadingSeven" data-bs-parent="#seller-faqs">
      <div className="accordion-body">
            <p>Currently, no charges are applicable for listing a property.</p>
        <p>Additionally, no fee, no charge is applicable by BrokerFreeHomes on closure of deal through the platform.</p>
        <p className="mb-0">In case any charges are become applicable in future, you will be notified about it well in advance.</p>
      </div>
    </div>
  </div>
        
        <div className="accordion-item">
    <h2 className="accordion-header" id="sellerheadingEight">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sellercollapseEight" aria-expanded="false" aria-controls="sellercollapseEight">
     I am a real-estate agent/broker, Can I list on the platform?
      </button>
    </h2>
    <div id="sellercollapseEight" className="accordion-collapse collapse" aria-labelledby="sellerheadingEight" data-bs-parent="#seller-faqs">
      <div className="accordion-body">
            <p>BrokerFreeHomes.com is a platform designed purely as a direct connect between owners and buyers/tenants.</p>
        <p className="mb-0">Real estate agents and brokers should not list on this platform.</p>
      </div>
    </div>
  </div>
        
        <div className="accordion-item">
    <h2 className="accordion-header" id="sellerheadingNine">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sellercollapseNine" aria-expanded="false" aria-controls="sellercollapseNine">
     Can I list multiple properties?
      </button>
    </h2>
    <div id="sellercollapseNine" className="accordion-collapse collapse" aria-labelledby="sellerheadingNine" data-bs-parent="#seller-faqs">
      <div className="accordion-body">
        <p className="mb-0">A registered Premium member (seller) can list upto three properties.</p>
      </div>
    </div>
  </div>
        
        <div className="accordion-item">
    <h2 className="accordion-header" id="sellerheadingTen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sellercollapseTen" aria-expanded="false" aria-controls="sellercollapseTen">
     Can I list a non-residential property on Broker-Free-Homes?
      </button>
    </h2>
    <div id="sellercollapseTen" className="accordion-collapse collapse" aria-labelledby="sellerheadingTen" data-bs-parent="#seller-faqs">
      <div className="accordion-body">
        <p className="mb-0">The BrokerFreeHomes.com platform doesn’t cater to commercial properties as of now.</p>
      </div>
    </div>
  </div>
        <div className="accordion-item">
    <h2 className="accordion-header" id="sellerheadingEleven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sellercollapseEleven" aria-expanded="false" aria-controls="sellercollapseEleven">
     Will my contact information be upfront and visible to everyone?
      </button>
    </h2>
    <div id="sellercollapseEleven" className="accordion-collapse collapse" aria-labelledby="sellerheadingEleven" data-bs-parent="#seller-faqs">
      <div className="accordion-body">
        <p className="mb-0">In order to protect your privacy, we do not share contact numbers or email address on the platform. You can be contacted only through the Message Board Chat, which you can access through your account.</p>
      </div>
    </div>
  </div>
        
        <div className="accordion-item">
    <h2 className="accordion-header" id="sellerheadingTwelve">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sellercollapseTwelve" aria-expanded="false" aria-controls="sellercollapseTwelve">
     Can I list a property that is not in Dubai?
      </button>
    </h2>
    <div id="sellercollapseTwelve" className="accordion-collapse collapse" aria-labelledby="sellerheadingTwelve" data-bs-parent="#seller-faqs">
      <div className="accordion-body">
        <p className="mb-0">Currently, BrokerFreeHomes.com caters to Dubai only. We shall soon be servicing more cities.</p>
      </div>
    </div>
  </div>
</div>
                       </Tab> {/* seller tab */}
<Tab eventKey="tenant" title="Tenant">
    <div className="accordion faqs" id="tenant-faqs">
  <div className="accordion-item">
    <h2 className="accordion-header" id="tenantheadingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tenantcollapseOne" aria-expanded="true" aria-controls="tenantcollapseOne">
       What charges are applicable on use of the website?
      </button>
    </h2>
    <div id="tenantcollapseOne" className="accordion-collapse collapse show" aria-labelledby="tenantheadingOne" data-bs-parent="#tenant-faqs">
      <div className="accordion-body">
       <p>As a limited period welcome offer, the subscription fee of the Premium membership to BrokerFreehomes.com has been waived off for the first six months. </p>
        <p className="mb-0">Additionally, no fee, no charge is applicable by BrokerFreeHomes on closure of deal through the platform.</p> 
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="tenantheadingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tenantcollapseTwo" aria-expanded="false" aria-controls="tenantcollapseTwo">
       Do I need to register to use the site?
      </button>
    </h2>
    <div id="tenantcollapseTwo" className="accordion-collapse collapse" aria-labelledby="tenantheadingTwo" data-bs-parent="#tenant-faqs">
      <div className="accordion-body">
        <p>While registration is not mandatory, we recommend you to do so as many useful features can be used only on registration. </p>
        <p className="mb-0">The full potential and the maximum benefit can be derived on registering and creating an account. </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="tenantheadingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tenantcollapseThree" aria-expanded="false" aria-controls="tenantcollapseThree">
        Is there any limitation on the number of property owners I can connect to?
      </button>
    </h2>
    <div id="tenantcollapseThree" className="accordion-collapse collapse" aria-labelledby="tenantheadingThree" data-bs-parent="#tenant-faqs">
      <div className="accordion-body">
        <p className="mb-0">No, there isn’t. Once you have registered and created an account; you can connect to unlimited number of sellers.</p>
      </div>
    </div>
  </div>
        <div className="accordion-item">
    <h2 className="accordion-header" id="tenantheadingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tenantcollapseFour" aria-expanded="false" aria-controls="tenantcollapseFour">
        How do I get the contact number of the property owner?
      </button>
    </h2>
    <div id="tenantcollapseFour" className="accordion-collapse collapse" aria-labelledby="tenantheadingFour" data-bs-parent="#tenant-faqs">
      <div className="accordion-body">
        <p className="mb-0">In order to protect the privacy of the property owner, we do not share his/her contact number or email address. You can, however, easily connect with the property owner using the Message Board Chat through your account.</p>
      </div>
    </div>
  </div>
        <div className="accordion-item">
    <h2 className="accordion-header" id="tenantheadingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tenantcollapseFive" aria-expanded="false" aria-controls="tenantcollapseFive">
        How do I save the shortlisted properties for future reference?
      </button>
    </h2>
    <div id="tenantcollapseFive" className="accordion-collapse collapse" aria-labelledby="tenantheadingFive" data-bs-parent="#tenant-faqs">
      <div className="accordion-body">
            <p>If you like any property and wish to revisit it later, click on the Heart Sign button to Save it to Favourites. </p>
        <p className="mb-0">You can view and manage your Favourites from your account. </p>
      </div>
    </div>
  </div>
        
        <div className="accordion-item">
    <h2 className="accordion-header" id="tenantheadingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tenantcollapseSix" aria-expanded="false" aria-controls="tenantcollapseSix">
       Will my contact information be upfront and visible to everyone?
      </button>
    </h2>
    <div id="tenantcollapseSix" className="accordion-collapse collapse" aria-labelledby="tenantheadingSix" data-bs-parent="#tenant-faqs">
      <div className="accordion-body">
        <p className="mb-0">In order to protect your privacy, we do not share contact numbers or email address on the platform. You can contacted only through the Message Board Chat, which you can access through your account.</p>
      </div>
    </div>
  </div>
                
</div>
                       </Tab>{/* tenant tab */}
<Tab eventKey="landlord" title="Landlord">
    <div className="accordion faqs" id="landlord-faqs">
  <div className="accordion-item">
    <h2 className="accordion-header" id="landlordheadingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#landlordcollapseOne" aria-expanded="true" aria-controls="landlordcollapseOne">
        What charges are applicable on use of the website?
      </button>
    </h2>
    <div id="landlordcollapseOne" className="accordion-collapse collapse show" aria-labelledby="landlordheadingOne" data-bs-parent="#landlord-faqs">
      <div className="accordion-body">
       <p>As a limited period welcome offer, the subscription fee of the Premium membership to BrokerFreehomes.com has been waived off for the first six months.</p>
        <p className="mb-0">Additionally, no fee, no charge is applicable by BrokerFreeHomes on closure of deal through the platform.</p> 
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="landlordheadingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#landlordcollapseTwo" aria-expanded="false" aria-controls="landlordcollapseTwo">
        Do I need to register to use the site?
      </button>
    </h2>
    <div id="landlordcollapseTwo" className="accordion-collapse collapse" aria-labelledby="landlordheadingTwo" data-bs-parent="#landlord-faqs">
      <div className="accordion-body">
            <p>While registration is not mandatory, we recommend you to do so as many useful features can be used only on registration.</p>
        <p className="mb-0">The full potential and the maximum benefit can be derived on registering and creating an account. </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="landlordheadingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#landlordcollapseThree" aria-expanded="false" aria-controls="landlordcollapseThree">
        I don’t have the property papers handy. Can I list the property?
      </button>
    </h2>
    <div id="landlordcollapseThree" className="accordion-collapse collapse" aria-labelledby="landlordheadingThree" data-bs-parent="#landlord-faqs">
      <div className="accordion-body">
        <p>You can initiate the process of listing the property by adding all the information related to the property and saving it as a Draft.</p>
        <p className="mb-0">The Draft listing can be accessed from your account, from where you can edit it and upload the pending Property Title Deed in PDF format. The completed listing can then be submitted for approval. </p>
      </div>
    </div>
  </div>
        <div className="accordion-item">
    <h2 className="accordion-header" id="landlordheadingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#landlordcollapseFour" aria-expanded="false" aria-controls="landlordcollapseFour">
        I am joint owner in a property. Can I list the property on the site?
      </button>
    </h2>
    <div id="landlordcollapseFour" className="accordion-collapse collapse" aria-labelledby="landlordheadingFour" data-bs-parent="#landlord-faqs">
      <div className="accordion-body">
        <p className="mb-0">Yes, you can. You will need to upload a Power of Attorney (POA) from each of the remaining owners of the property as authorisation.</p>
      </div>
    </div>
  </div>
        <div className="accordion-item">
    <h2 className="accordion-header" id="landlordheadingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#landlordcollapseFive" aria-expanded="false" aria-controls="landlordcollapseFive">
       I submitted a listing of my property. But it isn’t visible anywhere.
      </button>
    </h2>
    <div id="landlordcollapseFive" className="accordion-collapse collapse" aria-labelledby="landlordheadingFive" data-bs-parent="#landlord-faqs">
      <div className="accordion-body">
            <p>After submission, a Listing is reviewed by our team to verify that it is complete in all respects, and meets our guidelines & standards. This process can take upto two working days.</p>
        <p className="mb-0">You shall be notified via email once the listing is approved and goes live.</p>
      </div>
    </div>
  </div>
        
        <div className="accordion-item">
    <h2 className="accordion-header" id="landlordheadingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#landlordcollapseSix" aria-expanded="false" aria-controls="landlordcollapseSix">
       Can I update my Property images & details, once it has been listed?
      </button>
    </h2>
    <div id="landlordcollapseSix" className="accordion-collapse collapse" aria-labelledby="landlordheadingSix" data-bs-parent="#landlord-faqs">
      <div className="accordion-body">
            <p>You can easily update your listing from the ‘My Listings’ section in your account.</p>
        <p className="mb-0">However, please be aware that on editing, your listing will be resubmitted for approval.</p>
      </div>
    </div>
  </div>
        
        <div className="accordion-item">
    <h2 className="accordion-header" id="landlordheadingSeven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#landlordcollapseSeven" aria-expanded="false" aria-controls="landlordcollapseSeven">
        Does Broker-Free-Homes provide a ready tenancy contract?
      </button>
    </h2>
    <div id="landlordcollapseSeven" className="accordion-collapse collapse" aria-labelledby="landlordheadingSeven" data-bs-parent="#landlord-faqs">
      <div className="accordion-body">
        <p className="mb-0">You can use the Tenancy Contract template provided in ‘Useful Links’ Section to create a contract. </p>
      </div>
    </div>
  </div>
        <div className="accordion-item">
    <h2 className="accordion-header" id="landlordheadingEight">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#landlordcollapseEight" aria-expanded="false" aria-controls="landlordcollapseEight">
        Can I list a property that is not in Dubai?
      </button>
    </h2>
    <div id="landlordcollapseEight" className="accordion-collapse collapse" aria-labelledby="landlordheadingEight" data-bs-parent="#landlord-faqs">
      <div className="accordion-body">
        <p className="mb-0">Currently, BrokerFreeHomes.com caters to Dubai only. We shall soon be servicing more cities.</p>
      </div>
    </div>
  </div>
        
</div>
                       </Tab>{/* landlord tab */}
                  </Tabs>
      </div>
      </div>
    </div>
 
      <Footer />
    </>

  );
}

export default FaqPage; 