import React, { useState, useEffect,useRef } from "react";
import { useLocation } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import AuthHeader from "../../../customComponent/AuthHeader";
import Footer from "../../../customComponent/Footer";
import Moment from "moment";

const MessagePage = () => {
  const userId = sessionStorage.getItem("iduser");
  const userToken = sessionStorage.getItem("token");
  const [members, settMembers] = useState([]);
  const [messages, settMessages] = useState([]);
  const [newmessage, setNewMessage] = useState('');
  const [reciever, setRecieverId] = useState();

  const location = useLocation();
  const [propertyid, setPropertyid] = useState(location?.state);
  
  
 // const { propertyid } = location?.state

  useEffect(() => {
    handleGetMembers();
    scrollBottom();
  }, []);
  const handleGetMembers = async () => {
    await AuthService.GetMembers(userToken).then(async result => {
        if (result.apirspo) {  
          settMembers(result.members);
        } else {

        }
    });
}
const handleGetMessages = async (pmid, propertyid) => {
  setPropertyid(propertyid);  
  await AuthService.GetMessages(pmid,userToken).then(async result => {
      if (result.apirspo) {  
        //setRecieverId(recieverId);
        settMessages(result.messages);
       
      } else {

      }
  });
}

const handleSendMessage = async () => {
  await AuthService.SendMessage(propertyid,userToken,newmessage).then(async result => {
    if (result.apirspo) {  
      //handleGetMessages(reciever,userId);
      handleGetMessages(result.pmid,result.propertyid); 
      setNewMessage('');
      scrollBottom();
    } else {

    }
});
}
const messagesEndRef = useRef(null)
const scrollBottom = () => {
  messagesEndRef?.current?.scrollIntoView(true)
}
  return (
    <>
      <AuthHeader />
      <div id="dashboard-content" className="col-lg-9 h-100 offset-lg-2">
        <div className="dahsboard-wrap">
          <div className="dashboard-title">
            <h4>My Chat</h4>
          </div>
          <div className="row rounded-lg overflow-hidden chat-master">

            <div className="col-5">
              <div className="px-4 pb-2">
                <p className="h5 mb-0 py-1">Members</p>
              </div>
              
                  <div className="messages-box bg-white shadow p-4">
                    <div className="list-group rounded-0">
                    {members.length > 0 ?
                        members.map((data, idx) =>
                      <>
                      <a className="list-group-item list-group-item-action active rounded-0" id={idx} onClick={() => handleGetMessages(data.id, data.idproperty )}>
                        <div className="media"><div className="profileImage rounded-circle">{data.name?.charAt(0)}</div>
                          <div className="media-body">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                              <h6 className="mb-0">
                                  {data.id_receiver_user==userId?data.sender_name:data.reveiver_name}   
                                </h6>
                              <small className="small">{Moment(data.created_at).format("DD MMM 'YY")}</small>
                            </div>
                            <div className="text-end">
                           {/*} <span class="badge bg-primary">1</span> */}
                            
                            </div>
                          </div>
                        </div>
                      </a>     
                      </>
                      )
                      : null}
                    </div>
                  </div>
               
            </div>
            <div className="col-7 pe-0">
              <div className="chat-box mb-2 pe-2" ref={messagesEndRef}>
                      {/*messages.length > 0 ?
                          messages.map((data, idx) => 
                          <>
                            <div className="media mb-4"><div className="profileImage rounded-circle">O</div>
                              <div className="bg-otherG media-body rounded ms-3 shadow2 arrow-left">
                                <div className="p-3">
                                  <div className="d-flex mb-2 chat-text-head">
                                    <h6>
                                        LOGIN : {userId}
                                        RECEIVER: {data.id_receiver_user}
                                        SENDER : {data.id_sender_user}


                                    </h6>
                                    <span className="small fw-semi"><i className="bi bi-clock"></i> {Moment(data.created_at).format("LT")} |{Moment(data.created_at).format("DD MMM 'YY")}</span>
                                  </div>
                                  <p className="text-muted mb-0">
                                  {data.query}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                          )
                      :
                      null

                    */}


              {messages.length > 0 ?
                    messages.map((data, idx) =>                    
                  <>   
                  { data.id_sender_user == userId 
                    ? 
                    <div className="media mb-4"><div className="profileImage rounded-circle">{data.name?.charAt(0)}</div>
                    <div className="bg-otherG media-body rounded ms-3 shadow2 arrow-left">
                      <div className="p-3">
                        <div className="d-flex mb-2 chat-text-head">
                          <h6>{data.name}</h6>
                          <span className="small fw-semi"><i className="bi bi-clock"></i> {Moment(data.created_at).format("LT")} |{Moment(data.created_at).format("DD MMM 'YY")}</span>
                        </div>
                        <p className="text-muted mb-0">
                        {data.query}
                        </p>
                      </div>
                    </div>
                  </div>
                    : 
                    <div className="media mb-4 ms-auto">
                    <div className="media-body me-3 shadow2 arrow-right">
                      <div className="bg-white rounded p-3">
                        <div className="d-flex mb-2 chat-text-head">
                          <h6>{data.name}</h6>
                          <span className="small fw-semi"><i className="bi bi-clock"></i> {Moment(data.created_at).format("LT")} |{Moment(data.created_at).format("DD MMM 'YY")}</span>
                        </div>
                        <p className="mb-0"> {data.query}</p>
                      </div>
                    </div>
                    <div className="profileImage rounded-circle">{data.name?.charAt(0)} </div>
                  </div>  
                  }   
                </>
                  )
                  : null}  
              </div>
                  
                    <div className="secondaryG-bg">
                      <div className="input-group">
                      
                        {
                          propertyid>0
                            ?<>
                              <textarea placeholder="Type a message" aria-describedby="button-addon2" className="form-control rounded-0 border-0 py-4" onChange={(e) => setNewMessage(e.target.value)}>{newmessage}</textarea>
                              <div className="input-group-append  align-self-center">
                                <button id="button-addon2" type="submit" className="btn btn-link" onClick={() =>handleSendMessage()}> <i className="bi bi-send text-white"></i></button>
                              </div>
                              </>
                            :
                            null
                        }
                      </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MessagePage;