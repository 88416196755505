import React, { useState, useContext } from "react";
import AuthHeader from "../../../customComponent/AuthHeader";
import Footer from "../../../customComponent/Footer";
const AboutusPage = () => {
  return (
    <>
      <AuthHeader />

     <div className="inner-banner">
      </div>
      <div className="container mb-5">
        <div className="row">
      <div className="col-md-8 offset-md-2 subscriptions-plan my-5">
      <div class="section-title text-center"><h2>About Us</h2></div>
      <h5 className="fw-semi text-dark">GOODBYE COMMISSION, HELLO SAVINGS.</h5>   
      <p>The no-commission model in real estate transactions has been a lucky option for a very few people.  You had to be buying from or selling to friends directly.</p>
      <p>We asked ourselves the question: How can we give people the choice of no-commission transactions?</p>
      <p>And this seed of thought led us to analyze the customer journey in both rental and buying deals. Like the Airbnb model, what if we were to create a platform where buyers could directly interact with sellers? And this is how Broker Free Homes was born.</p>
      <p>The founders, Arijit, Smriti and Naveen are childhood friends. And all come from the advertising, media planning and communications backgrounds in India and the United Arab Emirates. Between them, they have several years of experience managing clients and buying homes.</p>
      <div className="p-3 bg-light primaryB mb-3 ">
     <p className="mb-0"><em className="d-block mb-2">"Our hope in bringing Broker Free Homes to the UAE is to increase the choices available to people – you want to buy or rent through the broker, go ahead; but you want to explore the commission free experience, make BrokerFreeHomes your first choice"</em>
            <span className="fw-semi">says Arijit</span>
    </p>
      </div>
      <p>Arijit Sen is a business leader, specializing in marketing communications with proven experience in business strategy and planning, project management and social media marketing. In his past life, he provided counsel to global brands on how to navigate the MENA media landscape, driving negotiation with regional and international media partners. He has been in leadership and management roles across several agency networks.</p>
      <p>Smriti Tripathi dons multiple hats at her India-based marcom agency. She has rich 20+ years of general management experience and is a strategic thinker with a problem-solving approach. She is also part of start-ups in other sectors and is a regular mentor to young and fledgling start-ups at TiE Rajasthan Chapter. </p>
      <p>Naveen Tripathi has over two decades of experience in providing Communication Design solutions to hundreds of clients across over 50 sectors. </p>
      <p>With strong focus on the user experience and making the journey simple and intuitive on our platform, we seek to enable direct conversations between home seekers and homeowners through transparent, seamless and commission-free experiences.</p>
      </div>
      </div>
    </div>
 
      <Footer />
    </>

  );
}

export default AboutusPage; 