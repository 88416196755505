import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import Multiselect from 'multiselect-react-dropdown';

const SearchBar = (props) => {
    const navigate = useNavigate();
    const [propertypurpose, setPropertyPurpose] = useState(1);
    const [searchterm, setSearchTerm] = useState('');
    const [searchtermerror, setSearchTermError] = useState(false);
    const { propertytype } = props;
    const [propertytypes, setPropertyTypes] = useState();
    const [propertytypeerror, setpropertytypesError] = useState();
    const [propertycity, setPropertyCity] = useState('Dubai');
    const [searchTerms, setSearchTerms] = useState([]); //to Store all search Terms in array
        
    const maxAllowedSearchTerms = 2;    //Maximum(3) Search Terms to get searched. 
    const handlePropertyPurpose = (e) => {
         setPropertyPurpose(e);    
    }

    const handlePropertySearch = () => {
        if(searchterm == undefined || searchterm.address_components == undefined || searchTerms.length<=0){ //Adding SearchTerms condition here also
            setSearchTermError(true)
        }if(propertytypes === undefined || propertytypes === ''){
            setpropertytypesError('Please select property type.')
        }else{
            // Previously we are sending only single value let  searchtermdata = searchterm.address_components[0].long_name
            //console.log("Search Bar : Purpose : " + propertypurpose +  "propertycity" + propertycity+ " Type : " + propertytypes + " Search Term Data : " + searchtermdata);
            navigate('/search', { state: {propertypurpose:propertypurpose,propertycity:propertycity,propertytype:propertytypes,searchterm:searchTerms}});
        }
        
    }

    const onEnterSearchTerm = (place, eventsrc) => {
        console.log(eventsrc);
        if(!(place == undefined || place.address_components == undefined)){ // if valid search Term identified 
            let  latestTerm = place.address_components[0].long_name;
            setSearchTerms( prevSearchTerms => {
                if(prevSearchTerms.length>maxAllowedSearchTerms){
                    setSearchTermError(true);
                    return prevSearchTerms;
                } else { 
                    return [...prevSearchTerms, latestTerm];};
                });
            
        }
    }

    return (
        <> 
            <section id="banner-search">
                <div className="container">
                    <div className="row"><div className="col-lg-10 offset-lg-1">
                        <ul className="nav nav-tabs search-tabs" id="banner-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" value={propertypurpose} onClick={() => handlePropertyPurpose(1)}>Buy</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false" value={propertypurpose} onClick={() => handlePropertyPurpose(2)}>RENT</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="search-tab-content">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div className="row g-0">
                                <div class="col-md-2 select-city">
                                    <select className="form-select form-select-lg border-0" id="autoSizingSelect">
                                        <option value="Dubai">Dubai</option>
                                    </select> 
                                    </div>
                                   <div className="col-md-3 ptype position-relative">                 
                                    <select className="form-select form-select-lg border-0" id="autoSizingSelect" value={propertytypes} onChange={(e) => {setPropertyTypes(e.target.value);setpropertytypesError()}}>
                                        <option value="">Property Type</option>
                                        {Object.keys(propertytype).map(key => 
                                         <>
                                            <option value={key}>{propertytype[key]}</option>
                                        </>
                                        )}                                     
                                    </select> 
<span className="text-danger error">{propertytypeerror}</span>
                                    </div>
                                    <div class="col-md-5 area-search position-relative">
                                    <div className="location-pin position-absolute small">{searchTerms.toString()}</div>
                                    <ReactGoogleAutocomplete className="form-control form-control-lg border-0"
                                        apiKey="AIzaSyD5dy5NNIkHJnscrX9TViUrhpFhAC730rg" 
                                        onPlaceSelected={(place, eventsrc) => {
                                            onEnterSearchTerm(place, eventsrc);   // Collect Max 3 Search Terms 
                                            //setSearchTerm(place)
                                          }} 
                                        options={{
                                            types: ["(regions)"],
                                            componentRestrictions: { country: "ae" },
                                        }}
                                        />  
  {searchtermerror== true ? <span className="text-danger error">Enter your location</span>:null}
                                        </div>  
                                                                           
                                        <div class="col-md-2 text-center position-relative">
                                    <button className="btn btn-primary btn-lg search-btn" onClick={(e) => handlePropertySearch()}><span className="btn-label"><i className="zoom"></i>Search</span> </button>

                                </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SearchBar;